import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  newSupplierRequest,
  createSupplierOptionsRequest,
} from "../../actions/supplierActions";
import Breadcrumbs from "../../components/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import SupplierDetailForm from "./SupplierDetailForm";
import {
  SimpleDialog,
  Loader,
  ErrorMessage,
} from "../../components/viewComponents";
import { ScreenTitle } from "../../components/editComponents";
import useDocumentTitle from "../../useDocumentTitle";
import { K_COUNTRIES } from "../../constantsData/countries";

export default function NewSupplier() {
  useDocumentTitle("New Supplier");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // get
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, options, error, success } = useSelector(
    (state) => state.createSupplierOptions
  );

  const getInitialValues = () => {
    let initialValues = {
      name: "",
      country: userDetailsSuccess
        ? K_COUNTRIES.filter(
            (x) => x.value === userDetails.data.orgInfo.country
          )[0]
        : "",
      currency: options.currencies
        ? options.currencies.filter((x) => x.isBaseCurrency === 1)[0]
        : "",
      taxRateId: options.salesTaxRates
        ? options.salesTaxRates.filter((x) => x.isDefault === 1).length
          ? options.salesTaxRates.filter((x) => x.isDefault === 1)[0].id
          : ""
        : "",
      postalAddress: {
        country: "",
        region: "",
        city: "",
        addressLine: "",
        postalCode: "",
      },
      billingAddress: {
        country: "",
        region: "",
        city: "",
        addressLine: "",
        postalCode: "",
      },
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      notes: "",
    };
    return initialValues;
  };

  const {
    loading: newLoading,
    success: newSuccess,
    error: newError,
  } = useSelector((state) => state.newSupplier);

  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    dispatch(createSupplierOptionsRequest());
  }, [dispatch]);

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/suppliers", { replace: true, from: location });
  };

  const addNewSupplierRequest = (values) => {
    let supplierValues = {
      name: values.name,
      country: typeof values.country === "object" ? values.country.value : "",
      currency:
        typeof values.currency === "object" ? values.currency.value : "",
      taxRateId: values.taxRateId,
      postalAddress: JSON.stringify(values.postalAddress),
      billingAddress: JSON.stringify(values.billingAddress),
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      notes: values.notes,
    };

    setDialog(true);

    dispatch(newSupplierRequest(supplierValues));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Supplier"
        successMessage="New supplier added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={newLoading}
        loadingMessage="Processing request..."
        success={newSuccess}
        error={newError}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="New Supplier" />
      <ScreenTitle title="Create New Supplier" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting data..." />
        ) : success ? (
          <div className="w-[80%]">
            <SupplierDetailForm
              values={getInitialValues}
              onSubmit={addNewSupplierRequest}
              options={options}
              btnName="Create New Supplier"
              title="Create New Supplier"
            />
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
