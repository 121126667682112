// get package list
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


export const uploadFileRequest = async (formData, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/file/upload`, formData, config);
    return response;
  } catch (error) {
    throw error;
  }

};

export const deleteFileFromS3Request = async (values, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/file/delete`, values, config);
    return response;
  } catch (error) {
    throw error;
  }

};
