import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  ScreenTitle,
  AddLinkButton,
  EditButtonLink,
  DeleteButton,
  ViewButtonLink,
} from "../../components/editComponents";
import Pagination from "../../components/Pagination";
import {
  ErrorMessage,
  Loader,
  DeleteDialog,
  SortedTableTitle,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import {
  supplierListRequest,
  deleteSupplierRequest,
} from "../../actions/supplierActions";
import useDocumentTitle from "../../useDocumentTitle";
import { Search } from "../../components/inputFields";
import { useNavigate } from "react-router-dom";

export default function Suppliers() {
  useDocumentTitle("Suppliers");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  // get data from store by using useSelector method
  const { loading, supplierList, error, success } = useSelector(
    (state) => state.supplierList
  );

  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = useSelector((state) => state.deleteSupplier);

  const sortOptions = [
    { id: 1, name: "Sort by name A - Z", value: "asc" },
    { id: 2, name: "Sort by name Z - A", value: "desc" },
  ];

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  // search
  const [keyWords, setKeywords] = useState("");

  // sort
  // const [sortBy, setSortBy] = useState("name"); // default sort column
  const [sortOrder, setSortOrder] = useState(""); // 'asc' or 'desc'

  // delete
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    dispatch(supplierListRequest(pageNum, pageSize, keyWords, sortOrder));
  }, [dispatch, pageNum, keyWords, sortOrder, deleteSuccess]);
    ////////////////////////////////////////////////////////

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };
  // Handle column sort
  const handleSort = (value) => {
    setSortOrder(value);
  };

  ////////////////////////////////////////////////////////

  const deleteAction = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = () => {
    dispatch(deleteSupplierRequest(deleteId));
  };

  const naviogateToDetails = (supplierId) => {
    navigate(`/supplier/preview/${supplierId}`, { replace: false });
  }

  ////////////////////////////////////////////////////////

  return (
    <div className={commonStyle.pageContainer}>
      <DeleteDialog
        title="Delete Supplier"
        preDeleteMess="Deleting this supplier can not be undone. All products associated with this supplier will have their default supplier field changed to empty. This action will not affect the history purcahse orders records. Are you sure you want to proceed with deleting this supplier?"
        successMessage="Supplier deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={() => setDeleteDialog(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      <Breadcrumbs screenName="Manage Suppliers" />
      <ScreenTitle title="Suppliers" />

      <div className={commonStyle.pageContentContainer}>
        <div className="flex flex-row justify-between">
          <div className="w-2/3 mr-6">
            <Search
              placeholdertext="Type supplier name to search..."
              initialQuery={keyWords}
              onChange={handleSearchChange}
            />
          </div>

          {userDetailsSuccess ? (
            userDetails.data.permissions.includes(
              "add_delete_edit_suppliers"
            ) ? (
              <AddLinkButton name="New Supplier" path="/supplier/new" />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>

        {loading ? (
          <Loader mess="Requesting supplier list, please wait a moment..." />
        ) : success ? (
          <div>
            {/* hold the tax rates table */}
            {Number(supplierList.totalCount) > 0 ? (
              <p className="mt-4">
                Found
                <span className="text-brandColor font-medium mx-1">
                  {supplierList.totalCount}
                </span>
                suppliers
              </p>
            ) : (
              <></>
            )}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>
                    <SortedTableTitle
                      name={"Supplier Name"}
                      onSelect={handleSort}
                      options={sortOptions}
                    />
                  </th>
                  <th>Country</th>
                  <th>Currency</th>
                  <th>Tax Rate</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {supplierList.suppliers.length > 0 ? (
                  supplierList.suppliers.map((item, index) => (
                    <tr key={index}>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">{item.name} </td>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">{item.country} </td>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">{item.currency} </td>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">{item.taxRate} </td>
                      <td>
                        <div className={commonStyle.actionsGrop}>
                          {userDetailsSuccess ? (
                            userDetails.data.permissions.includes(
                              "view_suppliers"
                            ) ? (
                              <ViewButtonLink
                                path={`/supplier/preview/${item.id}`}
                              />
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                          {userDetailsSuccess ? (
                            userDetails.data.permissions.includes(
                              "add_delete_edit_suppliers"
                            ) ? (
                              <>
                                <EditButtonLink
                                  path={`/supplier/edit/${item.id}`}
                                />
                                <DeleteButton
                                  action={() => deleteAction(item.id)}
                                />
                              </>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no supplier available, add a new supplier now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {supplierList.suppliers.length > 0 ? (
              <Pagination
                totalPageNum={supplierList.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
