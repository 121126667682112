import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonStyle from "../../../style/commonStyle.module.css";
import Breadcrumbs from "../../../components/Breadcrumbs";
import settingsStyle from "../settingsStyle.module.css";
import CompanySettingsTab from "./CompanySettingsTab";
import {
  EditButton,
  AddButton,
  DeleteButton,
} from "../../../components/editComponents";
import {
  Modal,
  ErrorMessage,
  Loader,
  SimpleDialog,
  DeleteDialog,
} from "../../../components/viewComponents";
import DepartmentsForm from "./DepartmentsForm";
import {
  newDepartmentRequest,
  updateDepartmentRequest,
  departmentListRequest,
  deleteDepartmentRequest,
} from "../../../actions/orgSettingsActions";
import useDocumentTitle from "../../../useDocumentTitle";

export default function CompanySettingsDepartments() {
  useDocumentTitle("Department");
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const initialValues = {
    id: "",
    name: "",
    description: "",
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(departmentListRequest());
  }, [dispatch]);

  // get department list
  const { loading, departments, error, success } = useSelector(
    (state) => state.departmentList
  );

  // update department
  const {
    loading: upadteLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateDepartment);
  // update department
  const {
    loading: newLoading,
    error: newError,
    success: newSuccess,
  } = useSelector((state) => state.newDepartment);

  const [editedDepartment, setEditDepartment] = useState("");

  // new and edit Modal
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  // mange dialog content, new and edit
  const [newDialog, setNewDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);

  const openEditModal = (item) => {
    setEditDepartment(item);
    setEditModal(true);
  };

  const addNewDepartment = (values) => {
    let depValues = {
      depName: values.name,
      depDescription: values.description,
    };

    dispatch(newDepartmentRequest(depValues));
    setNewModal(false);
    setNewDialog(true);
  };

  const editDepartment = (values) => {
    let depValues = {
      depName: values.name,
      depDescription: values.description,
    };

    dispatch(updateDepartmentRequest(depValues, values.id));
    setEditModal(false);
    setUpdateDialog(true);
  };

  // delete
  const [deleteId, setDeleteId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = useSelector((state) => state.deleteDepartment);

  const deleteAction = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = () => {
    dispatch(deleteDepartmentRequest(deleteId));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Department"
        isModalOpen={newModal}
        closeModal={() => setNewModal(false)}
        content={
          <DepartmentsForm
            values={initialValues}
            onSubmit={addNewDepartment}
            btnName="Save"
          />
        }
      />
      <Modal
        title="Update Department"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <DepartmentsForm
            values={editedDepartment}
            onSubmit={editDepartment}
            btnName="Update"
          />
        }
      />

      <SimpleDialog
        title="Add New Department"
        successMessage="New department added successfully."
        isDialogOpen={newDialog}
        closeDialog={() => setNewDialog(false)}
        loading={newLoading}
        loadingMessage="Processing request..."
        success={newSuccess}
        error={newError}
        btnValue="Confirm"
        confirmAction={() => setNewDialog(false)}
      />

      <SimpleDialog
        title="Update Department"
        successMessage="Department updated successfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={upadteLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <DeleteDialog
        title="Delete Department"
        preDeleteMess="Please note that if you delete this department, the action can not be undone. Any users assigned to this department will have their department field cleared. Are you sure you want to proceed with the deletion?"
        successMessage="Department deleted successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={() => setDeleteDialog(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />
      <Breadcrumbs screenName="Department" />
      <div className={commonStyle.tabsContainer}>
        <CompanySettingsTab tabName="department" />

        <div className={commonStyle.settingsContent}>
          <div className={settingsStyle.subTitleContainer}>
            <h2>Department</h2>
          </div>
          {loading ? (
            <Loader mess="Requesting department list, please wait a moment..." />
          ) : success ? (
            <div className={settingsStyle.contentCon}>
              <div className={commonStyle.shortDataTable}>
                {userDetailsSuccess ? (
                  userDetails.data.permissions.includes(
                    "add_delete_edit_company_info"
                  ) ? (
                    <AddButton
                      name="New Deparment"
                      action={() => setNewModal(true)}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {/* hold the tax rates table */}
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>Department Name</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {departments.length > 0 ? (
                      departments.map((item) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.description}</td>

                          <td>
                            <div className={commonStyle.actionsGrop}>
                              {userDetailsSuccess ? (
                                userDetails.data.permissions.includes(
                                  "add_delete_edit_company_info"
                                ) ? (
                                  <>
                                    <EditButton
                                      action={() => openEditModal(item)}
                                    />
                                    <DeleteButton
                                      action={() => deleteAction(item.id)}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          {" "}
                          <p>
                            There are no existing departments. Please add a new
                            department now.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <ErrorMessage mess={error} />
          )}
        </div>
      </div>
    </div>
  );
}
