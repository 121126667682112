import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteProjectRequest,
  getProjectListRequest
} from "../../api/projectServices";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  AddButton,
  DeleteButton,
  EditButton,
  ScreenTitle,
} from "../../components/editComponents";
import Pagination from "../../components/Pagination";
import {
  DeleteDialog,
  ErrorMessage,
  Loader,
  Modal,
  SimpleDialog,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import ProjectForm from "./ProjectForm";

export default function ProjectsScreen() {
  useDocumentTitle("Projects");
  // set the Modal

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };

  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  //////////////////////////////////////////////////////////////////////////////

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [projects, setProjects] = useState([]);
  const [updateListCount, setUpdateListCount] = useState(0);

  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [newDialog, setNewDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [editedProject, setEditProject] = useState();
  const [deletedProject, setDeletedProject] = useState();

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  //////////////////////////////////////////////////////////////////////////////

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    const getProjectList = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getProjectListRequest(pageNum, pageSize, config);

        if (responseData.data.success) {
          setProjects(responseData.data.data);
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getProjectList();
  }, [updateListCount, pageNum]);

  const editProjectModal = (item) => {
    setEditProject(item);
    setEditModal(true);
  };

  const openProjectDeleteDialog = (projectId) => {
    setDeletedProject(projectId);
    setDeleteDialog(true);
  };

  const addProject = async (newPro) => {
    setNewModal(false);
    setNewDialog(true);
    setUpdateListCount((preState) => preState + 1);

  };

  const editProject = async (projectId, values) => {
    setEditModal(false);
    setUpdateDialog(true);
    setEditProject();
    setUpdateListCount((preState) => preState + 1);
  };

  const deleteProject = async () => {
    try {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await deleteProjectRequest(deletedProject, config);

      if (responseData.data.success) {
        setDeletedProject();
        setUpdateListCount((preState) => preState + 1);
        setDeleteStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Project"
        isModalOpen={newModal}
        closeModal={() => setNewModal(false)}
        content={
          <ProjectForm
            projectId=""
            type="new"
            btnName="Add New Project"
            values={{ name: "", code:"" }}
            onSuccess={addProject}
          />
        }
      />
      <Modal
        title="Edit Project"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <ProjectForm
            projectId={editedProject ? editedProject.id : ""}
            type="edit"
            btnName="Update Project"
            values={{ name: editedProject ? editedProject.name : "", code: editedProject ? editedProject.code : "" }}
            onSuccess={editProject}
          />
        }
      />

      <SimpleDialog
        title="New Project"
        successMessage="New project added successfully."
        isDialogOpen={newDialog}
        closeDialog={() => setNewDialog(false)}
        loading={false}
        loadingMessage="Processing request..."
        success={true}
        error={""}
        btnValue="Confirm"
        confirmAction={() => setNewDialog(false)}
      />

      <SimpleDialog
        title="Update Project"
        successMessage="Project updated successfully"
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={false}
        loadingMessage="Processing request..."
        success={true}
        error={""}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <DeleteDialog
        title="Delete Project"
        preDeleteMess="Deleting project will not affect reports or historical transactions."
        successMessage="Project deleted successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={() => setDeleteDialog(false)}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={deleteProject}
      />

      <Breadcrumbs screenName="Projects" />
      <ScreenTitle title="Projects" />

      <div className={commonStyle.pageContentContainer}>
        {requestStatus.loading ? (
          <Loader mess="Requesting project list, please wait a moment.." />
        ) : requestStatus.success ? (
          <div className={commonStyle.shortDataTable}>
            {userDetailsSuccess ? (
              userDetails.data.permissions.includes(
                "add_delete_edit_project"
              ) ? (
                <AddButton
                  name="New Project"
                  action={() => setNewModal(true)}
                />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {/* hold the tax rates table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                <th>Project Code</th>
                  <th>Project Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {projects.projects.length > 0 ? (
                  projects.projects.map((item, index) => (
                    <tr key={index}>
                      <td>{item.code}</td>
                      <td>{item.name}</td>

                      <td>
                        <div className={commonStyle.actionsGrop}>
                          <EditButton action={() => editProjectModal(item)} />
                          <DeleteButton
                            action={() => openProjectDeleteDialog(item.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no project available, add a new project now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {projects.projects.length > 0 ? (
              <Pagination
                totalPageNum={projects.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
