import React, { useState } from "react";
import { XeroButton } from "../../../components/editComponents";
import { FullHeightContentModal, FullHeightModal } from "../../../components/viewComponents";
import ImportXeroCustomersForm from "./ImportXeroCustomersForm";
import ImportXeroSuppliersForm from "./ImportXeroSuppliersForm";
import ImportXeroProductsForm from "./ImportXeroProductsForm";


export default function ImportXeroProducts({onTaskSuccess}) {

  const [impotModal, setImportModal] = useState(false);

  const syncCustomersFromXero = () => {
    setImportModal(true);
  };

  const closeImportModalWithSuccess = () => {
    setImportModal(false);
    onTaskSuccess();
  };

  return (
    <>
      <FullHeightContentModal
        title="Import Products from Xero"
        isModalOpen={impotModal}
        closeModal={() => setImportModal(false)}
        content={
          <ImportXeroProductsForm
            importSuccess={closeImportModalWithSuccess}
          />
        }
      />

      <XeroButton
        name="Import Products from Xero"
        action={syncCustomersFromXero}
      />
    </>
  );
}
