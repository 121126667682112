import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import { ScreenTitle } from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { ErrorMessage, Loader } from "../../../components/viewComponents";
import { SimpleDialog } from "../../../components/viewComponents";
import {
  purchaseOrderOptionsRequest,
  createNewPurchaseOrderRequest,
  purchaseOrderDetailsRequest,
  latestProductPurchaseDetailsRequest,
} from "../../../actions/purchaseOrderActions";
import PurchaseOrderForm from "./PurchaseOrderForm";
import useDocumentTitle from "../../../useDocumentTitle";
import {convtPrice, generateOrderNumber } from "../../../functions/functions";

export default function NewPurchaseOrderScreen() {
  useDocumentTitle("New Purchase Order");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const { loading, purchaseOrderOptions, error, success } = useSelector(
    (state) => state.purchaseOrderOptions
  );

  // from clone
  const cloneOrderId = location.state ? location.state.orderId : "";
  // from dashboard stock alert
  const alertProductId = location.state ? location.state.famid : "";
  const alertVariantCode = location.state ? location.state.variantCode : "";

  useEffect(() => {
    dispatch(purchaseOrderOptionsRequest());
    if (cloneOrderId) {
      dispatch(purchaseOrderDetailsRequest(cloneOrderId, "clone"));
    }
    if (alertProductId) {
      dispatch(
        latestProductPurchaseDetailsRequest(alertProductId, alertVariantCode)
      );
    }
  }, [dispatch, cloneOrderId, alertProductId, alertVariantCode]);



  const {
    loading: cloneLoading,
    orderInfo,
    error: cloneError,
    success: cloneSuccess,
  } = useSelector((state) => state.purchaseOrderDetails);

  const {
    loading: reorderLoading,
    purchaseInfo,
    error: reorderError,
    success: reorderSuccess,
  } = useSelector((state) => state.latestPurchaseDetails);

  const {
    loading: createLoading,
    orderId,
    error: createError,
    success: createSuccess,
  } = useSelector((state) => state.createPurchaseOrder);

  const newOrderInfo = () => {
    let order_num = "";
    let order_pattern = purchaseOrderOptions.poSettings.find((x) => x.settingName === "purchase_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let tax_included = false;
    let tax_rule = purchaseOrderOptions.poSettings.find((x) => x.settingName === "purchase_order_default_tax_rule");
    if(tax_rule){
      tax_included = tax_rule.settingValue === "inclusive" ? true : false;
    }

    let data = {
      orderNumber: order_num,
      supplier: "",
      taxRate: "",
      currency: "",
      supplierName: "",
      supplierBillingName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      postalAddress: "",
      billingAddress: "",
      taxIncluded: tax_included,
      expectedDate: "",
      project: "",
      deliveryAddress:
        purchaseOrderOptions.locationOptions.length > 0
          ? purchaseOrderOptions.locationOptions.filter(
              (x) => x.id === userDetails.data.locationId
            ).length
            ? purchaseOrderOptions.locationOptions.filter(
                (x) => x.id === userDetails.data.locationId
              )[0].address
            : ""
          : "",
      location: purchaseOrderOptions.locationOptions.filter(
        (x) => x.id === userDetails.data.locationId
      )[0],
      notes: "",
    };
    return data;
  };


  const newOrderSummary = () => {
    return {
      productsTotal: 0,
      additionalCostTotal: 0,
    };
  };

  const cloneOrderBasicInfo = () => {
    let order_num = "";
    let order_pattern = purchaseOrderOptions.poSettings.find((x) => x.settingName === "purchase_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let data = {
      id: orderInfo.basicInfo.id,
      orderNumber: order_num,
      supplier: purchaseOrderOptions.supplierOptions.filter(
        (x) => x.value === orderInfo.basicInfo.supplier
      )[0],
      supplierName: orderInfo.basicInfo.supplier
      ? orderInfo.basicInfo.supplier
      : "",
      supplierBillingName: orderInfo.basicInfo.supplierBillingName
      ? orderInfo.basicInfo.supplierBillingName
      : "",
      firstName: orderInfo.basicInfo.firstName
        ? orderInfo.basicInfo.firstName
        : "",
      lastName: orderInfo.basicInfo.lastName
        ? orderInfo.basicInfo.lastName
        : "",
      email: orderInfo.basicInfo.email ? orderInfo.basicInfo.email : "",
      phone: orderInfo.basicInfo.phone ? orderInfo.basicInfo.phone : "",
      postalAddress: orderInfo.basicInfo.postalAddress
        ? orderInfo.basicInfo.postalAddress
        : {},
      billingAddress: orderInfo.basicInfo.billingAddress
        ? orderInfo.basicInfo.billingAddress
        : {},
      taxRate: purchaseOrderOptions.taxrateOptions.filter(
        (x) => x.rate === orderInfo.basicInfo.taxrate
      )[0],
      taxIncluded: orderInfo.basicInfo.taxIncluded,
      currency: orderInfo.basicInfo.currency,
      expectedDate: "",
      project: "",
      deliveryAddress: orderInfo.basicInfo.deliveryAddress
        ? orderInfo.basicInfo.deliveryAddress
        : "",
      location: purchaseOrderOptions.locationOptions.filter(
        (x) => x.value === orderInfo.basicInfo.location
      )[0],
      notes: orderInfo.basicInfo.notes ? orderInfo.basicInfo.notes : "",
    };
    return data;
  };

  const reorderBasicInfo = () => {
    let order_num = "";
    let order_pattern = purchaseOrderOptions.poSettings.find((x) => x.settingName === "purchase_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let basicInfo = {
      id: "",
      orderNumber: order_num,
      supplier: purchaseOrderOptions.supplierOptions.filter(
        (x) => x.id === purchaseInfo.basicInfo.supplier_id
      )[0],
      supplierName: purchaseInfo.basicInfo.supplierName
      ? purchaseInfo.basicInfo.supplierName
      : "",
      supplierBillingName: purchaseInfo.basicInfo.supplierBillingName
      ? purchaseInfo.basicInfo.supplierBillingName
      : "",
      firstName: purchaseInfo.basicInfo.firstName
        ? purchaseInfo.basicInfo.firstName
        : "",
      lastName: purchaseInfo.basicInfo.lastName
        ? purchaseInfo.basicInfo.lastName
        : "",
      email: purchaseInfo.basicInfo.email ? purchaseInfo.basicInfo.email : "",
      phone: purchaseInfo.basicInfo.phone ? purchaseInfo.basicInfo.phone : "",
      postalAddress: purchaseInfo.basicInfo.postalAddress
        ? purchaseInfo.basicInfo.postalAddress
        : {},
      billingAddress: purchaseInfo.basicInfo.billingAddress
        ? purchaseInfo.basicInfo.billingAddress
        : {},
      taxRate: purchaseOrderOptions.taxrateOptions.filter(
        (x) => x.id === purchaseInfo.basicInfo.taxrate_id
      )[0],
      taxIncluded: purchaseInfo.basicInfo.taxIncluded,
      currency: purchaseInfo.basicInfo.currency,
      expectedDate: "",
      project: "",
      deliveryAddress: purchaseInfo.basicInfo.deliveryAddress
        ? purchaseInfo.basicInfo.deliveryAddress
        : "",
      location: purchaseOrderOptions.locationOptions.filter(
        (x) => x.id === purchaseInfo.basicInfo.location_id
      )[0],
      notes: purchaseInfo.basicInfo.notes ? purchaseInfo.basicInfo.notes : "",
    };

    return basicInfo;
  };

  const reorderOrderItem = () => {
    let orderItems = [
      {
        famid: purchaseInfo.productDetails.famid
          ? purchaseInfo.productDetails.famid
          : "",
        productName: purchaseInfo.productDetails.name
          ? purchaseInfo.productDetails.name
          : "",
        sku: purchaseInfo.productDetails.sku
          ? purchaseInfo.productDetails.sku
          : "",
        variant: purchaseInfo.productDetails.variant
          ? purchaseInfo.productDetails.variant
          : "",
        variantCode: purchaseInfo.productDetails.variant_code
          ? purchaseInfo.productDetails.variant_code
          : "",
        quantity: "",
        unitName: purchaseInfo.productDetails.unitName
          ? purchaseInfo.productDetails.unitName
          : "",
        unitLatestCost: purchaseInfo.productDetails.latest_cost
          ? purchaseInfo.productDetails.latest_cost
          : "",
        unitCost: purchaseInfo.productDetails.unitCost
          ? purchaseInfo.productDetails.unitCost
          : "",
        discount: "",
        totalCost: "",
      },
    ];
    return orderItems;
  };

  // clone product
  const [cloneDialog, setCloneDialog] = useState(true);

  // add new po dialog
  const [createDialog, setCreateDialog] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// submit //////////////////////////////////////////////
  const addNewPurchaseOrder = (values, status) => {
    setCreateDialog(true);
    let isApproved = false;
    if (status === "Approved") {
      isApproved = true;
    }

    let orderItemValues = [];

    values.orderItems.forEach((prod) => {
      let costExclTax;
      if (values.orderInfo.taxIncluded) {
        costExclTax = convtPrice(
          prod.unitCost / (1 + values.orderInfo.taxRate.rate / 100)
        );
      } else {
        costExclTax = prod.unitCost;
      }

      let eachItem = {
        famid: prod.famid,
        variantCode: prod.variantCode,
        variantSku: prod.variantSku,
        quantity: prod.quantity,
        cost: prod.unitCost,
        costExclTax: costExclTax,
        discount: prod.discount,
        totalCost: prod.totalCost,
      };

      orderItemValues.push(eachItem);
    });

    let orderTotalExcltax;
    let orderTotalIncltax;
    let orderTax;

    if (values.orderInfo.taxIncluded) {
      orderTotalIncltax =
        Number(values.orderSummary.productsTotal) +
        Number(values.orderSummary.additionalCostTotal);

      orderTotalExcltax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) /
          (1 + values.orderInfo.taxRate.rate / 100)
      );

      orderTax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) -
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) /
            (1 + values.orderInfo.taxRate.rate / 100)
      );
    } else {
      orderTotalExcltax =
        Number(values.orderSummary.productsTotal) +
        Number(values.orderSummary.additionalCostTotal);

      orderTax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) *
          (values.orderInfo.taxRate.rate / 100)
      );

      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) +
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) *
            (values.orderInfo.taxRate.rate / 100)
      );
    }

    values.costs.forEach((c) => {
      let addCostExclTax;
      if (values.orderInfo.taxIncluded) {
        addCostExclTax = convtPrice(
          c.cost / (1 + values.orderInfo.taxRate.rate / 100)
        );
      } else {
        addCostExclTax = c.cost;
      }
      c["costExclTax"] = addCostExclTax;
    });

    if (typeof values.orderInfo.postalAddress === "object") {
      values.orderInfo.postalAddress = JSON.stringify(
        values.orderInfo.postalAddress
      );
    }
    if (typeof values.orderInfo.billingAddress === "object") {
      values.orderInfo.billingAddress = JSON.stringify(
        values.orderInfo.billingAddress
      );
    }

    let purchaseOrderValues = {
      approved: isApproved,
      status: status,
      orderInfo: {
        orderNumber: values.orderInfo.orderNumber,
        supplierId: values.orderInfo.supplier.id,
        taxrateId: values.orderInfo.taxRate.id,
        taxrate: values.orderInfo.taxRate.rate,
        taxIncluded: values.orderInfo.taxIncluded,
        currency: values.orderInfo.currency,
        supplierName: values.orderInfo.supplierName,
        supplierBillingName: values.orderInfo.supplierBillingName,
        firstName: values.orderInfo.firstName,
        lastName: values.orderInfo.lastName,
        email: values.orderInfo.email,
        phone: values.orderInfo.phone,
        postalAddress: values.orderInfo.postalAddress,
        billingAddress: values.orderInfo.billingAddress,
        locationId: values.orderInfo.location.id,
        deliveryAddress: values.orderInfo.deliveryAddress,
        project: values.orderInfo.project ? values.orderInfo.project.id : "",
        expectedDate: values.orderInfo.expectedDate
          ? Math.floor(values.orderInfo.expectedDate.getTime() / 1000)
          : "",
        notes: values.orderInfo.notes,
        // calcute order total amount
        orderTotalExcltax: orderTotalExcltax,
        orderTotalIncltax: orderTotalIncltax,
        orderTax: orderTax,
      },
      orderItems: orderItemValues,
      costs: values.costs,
      files: values.files,
    };

    dispatch(createNewPurchaseOrderRequest(purchaseOrderValues));
  };

  const closeCreateDialogWithSuccess = () => {
    navigate(`/purchase/order/preview/${orderId}`, {
      replace: true,
    });
  };

  return (
    <div className={commonStyle.pageContainer}>
      {cloneOrderId ? (
        <SimpleDialog
          title="Clone Purchase Order"
          successMessage="Purchase order cloning was successful."
          isDialogOpen={cloneDialog}
          closeDialog={() => setCloneDialog(false)}
          loading={cloneLoading}
          loadingMessage="Cloning purchase order details..."
          success={cloneSuccess}
          error={cloneError}
          confirmAction={() => setCloneDialog(false)}
        />
      ) : (
        <></>
      )}

      <SimpleDialog
        title="Create New Purchase Order"
        successMessage="New purchase order added successfully."
        isDialogOpen={createDialog}
        closeDialog={() => setCreateDialog(false)}
        loading={createLoading}
        loadingMessage="Processing request..."
        success={createSuccess}
        error={createError}
        confirmAction={closeCreateDialogWithSuccess}
      />

      <Breadcrumbs screenName="New Purchase Order" />
      <ScreenTitle title="New Purchase Order" status="New" />
      <div className={commonStyle.pageContentContainer}>
        {userDetailsSuccess ? (
          loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            cloneOrderId ? (
              cloneLoading ? (
                <Loader mess="Cloning purchase order details, please wait a moment..." />
              ) : cloneSuccess ? (
                <PurchaseOrderForm
                  initialOrderInfo={cloneOrderBasicInfo}
                  initialOrderItems={orderInfo.orderItems}
                  initialCosts={orderInfo.orderCosts}
                  initialOrderSummary={orderInfo.orderSummary}
                  initialFiles={[]}
                  submit={addNewPurchaseOrder}
                  type="clone"
                  showDraftBtn={true}
                />
              ) : cloneError ? (
                <ErrorMessage mess={cloneError} />
              ) : (
                <></>
              )
            ) : alertProductId ? (
              reorderLoading ? (
                <Loader mess="Cloning purchase order details, please wait a moment..." />
              ) : reorderSuccess ? (
                <PurchaseOrderForm
                  initialOrderInfo={reorderBasicInfo}
                  initialOrderItems={reorderOrderItem}
                  initialCosts={[]}
                  initialFiles={[]}
                  initialOrderSummary={newOrderSummary}
                  submit={addNewPurchaseOrder}
                  type="clone"
                  showDraftBtn={true}
                />
              ) : reorderError ? (
                <ErrorMessage mess={reorderError} />
              ) : (
                <></>
              )
            ) : (
              <PurchaseOrderForm
                initialOrderInfo={newOrderInfo}
                initialOrderItems={[]}
                initialOrderSummary={newOrderSummary}
                initialCosts={[]}
                initialFiles={[]}
                submit={addNewPurchaseOrder}
                type="new"
                showDraftBtn={true}
              />
            )
          ) : (
            <ErrorMessage mess={error} />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
