import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createXeroImportTaskRequest, importXeroContactsRequest } from "../../../api/importXeroDataServices";
import {
  SimpleBlueButton
} from "../../../components/editComponents";
import {
  Loader,
  SimpleDialog,
  TextErrorMessage,
  ViewDateWithTime
} from "../../../components/viewComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import integrationStyle from "../integrationStyle.module.css";


export default function ImportXeroSuppliersForm({ importSuccess }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [importStatus, setImportStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [xeroContacts, setXeroContacts] = useState([]);
  const [lastImportTime, setLastImportTime] = useState("");
  const [dialog, setDialog] = useState(false);

  const importXeroSuppliers = async () => {
    try {
      setRequestStatus((preState) => ({ ...preState, loading: true }));
      const responseData = await importXeroContactsRequest("supplier", config);

      if (responseData.success) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
        setXeroContacts(responseData.data);
        setLastImportTime(responseData.latstImportTime);
      } else {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: false,
          error: "Import suppliers form Xero failed.",
        }));
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  useEffect(() => {
    importXeroSuppliers();
  }, [dispatch]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  const saveSuppliersRequest = async () => {
    if (!xeroContacts.length) {
      return;
    }
    try {
      setDialog(true);
      setImportStatus((preState) => ({ ...preState, loading: true }));
      const responseData = await createXeroImportTaskRequest(
        "supplier",
        xeroContacts,
        config
      );
      if (responseData.success) {
        setImportStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setImportStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    importSuccess();
  };

  return (
    <div style={{ height: "100%", paddingTop: "20px" }}>
      {importStatus.error ? (
        <TextErrorMessage mess={importStatus.error} />
      ) : (
        <></>
      )}

      <div className={integrationStyle.resultWrapper}>
      <SimpleDialog
          title="Import Suppliers From Xero"
          successMessage="Create import suppliers from Xero task successfully."
          isDialogOpen={dialog}
          closeDialog={() => setDialog(false)}
          loading={importStatus.loading}
          loadingMessage="Processing request..."
          success={importStatus.success}
          error={importStatus.error}
          confirmAction={closeDialogWithSuccess}
        />


        {requestStatus.loading ? (
          <Loader mess="Requesting contact from Xero..." />
        ) : requestStatus.success ? (
          <div style={{ height: "calc(100vh - 240px)", position: "relative" }}>
            <div className="flex flex-row items-center">
              <span>Found</span>
              <span className="text-brandColor font-medium mx-1">
                {xeroContacts.length}
              </span>
              <span> suppliers from your Xero system</span>{" "}
              {lastImportTime && (
                <div className="flex flex-row items-center">
                  <span className="mr-1">, since you last import on</span>
                  <ViewDateWithTime value={lastImportTime} />
                </div>
              )}
            </div>

            {xeroContacts.length > 0 ? (
              <div className={integrationStyle.xeroProductsCon}>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "22px",
                          }}
                        ></div>
                      </th>
                      <th>Supplier Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {xeroContacts.map((elem, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "22px",
                            }}
                          ></div>
                        </td>
                        <td>{elem.xero_contact_name} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
            <div style={{ position: "absolute", bottom: 0 }}>
              <SimpleBlueButton
                name="Import Suppliers from Xero"
                action={saveSuppliersRequest}
              />
            </div>
          </div>
        ) : requestStatus.error ? (
          <TextErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
