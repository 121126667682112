import React, { useState } from "react";
import { XeroButton } from "../../../components/editComponents";
import { FullHeightModal } from "../../../components/viewComponents";
import ImportXeroCustomersForm from "./ImportXeroCustomersForm";
import ImportXeroSuppliersForm from "./ImportXeroSuppliersForm";


export default function ImportXeroSuppliers({onTaskSuccess}) {

  const [impotModal, setImportModal] = useState(false);

  const syncCustomersFromXero = () => {
    setImportModal(true);
  };

  const closeImportModalWithSuccess = () => {
    setImportModal(false);
    onTaskSuccess();
  };

  return (
    <>
      <FullHeightModal
        title="Import Suppliers from Xero"
        isModalOpen={impotModal}
        closeModal={() => setImportModal(false)}
        content={
          <ImportXeroSuppliersForm
            importSuccess={closeImportModalWithSuccess}
          />
        }
      />

      <XeroButton
        name="Import Suppliers from Xero"
        action={syncCustomersFromXero}
      />
    </>
  );
}
