import React from "react";
import { NavLink } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";

export default function ProductIOTabs({ tabName }) {

  let className1;
  let className3;

  if (tabName === "importProducts") {
    className1 = commonStyle.activeItem;
  } else {
    className1 = commonStyle.tabItem;
  }
  if (tabName === "exportProducts") {
    className3 = commonStyle.activeItem;
  } else {
    className3 = commonStyle.tabItem;
  }

  return (
    <div className={commonStyle.tabsCon}>
      <NavLink
        to="/inventory/products/import"
        exact="true"
        className={className1}
      >
        Import Products
      </NavLink>
      <NavLink
        to="/inventory/products/export"
        exact="true"
        className={className3}
      >
        Export Products
      </NavLink>
    </div>
  );
  
}
