import {
  PURCHASE_ORDER_LIST_FAIL,
  PURCHASE_ORDER_LIST_REQUEST,
  PURCHASE_ORDER_LIST_SUCCESS,
  PURCHASE_ORDER_DETAILS_FAIL,
  PURCHASE_ORDER_DETAILS_REQUEST,
  PURCHASE_ORDER_DETAILS_SUCCESS,
  PURCHASE_ORDER_OPTIONS_FAIL,
  PURCHASE_ORDER_OPTIONS_REQUEST,
  PURCHASE_ORDER_OPTIONS_SUCCESS,
  SEARCH_PURCHASE_PRODUCT_FAIL,
  SEARCH_PURCHASE_PRODUCT_REQUEST,
  SEARCH_PURCHASE_PRODUCT_SUCCESS,
  PURCHASE_ORDER_CREATE_REQUEST,
  PURCHASE_ORDER_CREATE_SUCCESS,
  PURCHASE_ORDER_CREATE_FAIL,
  PURCHASE_ORDER_EDIT_REQUEST,
  PURCHASE_ORDER_EDIT_SUCCESS,
  PURCHASE_ORDER_EDIT_FAIL,
  RECEIVE_STOCK_REQUEST,
  RECEIVE_STOCK_SUCCESS,
  RECEIVE_STOCK_FAIL,
  LATEST_PRODUCT_PURCHASE_DETAILS_REQUEST,
  LATEST_PRODUCT_PURCHASE_DETAILS_SUCCESS,
  LATEST_PRODUCT_PURCHASE_DETAILS_FAIL,
  PURCHASE_ORDER_ACTIONS_REQUEST,
  PURCHASE_ORDER_ACTIONS_SUCCESS,
  PURCHASE_ORDER_ACTIONS_FAIL,
  PURCHASE_ORDER_EDIT_AFTER_RECEIVE_REQUEST,
  PURCHASE_ORDER_EDIT_AFTER_RECEIVE_SUCCESS,
  PURCHASE_ORDER_EDIT_AFTER_RECEIVE_FAIL,
} from "../constants/purchaseOrderConstants";

export const purchaseOrderOptionsReducer = (
  state = { purchaseOrderOptions: {} },
  action
) => {
  switch (action.type) {
    case PURCHASE_ORDER_OPTIONS_REQUEST:
      return { loading: true };
    case PURCHASE_ORDER_OPTIONS_SUCCESS:
      return {
        loading: false,
        purchaseOrderOptions: action.payload,
        success: true,
      };
    case PURCHASE_ORDER_OPTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchPurchaseProductReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case SEARCH_PURCHASE_PRODUCT_REQUEST:
      return { loading: true };
    case SEARCH_PURCHASE_PRODUCT_SUCCESS:
      return { loading: false, products: action.payload, success: true };
    case SEARCH_PURCHASE_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const createPurchaseOrderReducer = (state = {orderId:""}, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_CREATE_REQUEST:
      return { loading: true };
    case PURCHASE_ORDER_CREATE_SUCCESS:
      return { loading: false, orderId: action.payload, success:true };
    case PURCHASE_ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const purchaseOrderListReducer = (
  state = { purchaseOrders: [] },
  action
) => {
  switch (action.type) {
    case PURCHASE_ORDER_LIST_REQUEST:
      return { loading: true, success: false };
    case PURCHASE_ORDER_LIST_SUCCESS:
      return { loading: false, purchaseOrders: action.payload, success: true };
    case PURCHASE_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const purchaseOrderDetailsReducer = (
  state = { orderInfo: [] },
  action
) => {
  switch (action.type) {
    case PURCHASE_ORDER_DETAILS_REQUEST:
      return { loading: true, success: false };
    case PURCHASE_ORDER_DETAILS_SUCCESS:
      return { loading: false, orderInfo: action.payload, success: true };
    case PURCHASE_ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const purchaseOrderActionsReducer = (
  state = { actions: [] },
  action
) => {
  switch (action.type) {
    case PURCHASE_ORDER_ACTIONS_REQUEST:
      return { loading: true, success: false };
    case PURCHASE_ORDER_ACTIONS_SUCCESS:
      return { loading: false, actions: action.payload, success: true };
    case PURCHASE_ORDER_ACTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const editPurchaseOrderReducer = (state = {orderId:""}, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_EDIT_REQUEST:
      return { loading: true };
    case PURCHASE_ORDER_EDIT_SUCCESS:
      return { loading: false, orderId: action.payload, success:true };
    case PURCHASE_ORDER_EDIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const editPurchaseOrderAfterReceiveReducer = (state = {orderId:""}, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_EDIT_AFTER_RECEIVE_REQUEST:
      return { loading: true };
    case PURCHASE_ORDER_EDIT_AFTER_RECEIVE_SUCCESS:
      return { loading: false, orderId: action.payload, success:true };
    case PURCHASE_ORDER_EDIT_AFTER_RECEIVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const receiveStockReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_STOCK_REQUEST:
      return { loading: true };
    case RECEIVE_STOCK_SUCCESS:
      return { loading: false, success:true };
    case RECEIVE_STOCK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const latestProductPurchaseDetailsReducer = (
  state = { purchaseInfo: [] },
  action
) => {
  switch (action.type) {
    case LATEST_PRODUCT_PURCHASE_DETAILS_REQUEST:
      return { loading: true, success: false };
    case LATEST_PRODUCT_PURCHASE_DETAILS_SUCCESS:
      return { loading: false, purchaseInfo: action.payload, success: true };
    case LATEST_PRODUCT_PURCHASE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

