import React, { useState } from "react";
import {
  Tag,
  ViewDateText,
} from "../../components/viewComponents";
import { convtQuantity } from "../../functions/functions";
import commonStyle from "../../style/commonStyle.module.css";
import LocationTab from "./LocationTab";
import inventoryStyle from "./inventoryStyle.module.css";

export default function SerialBatchNumbers({ locations, numbers, hasProject }) {
  const [activeLocationId, setActiveLocationId] = useState(
    locations[0].locationId
  );
  const changeLocationAction = (locationId) => {
    setActiveLocationId(locationId);
  };
  return (
    <div>
      <LocationTab
        locationsData={locations}
        changeLocation={changeLocationAction}
        activeId={activeLocationId}
      />

      <div className={inventoryStyle.logsContentWrapper}>
        <div className={inventoryStyle.logsContentCon}>
          {numbers.length > 0 ? (
            <table className={commonStyle.logsTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  {hasProject && <th>Project</th>}
                  <th>Expire Date</th>
                  <th>Added Date</th>
                </tr>
              </thead>
              <tbody>
                {numbers.find(
                  (ele) => Number(ele.locationId) === Number(activeLocationId)
                ) ? (
                  numbers
                    .filter(
                      (ele) =>
                        Number(ele.locationId) === Number(activeLocationId)
                    )
                    .map((item, index3) => (
                      <tr key={index3} className="h-8">
                        <td>{item.code}</td>

                        <td>{convtQuantity(item.quantity_in_stock)}</td>
                        <td>
                          {item.quantity_in_stock > 0 ? (
                            <Tag
                              name={`${item.quantity_in_stock} Stock on hand`}
                              color="green"
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        {hasProject && (
                          <td>
                            {item.projectCode ? (
                              <span>
                                {item.projectCode}-{item.projectName}
                              </span>
                            ) : (
                              <></>
                            )}
                          </td>
                        )}
                        <td>
                          {item.expire_date ? (
                            <ViewDateText value={item.expire_date} />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <ViewDateText value={item.atime} />
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td>no records</td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <p>No records</p>
          )}
        </div>
      </div>
    </div>
  );
}
