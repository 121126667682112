import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import { ScreenTitle } from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ErrorMessage,
  InsufficientStockSaleOrderDialog,
  Loader,
  TextErrorMessage,
} from "../../../components/viewComponents";
import {
  SimpleDialog,
  ProceedSaleOrderDialog,
} from "../../../components/viewComponents";
import { convtPrice } from "../../../functions/functions";
import {
  saleOrderOptionsRequest,
  editSaleOrderRequest,
  saleOrderDetailsRequest,
} from "../../../actions/saleOrderActions";

import SaleOrderForm from "./SaleOrderForm";
import useDocumentTitle from "../../../useDocumentTitle";

export default function EditSaleOrderScreen() {
  useDocumentTitle("Edit Sale Order");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id: soid } = useParams();
  const { loading, options, error, success } = useSelector(
    (state) => state.saleOrderOptions
  );

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const {
    loading: detailsLoading,
    orderInfo,
    error: detailsError,
    success: detailsSuccess,
  } = useSelector((state) => state.saleOrderDetails);

  useEffect(() => {
    dispatch(saleOrderOptionsRequest());
    if (soid) {
      dispatch(saleOrderDetailsRequest(soid, "edit"));
    }
  }, [dispatch, soid]);

  const {
    loading: editLoading,
    orderId,
    error: editError,
    success: editSuccess,
  } = useSelector((state) => state.editSaleOrder);

  const orderBasicInfo = () => {
    let data = {
      id: orderInfo.basicInfo.id,
      noCustomer: orderInfo.basicInfo.noCustomer,
      orderNumber: orderInfo.basicInfo.orderNumber,
      suInvoice: orderInfo.basicInfo.suInvoice,
      invNumber: orderInfo.basicInfo.invoiceNumberSu,
      customer: options.customerOptions.filter(
        (x) => x.id === orderInfo.basicInfo.customerId
      )[0],
      taxRate: options.taxrateOptions.filter(
        (x) => x.rate === orderInfo.basicInfo.taxrate
      )[0],
      taxIncluded: orderInfo.basicInfo.taxIncluded,
      currency: orderInfo.basicInfo.currency,
      customerName: orderInfo.basicInfo.customerName
        ? orderInfo.basicInfo.customerName
        : "",
      customerBillingName: orderInfo.basicInfo.customerBillingName
        ? orderInfo.basicInfo.customerBillingName
        : "",
      firstName: orderInfo.basicInfo.firstName
        ? orderInfo.basicInfo.firstName
        : "",
      lastName: orderInfo.basicInfo.lastName
        ? orderInfo.basicInfo.lastName
        : "",
      email: orderInfo.basicInfo.email ? orderInfo.basicInfo.email : "",
      phone: orderInfo.basicInfo.phone ? orderInfo.basicInfo.phone : "",
      postalAddress: orderInfo.basicInfo.shippingAddress
        ? orderInfo.basicInfo.shippingAddress
        : {},
      billingAddress: orderInfo.basicInfo.billingAddress
        ? orderInfo.basicInfo.billingAddress
        : {},
      location: options.locationOptions.filter(
        (x) => x.id === orderInfo.basicInfo.locationId
      )[0],
      paymentTerm: orderInfo.basicInfo.paymentTerm,
      priceTier: options.priceTierOptions.filter(
        (x) => x.id === orderInfo.basicInfo.priceTierId
      )[0],
      project: options.projectsOptions.filter(
        (x) => x.id === orderInfo.basicInfo.project_id
      ).length
        ? options.projectsOptions.filter(
            (x) => x.id === orderInfo.basicInfo.project_id
          )[0]
        : "",
      isNeedShipping: orderInfo.basicInfo.isNeedShipping,
      notes: orderInfo.basicInfo.notes ? orderInfo.basicInfo.notes : "",
      status: orderInfo.basicInfo.rental_details,
    };
    return data;
  };

  const orderFiles = () => {
    let copy = [...orderInfo.orderFiles];
    let data = [];
    copy.forEach((x) => {
      data.push({
        fileName: x.fileName,
        uri: x.uri,
        loading: false,
        error: "",
      });
    });
    return data;
  };

  // edit order submit values
  const [editOrderValues, setEditOrderValues] = useState({});

  // edit sale order
  const [editDraftDialog, setEditDraftDialog] = useState(false);
  const [editApprovedDialog, setEditApprovedDialog] = useState(false);
  // edit sale order
  const [insufficientStockDialog, setInsufficientStockDialog] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// submit //////////////////////////////////////////////
  const openEditSaleOrderDialog = (values, status) => {
    let isPackageSale = false;
    let isInsufficientStock = false;
    let isApproved = false;

    if (values.orderItems.some((item) => item.type === "package")) {
      isPackageSale = true;
    }

    if (status === "Approved") {
      isApproved = true;
    }

    let orderItemValues = [];

    values.orderItems.forEach((prod) => {
      if (
        status === "Approved" &&
        prod.type !== "package" &&
        Number(prod.quantity) > Number(prod.stockOnHand)
      ) {
        isInsufficientStock = true;
      }

      let eachItem = {
        type: prod.type,
        packageId: prod.packageId,
        packageName: prod.packageName,
        productName: prod.productName,
        productType: prod.productType,
        variant: prod.variant,
        famid: prod.famid,
        variantCode: prod.variantCode,
        variantSku: prod.variantSku,
        quantity: prod.quantity,
        price: prod.unitPrice,
        discount: prod.discount,
        totalPrice: prod.totalPrice,
        serialized: prod.serialized,
        batchTracked: prod.batchTracked,
        consumeSerialNumbers: prod.consumeSerialNumbers,
        consumeBatchNumbers: prod.consumeBatchNumbers,
      };

      orderItemValues.push(eachItem);
    });

    let orderTotalExcltax;
    let orderTotalIncltax;
    let orderTax;

    if (values.orderInfo.taxIncluded) {
      orderTotalExcltax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) /
          (1 + values.orderInfo.taxRate.rate / 100)
      );

      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );

      orderTax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) -
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) /
            (1 + values.orderInfo.taxRate.rate / 100)
      );
    } else {
      orderTotalExcltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );
      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) +
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) *
            (values.orderInfo.taxRate.rate / 100)
      );
      orderTax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) *
          (values.orderInfo.taxRate.rate / 100)
      );
    }

    if (typeof values.orderInfo.postalAddress === "object") {
      values.orderInfo.postalAddress = JSON.stringify(
        values.orderInfo.postalAddress
      );
    }
    if (typeof values.orderInfo.billingAddress === "object") {
      values.orderInfo.billingAddress = JSON.stringify(
        values.orderInfo.billingAddress
      );
    }

    let saleOrderValues = {
      orderSource: orderInfo.basicInfo.source,
      isPackageSale: isPackageSale,
      approved: isApproved,
      status: status,
      orderInfo: {
        orderNumber: values.orderInfo.orderNumber,
        suInvoice: values.orderInfo.suInvoice ? 1 : 0,
        invNumber: values.orderInfo.invNumber,
        customerId: values.orderInfo.customer
          ? values.orderInfo.customer.id
          : "",
        taxrateId: values.orderInfo.taxRate.id,
        taxrate: values.orderInfo.taxRate.rate,
        taxIncluded: values.orderInfo.taxIncluded,
        currency: values.orderInfo.currency,
        locationId: values.orderInfo.location.id,
        customerName: values.orderInfo.customerName,
        customerBillingName: values.orderInfo.customerBillingName,
        firstName: values.orderInfo.firstName,
        lastName: values.orderInfo.lastName,
        email: values.orderInfo.email,
        phone: values.orderInfo.phone,
        postalAddress: values.orderInfo.postalAddress,
        billingAddress: values.orderInfo.billingAddress,
        paymentTerm: values.orderInfo.paymentTerm,
        priceTierId: values.orderInfo.priceTie
          ? values.orderInfo.priceTier.id
          : "",
        projectId: values.orderInfo.project ? values.orderInfo.project.id : "",
        isNeedShipping: values.orderInfo.isNeedShipping ? 1 : 0,
        notes: values.orderInfo.notes,
        orderTotalExcltax: orderTotalExcltax,
        orderTotalIncltax: orderTotalIncltax,
        orderTax: orderTax,
      },
      orderItems: orderItemValues,
      costs: values.costs,
      files: values.files,
    };

    setEditOrderValues(saleOrderValues);

    if (!isApproved) {
      setEditDraftDialog(true);
      dispatch(editSaleOrderRequest(saleOrderValues, soid));
    } else {
      if (isInsufficientStock) {
        setInsufficientStockDialog(true);
      } else {
        setEditApprovedDialog(true);
      }
    }
  };

  const editSaleOrder = () => {
    dispatch(editSaleOrderRequest(editOrderValues, soid));
  };

  const proceedInsufficiectStockOrder = (saveType) => {
    let orderValuesCopy = { ...editOrderValues };
    orderValuesCopy.status = saveType;
    orderValuesCopy.approved = false;
    dispatch(editSaleOrderRequest(orderValuesCopy, soid));
  };

  const closeDialogWithSuccess = () => {
    navigate(`/sale/order/preview/${orderId}`, {
      replace: true,
      from: location,
    });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title={"Edit Sale Order"}
        successMessage="Order updated successfully."
        isDialogOpen={editDraftDialog}
        closeDialog={() => setEditDraftDialog(false)}
        loading={editLoading}
        loadingMessage="Processing request..."
        success={editSuccess}
        error={editError}
        confirmAction={closeDialogWithSuccess}
      />

      <ProceedSaleOrderDialog
        title={"Approve Sale Order"}
        successMessage="Order approved successfully."
        isDialogOpen={editApprovedDialog}
        closeDialog={() => setEditApprovedDialog(false)}
        closeDialogWithSuccess={closeDialogWithSuccess}
        loading={editLoading}
        loadingMessage="Processing request..."
        success={editSuccess}
        error={editError}
        confirmAction={editSaleOrder}
        text1={"The stock on hand quantity will be reduced."}
        text2={
          detailsSuccess && orderInfo.basicInfo.source === "xerohook"
            ? "This order is synced from Xero. After approval, any updates from the original source will not reflect in this order. If adjustments to items are needed after approval, please use the stock adjustment function to maintain accurate stock levels."
            : "An invoice will be created in your Xero System, and the stock level will be updated within your Xero system (Only applicable for inventory tracked items)."
        }
      />

      <InsufficientStockSaleOrderDialog
        title={"Create New Sale Order"}
        successMessage="New order created successfully."
        isDialogOpen={insufficientStockDialog}
        closeDialog={() => setInsufficientStockDialog(false)}
        closeDialogWithSuccess={closeDialogWithSuccess}
        loading={editLoading}
        loadingMessage="Processing request..."
        success={editSuccess}
        error={editError}
        confirmAction={proceedInsufficiectStockOrder}
      />

      <Breadcrumbs screenName={"Edit Sale Order"} />
      <ScreenTitle title={"Edit Sale Order"} status="Edit" />
      <div className={commonStyle.pageContentContainer}>
        {userDetailsSuccess ? (
          loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            detailsLoading ? (
              <Loader mess="Requesting order details, please wait a moment..." />
            ) : detailsSuccess ? (
              <>
                {orderInfo.basicInfo.status === "Draft" ||
                orderInfo.basicInfo.status === "Back Order" ? (
                  <SaleOrderForm
                    initialOrderInfo={orderBasicInfo}
                    initialOrderItems={orderInfo.orderItems}
                    initialCosts={orderInfo.orderCosts}
                    initialFiles={orderFiles}
                    initialOrderSummary={orderInfo.orderSummary}
                    submit={openEditSaleOrderDialog}
                    type="edit"
                    itemEditable={
                      orderInfo.basicInfo.source === "xerohook" ? false : true
                    }
                  />
                ) : (
                  <TextErrorMessage
                    mess={`The order status is "${orderInfo.basicInfo.status}", can not be edited from this page`}
                  />
                )}
              </>
            ) : (
              <ErrorMessage mess={detailsError} />
            )
          ) : (
            <ErrorMessage mess={error} />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
