import React, { useEffect, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaRegFile } from "react-icons/fa";
import { useSelector } from "react-redux";
import { uploadFileRequest } from "../actions/fileUpload";
import uploaderStyle from "../style/fileUploaderStyle.module.css";
import { SimpleBlueButton, XDeleteButton } from "./editComponents";
import { ErrorMessage, Loader, TextErrorMessage } from "./viewComponents";

const S3_HOST_URL = process.env.REACT_APP_S3_HOST_URL;

export default function UploadAttachmentsField({
  initialImages,
  onFilesUpload,
  onSave,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [dragActive, setDragActive] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(initialImages);
  const [fileError, setFileError] = useState("");
  const [saveCount, setSaveCount] = useState(1);

  useEffect(() => {
    const validFiles = uploadedFiles.filter((file) => file.uri && !file.error);
    onFilesUpload(validFiles);
  }, [uploadedFiles, saveCount]);

  const isValidFile = (file) => {
    const validTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const videoTypes = [
      "video/mp4",
      "video/mpeg",
      "video/quicktime",
      "video/x-msvideo",
    ];
    return validTypes.includes(file.type) && !videoTypes.includes(file.type);
  };

  

  const dropHandler = (ev) => {
    ev.preventDefault();
    setFileError("");
    setDragActive(false);

    const files = [...(ev.dataTransfer?.files || ev.target?.files || [])];
    const uniqueFiles = filterNewFiles(files.filter(isValidFile));

    if (uniqueFiles.length === 0) {
      setFileError("Only PDF, images, Word documents, and CSV files are accepted.");
      return;
    }
    const INVALID_FILENAME_REGEX = /[<>:"/\\|?*\0]/;

    let find_invalid_name = uniqueFiles.find((ele) => INVALID_FILENAME_REGEX.test(ele.name));
    if(find_invalid_name){
      setFileError(`Filenames including any of the characters <, >, :, ", /, \, |, ?, *, \0 are not allowed`);
      return;
    }

    const duplicates = uniqueFiles.filter((file) =>
      uploadedFiles.some(
          (uploadedFile) =>
              uploadedFile.fileName === file.name
      )
    );

    if (duplicates.length > 0) {
        setFileError(`Duplicate files detected. Files with the same name are not allowed.`);
        return;
    }
    addFiles(uniqueFiles);
    uploadFiles(uniqueFiles);
  };

  const filterNewFiles = (newFiles) => {
    const uploadedFilesCopy = uploadedFiles.filter((file) => file.uri);
    return newFiles.filter(
      (file) =>
        !uploadedFilesCopy.some(
          (uploaded) =>
            uploaded.fileName === file.name && uploaded.size === file.size
        )
    );
  };

  const addFiles = (files) => {
    const newFiles = files.map((file) => ({
      fileName: file.name,
      uri: "",
      isLoading: true,
      error: "",
    }));
    setUploadedFiles((prevFiles) => [
      ...prevFiles.filter((file) => file.uri),
      ...newFiles,
    ]);
  };

  const dragOverHandler = (ev) => {
    ev.preventDefault();
    setDragActive(true);
    setFileError("");
  };

  const deleteFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const updateFileStatus = (fileName, uri, error) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.fileName === fileName
          ? { ...file, uri, isLoading: false, error }
          : file
      )
    );
  };

  const uploadFiles = async (files) => {
    for (let file of files) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("folderName", "attachments");

      try {
        const responseData = await uploadFileRequest(formData, config);
        if (responseData.data.success) {
          updateFileStatus(file.name, responseData.data.data.url, "");
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        updateFileStatus(file.name, "", error);
      }
    }
  };

  const saveFiles = () => {
    setSaveCount(saveCount + 1);
    onSave();
  };

  return (
    <div className="w-full">
      {fileError && (
        <div className="my-2">
          <TextErrorMessage mess={fileError} />
        </div>
      )}
      <div
        className="w-full mt-4"
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onDragLeave={() => setDragActive(false)}
      >
        <div
          className={`w-full h-[160px] text-center relative border-2 rounded border-dashed ${
            dragActive ? "border-brandColor" : "border-[#C1D6FF]"
          }`}
        >
          <input
            type="file"
            id="input-file-upload"
            className="hidden"
            accept="image/*,application/pdf,.csv,.doc,.docx"
            multiple
            onChange={dropHandler}
          />
          <label
            htmlFor="input-file-upload"
            className="flex w-full items-center justify-center h-full hover:cursor-pointer"
          >
            <div className={uploaderStyle.uploadTips}>
              <AiOutlineCloudUpload className={uploaderStyle.uploadImg} />
              <div>
                Drag and drop, or{" "}
                <span className={uploaderStyle.browse}>browse</span> your files
              </div>
            </div>
          </label>
        </div>
      </div>
      <p className="mt-2 text-gray">*9 MB max size per file</p>
      <p className="mt-2 text-gray">{`*Filenames including any of the characters <, >, :, ", /, \, |, ?, *, \0 are not allowed`}</p>
      

      {uploadedFiles.length > 0 ? (
        <div>
          {uploadedFiles.map((file, index) => (
            <div
              key={index}
              className="relative min-w-0 border border-borderGray"
            >
              {file.isLoading ? (
                <div className={uploaderStyle.fileRow}>
                  <Loader mess={"Uploading..."} />
                </div>
              ) : file.uri ? (
                <div className={uploaderStyle.fileRow}>
                  <FaRegFile className={uploaderStyle.csvIcon} />
                  <a
                    href={`${S3_HOST_URL}${file.uri}`}
                    download={file.fileName}
                    target="_blank"
                    rel="noreferrer"
                    className={uploaderStyle.fileName}
                  >
                    <p>{file.fileName}</p>
                  </a>
                  <XDeleteButton action={() => deleteFile(index)} />
                </div>
              ) : (
                file.error && <div className="mt-4"> <ErrorMessage mess={file.error} /></div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      <div className="mt-6">
        <SimpleBlueButton name={"Save"} action={saveFiles} />
      </div>
    </div>
  );
}
