import axios from "axios";
import {serviceMiddleware} from "./serviceMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;



 export const createCategoryRequest = async (values, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/product/category/create`, values, config);
    serviceMiddleware(response);
    return response;
  } catch (error) {
    throw error;
  }

};

export const updateCategoryRequest = async (cateId, values, config) => {

  try {
    const response = await axios.put(SERVER_URL + `/product/category/${cateId}`, values, config);
    return response;
  } catch (error) {
    throw error;
  }
};






