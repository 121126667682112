import React, { useEffect, useState } from "react";
import { BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { createCustomerOptionsRequest, newCustomerRequest } from "../../actions/customerActions";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
} from "../../components/viewComponents";
import CustomerDetailForm from "./CustomerDetailForm";
import viewStyle from "../../style/styledViewComponentsStyle.module.css";


export default function NewCustomerModal({title,
  isModalOpen,
  closeModal,
  onCreateSuccess,}) {
  const dispatch = useDispatch();

  const { loading, options, error, success } = useSelector(
    (state) => state.createCustomerOptions
  );

  useEffect(() => {
    dispatch(createCustomerOptionsRequest());
  }, [dispatch]);

  const {
    loading: newLoading,
    success: newSuccess,
    customerId: newCustomerId,
    error: newError,
  } = useSelector((state) => state.newCustomer);

  const [newCustomerName, setNewCustomerName] = useState("");
  const [dialog, setDialog] = useState(false);


  const addNewCustomerRequest = (values, differentBillingName) => {
    let customerValues = {
      name: values.name,
      billingName: differentBillingName ? values.billingName : values.name,
      country: typeof values.country === "object" ? values.country.value : "",
      currency:
        typeof values.currency === "object" ? values.currency.value : "",
      taxRateId: values.taxRateId,
      locationId: values.locationId,
      priceTierId: values.priceTierId,
      paymentTerm: values.paymentTerm,
      postalAddress: values.postalAddress,
      billingAddress: values.billingAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      notes: values.notes,
    };
    setDialog(true);
    setNewCustomerName(values.name);
    dispatch(newCustomerRequest(customerValues));
  };

  const closeDialogWithSuccess = () => {
    let newCustomer = {
      id: newCustomerId,
      label: newCustomerName,
      value: newCustomerName
    }
    setDialog(false);
    onCreateSuccess(newCustomer);
  };

  return (
    <div>
      <ReactModal
        isOpen={isModalOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.fullHeightContentModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.modalContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeModal}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>

          <div className={viewStyle.modalContent_full}>
            <SimpleDialog
              title="Create New Customer"
              successMessage="New customer created successfully."
              isDialogOpen={dialog}
              closeDialog={() => setDialog(false)}
              loading={newLoading}
              loadingMessage="Processing request..."
              success={newSuccess}
              error={newError}
              confirmAction={closeDialogWithSuccess}
            />

            {loading ? (
              <Loader mess="Requesting data, please wait a moment..." />
            ) : success ? (
              <div className="w-full p-8">
                <CustomerDetailForm
                  type="new"
                  customerId={""}
                  onSubmit={addNewCustomerRequest}
                  options={options}
                  btnName="Create New Customer"
                />
              </div>
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
