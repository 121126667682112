import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonStyle from "../style/commonStyle.module.css";
import {
  BlueButton,
  LoadingButton,
  SimpleBlueButton,
  SingleRatioButton,
  XDeleteButton,
} from "./editComponents";
import {
  PriceText,
  VariantTag,
  VariantSkuTag,
  ContentModal,
  ErrorText,
  FullHeightContentModal,
} from "./viewComponents";
import CSVReader from "react-csv-reader";
import {
  checkProductByVariantSkuRequest,
  createNewProductApiRequest,
  findUnitRequest,
  searchProductByVariantSkuRequest,
} from "../api/productServices";
import { MdClear, MdFileUpload } from "react-icons/md";
import { NoBorderInput, StyledSelect } from "./inputFields";
import { validatePrice, validateQuantity } from "../functions/validate";
import { productOptionsRequest } from "../actions/productActions";

export default function BulkImportOrderProducts({ completeImport }) {
  const [modal, setModal] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setModal(true);
  };

  const handleSubmit = (values) => {
    setModal(false);
    completeImport(values);
  };
  return (
    <div>
      <ContentModal
        title="Import Products"
        isModalOpen={modal}
        closeOnOverlayClick={false}
        closeModal={() => setModal(false)}
        content={<BulkImportOrderProductsForm handleSubmit={handleSubmit} />}
      />

      <button
        onClick={handleClick}
        className="mt-1 text-brandColor rounded hover:bg-brandColor hover:text-white p-1 flex flex-row items-center"
      >
        {" "}
        <MdFileUpload className="w-4 h-4 mr-2" />{" "}
        <span>Import Products by CSV</span>{" "}
      </button>
    </div>
  );
}

function BulkImportOrderProductsForm({ handleSubmit }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };
  //////////////////////////////////////////////////////////////////////////////
  const [newProductsModal, setNewProductsModal] = useState(false);
  const [file, setFile] = useState("");

  const [products, setProducts] = useState([]);
  const [fileProducts, setFileProducts] = useState([]);
  const [fileNewProducts, setFileNewProducts] = useState([]);

  const [headerError, setHeaderError] = useState("");
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const deleteProductItem = (index) => {
    let productsCopy = [...products];
    productsCopy.splice(index, 1);
    setProducts(productsCopy);
  };

  ///////////////////////////////////////////////////////////////
  const requiredHeaders = [
    "code",
    "productname",
    "unit",
    "unitcost",
    "quantity",
  ];

  const handleForce = (data, fileInfo) => {
    const fileHeaders = data[0] ? Object.keys(data[0]) : [];

    if (fileHeaders.length) {
      const isValid = requiredHeaders.every((header) =>
        fileHeaders.includes(header)
      );
      // Function to check if every value in the object is null
      if (!isValid) {
        setHeaderError(
          "Uploaded CSV file header does not match the required headers, please use the provided file template."
        );
        return;
      } else {
        setHeaderError("");
      }
    }

    const isEveryValueNull = (obj) => {
      return Object.values(obj).every((value) => value === null);
    };
    // Use filter to remove objects where every value is null
    const filteredArray = data.filter((obj) => !isEveryValueNull(obj));
    let file_products = [];
    filteredArray.map((x) => {
      if (x.productname && x.code && x.quantity && x.unitcost) {
        if (Number(x.quantity) > 0 && Number(x.unitcost) >= 0) {
          file_products.push({
            productname: x.productname,
            code: x.code,
            unitName: x.unit,
            quantity: Number(x.quantity),
            unitcost: Number(x.unitcost),
          });
        }
      }
    });
    setFileProducts(file_products);
    setFile(fileInfo);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const extractProducts = async () => {
    const products_copy = [...fileProducts];
    let new_org_products = [];
    let exist_products = [];

    setRequestStatus((preState) => ({
      ...preState,
      loading: true,
    }));

    for (const p of products_copy) {
      let pro_details = "";
      try {
        const responseData = await checkProductByVariantSkuRequest(
          p.code,
          config
        );
        if (responseData.data.success) {
          let exist = responseData.data.data;
          if (exist) {
            p.newCreated = false;
            const proDetailsResponseData =
              await searchProductByVariantSkuRequest(p.code, config);
            if (proDetailsResponseData.data.success) {
              pro_details = proDetailsResponseData.data.data;
              p.details = pro_details;
              exist_products.push(p);
            } else {
              throw proDetailsResponseData.data.message.toString();
            }
          } else {
            p.barcode = "";
            p.serialized = false;
            p.batchTracked = false;
            p.salePrice = 0;
            p.latestCost = p.unitcost;
            p.reorderLevel = 0;
            p.category = "";
            p.copyNameToDescription = false;
            p.supplier = "";
            new_org_products.push(p);
          }
        } else {
          throw responseData.data.message.toString();
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
        return;
      }
    }

    if (exist_products.length) completeProducts(exist_products);
    if (new_org_products.length) {
      setFileNewProducts(new_org_products);
      setNewProductsModal(true);
    }
    setRequestStatus((preState) => ({
      ...preState,
      loading: false,
      success: true,
      error: "",
    }));
  };

  const completeProducts = (comingProducts) => {
    let new_products = [...products];
    comingProducts.map((x) => {
      let pro = x.details;
      // check if product exist
      let exists = new_products.some(
        (item) =>
          item.familyId === pro.familyId && item.variantCode === pro.variantCode
      );

      if (exists) {
        let index = new_products.findIndex(
          (item) =>
            item.familyId === pro.familyId &&
            item.variantCode === pro.variantCode
        );
        new_products[index].quantity += Number(x.quantity);
        new_products[index].totalCost =
          new_products[index].quantity * Number(new_products[index].unitCost);
      } else {
        new_products.push({
          familyId: pro.familyId,
          productType: pro.productType,
          productName: pro.name,
          newCreated: x.newCreated,
          sku: pro.sku,
          barcode: pro.barcode,
          variantCode: pro.variantCode,
          variantSku: pro.variantSku,
          variant: pro.variant,
          unitLatestCost: pro.latestCost,
          quantity: Number(x.quantity),
          unitName: pro.unit,
          unitCost: Number(x.unitcost),
          totalCost: Number(x.quantity) * Number(x.unitcost),
        });
      }
      setProducts(new_products);
    });
  };

  const completeAction = () => {
    handleSubmit(products);
  };

  const removefile = (e) => {
    e.preventDefault();
    setFile("");
    setProducts([]);
    setFileProducts([]);
    setRequestStatus({
      loading: false,
      success: false,
      error: "",
    });
  };

  const handleNewProductsSubmit = (newProducts) => {
    setNewProductsModal(false);
    let new_valeus = [];
    newProducts.map((item) => {
      new_valeus.push({
        code: item.code,
        details: item.details,
        newCreated: item.newCreated,
        productname: item.productname,
        quantity: item.quantity,
        unitName: item.unitName,
        unitcost: item.unitcost,
      });
    });
    completeProducts(new_valeus);
  };

  return (
    <div className="w-full mt-4">
      <FullHeightContentModal
        title="New Products"
        isModalOpen={newProductsModal}
        closeOnOverlayClick={false}
        closeModal={() => setNewProductsModal(false)}
        content={
          <FindNewProductsForm
            findProducts={fileNewProducts}
            handleSubmit={handleNewProductsSubmit}
          />
        }
      />

      {file.name ? (
        <div className="w-full mt-2">
          <div className="p-4 w-full border border-borderGray border-solid flex flex-row items-center">
            <p>
              Selected file: <span className="underline">{file.name}</span>{" "}
            </p>
            <button
              onClick={removefile}
              className="text-red-500 p-1 rounded-full hover:bg-red-500 hover:text-white ml-2"
            >
              <MdClear className="w-5 h-5" />
            </button>
          </div>
          {requestStatus.error ? (
            <ErrorText mess={requestStatus.error} />
          ) : (
            <></>
          )}

          {!requestStatus.success ? (
            requestStatus.loading ? (
              <div className="w-fit mt-4">
                <p>
                  It will take 1-3 minutes to process the data. If the system
                  cannot find a matching code for an existing product, a new
                  product will be created.
                </p>
                <LoadingButton name={"Extracting products.."} />
              </div>
            ) : (
              <div className="w-fit mt-4">
                <BlueButton
                  name={"Extract Products From File"}
                  action={extractProducts}
                />
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div>
          <div className="mt-2">
            <span>
              Please download the CSV template and fill in the required fields.
            </span>
            <a
              href={`https://d1o1skvu5gf7wj.cloudfront.net/rui/import-products-to-purchase-order-template.csv`}
              download={"import_purchase_order_products_template"}
              target="_blank"
              rel="noreferrer"
              className="text-brandColor underline"
            >
              Import purchase order products template
            </a>
          </div>

          <CSVReader
            cssClass="mt-4 p-4 border border-borderGray border-solid"
            cssInputClass=""
            cssLabelClass="mr-2"
            label="Select CSV file"
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
          />
        </div>
      )}

      {headerError ? <ErrorText mess={headerError} /> : <></>}

      {requestStatus.success ? (
        products.length > 0 ? (
          <div className="mt-4">
            <p>
              Found <span className="text-brandColor"> {products.length}</span>{" "}
              valid products
            </p>
            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>Barcode</th>
                    <th>Unit</th>
                    <th>Unit Cost</th>
                    <th>Quantity</th>
                    <th>Total Cost</th>
                    <th
                      style={{
                        margin: "auto",
                        borderLeft: "1px solid var(--border-color)",
                      }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((pro, index) => (
                    <tr key={index}>
                      <td>
                        {pro.variantSku && (
                          <VariantSkuTag name={pro.variantSku} />
                        )}
                      </td>
                      <td>
                        <p>
                          {pro.newCreated ? (
                            <span className="text-xs text-green-500 mr-1">
                              New
                            </span>
                          ) : (
                            <></>
                          )}
                          {pro.productName}{" "}
                          {pro.variant ? (
                            <VariantTag name={pro.variant} />
                          ) : (
                            <></>
                          )}
                        </p>
                      </td>
                      <td>{pro.barcode}</td>
                      <td>{pro.unitName}</td>
                      <td>
                        <PriceText num={pro.unitCost} />
                      </td>
                      <td>{pro.quantity}</td>
                      <td>
                        <PriceText num={pro.totalCost} />
                      </td>

                      <td
                        style={{
                          borderLeft: "1px solid var(--border-color)",
                          width: "4%",
                        }}
                      >
                        <XDeleteButton
                          action={() => deleteProductItem(index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <p className="text-red-500">No valid products found</p>
          </div>
        )
      ) : (
        <></>
      )}

      {products.length > 0 ? (
        <div className="w-full mt-4">
          <SimpleBlueButton
            name="Save Products to Order"
            action={completeAction}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function FindNewProductsForm({ findProducts, handleSubmit }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };
  const { loading, options, error, success } = useSelector(
    (state) => state.productOptions
  );

  const [newProducts, setNewProducts] = useState(findProducts);

  const [newProductStatus, setNewProductStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    dispatch(productOptionsRequest());
  }, [dispatch]);

  const deleteProductItem = (index) => {
    let productsCopy = [...newProducts];
    productsCopy.splice(index, 1);
    setNewProducts(productsCopy);
  };

  ///////////////////////////////////////////////////////////////

  const handleSerializedChange = (e) => {
    const { name, value, index } = e.target;
    let newState = [...newProducts];

    if (value) {
      if (name === "serialized") {
        newState[index]["batchTracked"] = !value;
        newState[index]["serialized"] = value;
      }

      if (name === "batchTracked") {
        newState[index]["batchTracked"] = value;
        newState[index]["serialized"] = !value;
      }
    } else {
      newState[index][name] = value;
    }
    setNewProducts(newState);
  };

  const handleVariantsChange = (e) => {
    const { type, name, value, index } = e.target;
    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }
    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    let newState = [...newProducts];
    newState[index][name] = value;
    setNewProducts(newState);
  };



  const saveNewProducts = async () => {
    try {
      setNewProductStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      let copy = [...newProducts];
      for (const p of copy) {
        let unit_id = "";
        // find unit id
        if (p.unitName) {
          const unitResponseData = await findUnitRequest(p.unitName, config);
          if (unitResponseData.data.success) {
            unit_id = unitResponseData.data.data;
          } else {
            throw unitResponseData.data.message.toString();
          }
        }

        // create new product

        let newValues = {
          draft: false,
          type: "product",
          productName: p.productname,
          categoryId: p.category ? p.category.id : "",
          description: p.copyNameToDescription ? p.productname : "",
          image: "",
          supplierId: p.supplier ? p.supplier.id : "",
          unitId: unit_id,
          managed: false,
          serialized: p.serialized,
          batchTracked: p.batchTracked,
          sku: p.code,
          barcode: p.barcode,
          salePrice: Number(p.salePrice),
          latestCost: Number(p.latestCost),
          reorderLevel: Number(p.reorderLevel),
        };
        let new_product_added = false;
        const response_create = await createNewProductApiRequest(
          newValues,
          config
        );
        if (response_create.data.success) {
          p.newCreated = true;
          new_product_added = true;
        } else {
          throw response_create.data.message;
        }

        if (new_product_added) {
          const proDetailsResponseData = await searchProductByVariantSkuRequest(
            p.code,
            config
          );
          if (proDetailsResponseData.data.success) {
            p.details = proDetailsResponseData.data.data;
          } else {
            throw proDetailsResponseData.data.message.toString();
          }
        }
      }
      handleSubmit(copy);

      setNewProductStatus((preState) => ({
        ...preState,
        loading: false,
        success: true,
        error: "",
      }));
    } catch (error) {
      setNewProductStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };


  return (
    <div className="w-full mt-4">
      {newProducts.length > 0 ? (
        <div className="mt-4">
          <p>
            Found <span className="text-brandColor"> {newProducts.length}</span>{" "}
            new products
          </p>
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th className="min-w-[80px]">Code</th>
                  <th className="min-w-[200px]">Product Name</th>
                  <th>Category</th>
                  <th>Unit</th>
                  <th>Supplier</th>
                  <th>Serialized/Batch-tracked</th>
                  <th>Barcode</th>
                  <th>Re-order Level</th>
                  <th>Sale Price</th>
                  <th>Unit Cost</th>
                  <th
                    style={{
                      margin: "auto",
                      borderLeft: "1px solid var(--border-color)",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {newProducts.map((pro, index) => (
                  <tr key={index}>
                    <td className="min-w-[80px]">
                      {pro.code && <VariantSkuTag name={pro.code} />}
                    </td>
                    <td className="min-w-[200px]">
                      <p>{pro.productname}</p>
                      <SingleRatioButton
                        label="Copy name as product description"
                        name="copyNameToDescription"
                        isOn={pro.copyNameToDescription}
                        onChange={(copyNameToDescription) =>
                          handleVariantsChange({
                            target: {
                              value: copyNameToDescription,
                              name: "copyNameToDescription",
                              index: index,
                            },
                          })
                        }
                      />

                    </td>
                    <td className="min-w-[160px] pb-2">
                      <StyledSelect
                        placeHolder="Select..."
                        selectOptions={success ? options.category : []}
                        value={pro.category}
                        onChange={(category) =>
                          handleVariantsChange({
                            target: {
                              type: "text",
                              value: category,
                              name: "category",
                              index: index,
                            },
                          })
                        }
                      />
                    </td>
                    <td className="min-w-[64px]">{pro.unitName}</td>
                    <td className="min-w-[160px] pb-2">
                      <StyledSelect
                        placeHolder="Select..."
                        selectOptions={success ? options.suppliers : []}
                        value={pro.supplier}
                        onChange={(supplier) =>
                          handleVariantsChange({
                            target: {
                              type: "text",
                              value: supplier,
                              name: "supplier",
                              index: index,
                            },
                          })
                        }
                      />
                    </td>
                    <td className="min-w-[180px]">
                      <SingleRatioButton
                        label="Serialized"
                        name="serialized"
                        isOn={pro.serialized}
                        onChange={(serialized) =>
                          handleSerializedChange({
                            target: {
                              value: serialized,
                              name: "serialized",
                              index: index,
                            },
                          })
                        }
                      />
                      <SingleRatioButton
                        label="Batch Tracked"
                        name="batchTracked"
                        isOn={pro.batchTracked}
                        onChange={(batchTracked) =>
                          handleSerializedChange({
                            target: {
                              value: batchTracked,
                              name: "batchTracked",
                              index: index,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <NoBorderInput
                        type="text"
                        name="salePrice"
                        value={pro.barcode}
                        onChange={(barcode) =>
                          handleVariantsChange({
                            target: {
                              type: "text",
                              value: barcode,
                              name: "barcode",
                              index: index,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <NoBorderInput
                        type="text"
                        name="reorderLevel"
                        value={pro.reorderLevel}
                        onChange={(reorderLevel) =>
                          handleVariantsChange({
                            target: {
                              type: "quantity",
                              value: reorderLevel,
                              name: "reorderLevel",
                              index: index,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <NoBorderInput
                        type="text"
                        name="salePrice"
                        value={pro.salePrice}
                        onChange={(salePrice) =>
                          handleVariantsChange({
                            target: {
                              type: "price",
                              value: salePrice,
                              name: "salePrice",
                              index: index,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <NoBorderInput
                        type="text"
                        name="latestCost"
                        value={pro.latestCost}
                        onChange={(latestCost) =>
                          handleVariantsChange({
                            target: {
                              type: "price",
                              value: latestCost,
                              name: "latestCost",
                              index: index,
                            },
                          })
                        }
                      />
                    </td>

                    <td
                      style={{
                        borderLeft: "1px solid var(--border-color)",
                        width: "4%",
                      }}
                    >
                      <XDeleteButton action={() => deleteProductItem(index)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="mt-4">
          <p className="text-red-500">No new products found</p>
        </div>
      )}
      {newProductStatus.error && (
        <p className="mt-4 text-red-500">{newProductStatus.error} </p>
      )}

      {newProducts.length > 0 ? (
        <div className="w-full mt-4">
          {newProductStatus.loading ? (
            <LoadingButton name="Saving products..." />
          ) : (
            <SimpleBlueButton name="Save Products" action={saveNewProducts} />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
