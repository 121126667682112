import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import {
  EditButtonLink,
  ScreenTitle,
  SimpleBlueButton,
} from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ErrorMessage,
  Loader,
  ViewText,
  ViewDateWithTitle,
  SubTitle,
  PriceText,
  VariantTag,
  VariantSkuTag,
  ViewLabel,
  Modal,
  SimpleDialog,
} from "../../../components/viewComponents";
import {
  CreateableSelectField,
  NoBorderInput,
  ReadOnlyField,
  StyledInput,
  StyledSelect,
} from "../../../components/inputFields";
import { stockAdjustDetailsRequest } from "../../../actions/productActions";
import useDocumentTitle from "../../../useDocumentTitle";
import { convtPrice, convtQuantity } from "../../../functions/functions";
import ProjectForm from "../../settings/ProjectForm";
import {
  stockAdjustOptionsRequest,
  updateStockAdjustmentRequest,
} from "../../../api/productServices";

export default function EditStockAdjustmentScreen() {
  useDocumentTitle("Edit Stock Adjustment");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: adjustid } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, adjustDetails, error, success } = useSelector(
    (state) => state.stockAdjustDetails
  );

  const [options, setOptions] = useState();
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [adjustReason, setAdjustReason] = useState();
  const [adjustNotes, setAdjustNotes] = useState("");
  const [adjustProject, setAdjustProject] = useState();
  const [newProjectModal, setNewProjectModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // mange dialog content, new and edit
  const [dialog, setDialog] = useState(false);

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(stockAdjustDetailsRequest(adjustid));
  }, [dispatch, adjustid]);

  useEffect(() => {
    const getAdjustOptions = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await stockAdjustOptionsRequest(config);
        if (responseData.data.success) {
          setOptions(responseData.data.data);
          let project_list = responseData.data.data.projects;
          let reason_list = responseData.data.data.reasons;
          if (success) {
            let find_project = project_list.find(
              (x) => x.id === adjustDetails.basicInfo.projectId
            );
            if (find_project) {
              setAdjustProject(find_project);
            }
            let find_reason = reason_list.find(
              (x) => x.id === adjustDetails.basicInfo.reasonId
            );
            if (find_reason) {
              setAdjustReason(find_reason);
            }
            setAdjustNotes(adjustDetails.basicInfo.notes);
          }
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getAdjustOptions();
  }, [success]);

  const addNewProjectSuccess = (newPro) => {
    let optionCopy = { ...options };
    let projectCopy = optionCopy.projects;
    projectCopy.unshift(newPro);
    setOptions(optionCopy);
    setAdjustProject(newPro);
    setNewProjectModal(false);
  };

  const updateStockAdjustment = async () => {
    setDialog(true);

    let updateValues = {
      projectId: adjustProject ? adjustProject.id : "",
      reasonId: adjustReason ? adjustReason.id : "",
      notes: adjustNotes,
    };

    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await updateStockAdjustmentRequest(
        adjustid,
        updateValues,
        config
      );
      if (responseData.data.success) {
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    navigate(`/inventory/bulk-stock-adjustment/preview/${adjustid}`, {
      replace: true,
      from: location,
    });
    setDialog(false);
  };

  /////////////////////////////////////////////////////////////////////////////

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Project"
        isModalOpen={newProjectModal}
        closeModal={() => setNewProjectModal(false)}
        content={
          <ProjectForm
            projectId=""
            type="new"
            btnName="Add New Project"
            values={{ name: "", code: "" }}
            onSuccess={addNewProjectSuccess}
          />
        }
      />

      <SimpleDialog
        title="Edit Stock Adjustment"
        successMessage="Stock adjustment order updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="Edit Stock Adjustment" />
      <ScreenTitle title="Edit Stock Adjustment" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting details, please wait a moment..." />
        ) : success ? (
          <div>
            {/* adjust order infomation */}
            {requestStatus.loading ? (
              <Loader mess="Requesting locations..." />
            ) : requestStatus.success ? (
              <div className="w-full shadow-cardShadow p-4">
                <SubTitle name="Order Basic Information" />
                <div className="w-full grid grid-cols-3 gap-4">
                  <ReadOnlyField
                    label={"Location*"}
                    value={adjustDetails.basicInfo.location}
                  />
                  <ReadOnlyField
                    label={"Adjust Type*"}
                    value={
                      adjustDetails.basicInfo.type === "RECEIVESTOCK"
                        ? "Receive Stock"
                        : "Deduct Stock"
                    }
                  />

                  <StyledSelect
                    label="Adjust Reason"
                    selectOptions={
                      adjustDetails.basicInfo.type
                        ? options.reasons.filter(
                            (x) => x.type === adjustDetails.basicInfo.type
                          )
                        : options.reasons
                    }
                    value={adjustReason}
                    placeHolder="Select adjust reason"
                    clearable={false}
                    onChange={(adjustReason) => setAdjustReason(adjustReason)}
                    error={formErrors.adjustReason}
                  />
                </div>
                {options.projects && options.projects.length > 0 ? (
                  <>
                    <CreateableSelectField
                      label="Project"
                      extractKey="id"
                      options={options.projects}
                      name={"year"}
                      value={adjustProject}
                      onChange={(project) => setAdjustProject(project)}
                      placeholder="Select project"
                      autoFocus={false}
                      readOnly={false}
                      clearable={true}
                      noResultText="No options"
                      addBtnName="Add a new project"
                      openAddNewModal={() => setNewProjectModal(true)}
                      error={formErrors.project}
                    />
                  </>
                ) : (
                  <></>
                )}
                <div style={{ width: "100%" }}>
                  <StyledInput
                    label="Notes"
                    type="text"
                    name="notes"
                    value={adjustNotes}
                    onChange={(notes) => setAdjustNotes(notes)}
                  />
                </div>
                <div className="w-full mt-4">
                  <SimpleBlueButton
                    action={updateStockAdjustment}
                    name="Update"
                  />
                </div>
              </div>
            ) : requestStatus.error ? (
              <ErrorMessage mess={requestStatus.error} />
            ) : (
              <></>
            )}

            {/* invoice section */}
            <div className="mt-8 w-full shadow-cardShadow p-4">
              <SubTitle name="Adjust Products" />

              <div className={commonStyle.longDataTable}>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Product Name</th>
                      <th>Unit</th>
                      <th>Adjust Cost</th>
                      <th>Quantity</th>
                      <th>Adjust Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adjustDetails.orderItems.map((item, index1) => {
                      return (
                        <tr key={index1}>
                          <td>
                            {item.variantSku && (
                              <VariantSkuTag name={item.variantSku} />
                            )}
                          </td>
                          <td>{item.productName}</td>
                          <td>{item.unitName}</td>
                          <td>
                            <PriceText num={convtPrice(item.unitCost)} />
                          </td>
                          <td>{convtQuantity(item.quantity)}</td>

                          <td>
                            <PriceText num={convtPrice(item.totalCost)} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
