import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OrderAttachments } from "../../../components/attachmentComponents";
import {
  SimpleBlueButton
} from "../../../components/editComponents";
import {
  CreateableSelectField,
  ReadOnlyField,
  StyledInput,
  StyledSelect,
  StyledTextarea
} from "../../../components/inputFields";
import {
  ContactCard,
  PriceText,
  SubTitle,
  VariantSkuTag
} from "../../../components/viewComponents";
import { convtPrice, convtQuantity } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import NewCustomerModal from "../../customers/NewCustomerModal";
import purchaseStyle from "../../purchase/purchaseStyle.module.css";
import salesStyle from "../salesStyle.module.css";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function SaleOrderFormBasicInfo({
  initialOrderInfo,
  initialOrderItems,
  initialOrderSummary,
  initialCosts,
  initialFiles,
  onSubmit,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };

  // product values

  const { options, success } = useSelector((state) => state.saleOrderOptions);

  /////////////////////////////initial states//////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const [customersOptions, setCustomerOptions] = useState([]);
  const [orderInfo, setOrderInfo] = useState(initialOrderInfo);
  const [orderItems, setOrderItems] = useState(initialOrderItems);

  const [addtionalCosts, setAddtionalCosts] = useState(initialCosts);
  const [orderFiles, setOrderFiles] = useState(initialFiles);
  // store the order items total cost and extra cost total. the tax amount and order amount calculate when display
  const [orderSummary, setOrderSummary] = useState(initialOrderSummary);


  //define the error message
  const [formErrors, setFormErrors] = useState({});
  // scan dialog
  const [newCustomerModal, setNewCustomerModal] = useState(false);

  useEffect(() => {
    if (Object.keys(options).length) {
      let customer_options_copy = [...options.customerOptions];
      setCustomerOptions(customer_options_copy);
    } 
  }, [success]);

  /////////////////////////////Handle change functions////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderInfo({ ...orderInfo, [name]: value });
  };
  const handleCustomerChange = (e) => {
    const { value } = e.target;

    if (value) {
      getCustomerDetails(value)
    } else {
      setOrderInfo({
        ...orderInfo,
        customer: "",
      });
    }
  };
  const getCustomerDetails = (value) => {
    try {
      axios
        .get(SERVER_URL + `/customers/${value.id}`, config)
        .then(async (response) => {
          if (response.data.success) {
            let customerDetails = response.data.data;

            setOrderInfo({
              ...orderInfo,
              customer: value,
              customerName: customerDetails.name,
              customerBillingName: customerDetails.billingName,
              firstName: customerDetails.firstName,
              lastName: customerDetails.lastName,
              email: customerDetails.email,
              phone: customerDetails.phone,
              postalAddress: customerDetails.postalAddress,
              billingAddress: customerDetails.billingAddress,
              paymentTerm: customerDetails.paymentTerm,
            });
          } else {
            setFormErrors({ customer: "Get customer details failed!" });
          }
        })
        .catch((err) => {
          setFormErrors({ customer: "Get customer details failed!" });
        });
    } catch (error) {
      setFormErrors({ customer: "Get customer details failed!" });
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////Submit and check values/////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const checkValidation = () => {
    let sumitValues = {
      orderInfo: orderInfo,
      files: orderFiles,
    };

    const hasErrors = validate(sumitValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(sumitValues);
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.orderInfo.orderNumber) {
      errors.orderNumber = "order number is required!";
    }
    if (!values.orderInfo.customer) {
      errors.customer = "Customer is required!";
    }
   
    if (values.orderInfo.paymentTerm === "") {
      errors.paymentTerm = "Payment term is required!";
    }

    return errors;
  };



  const getAttachments = (files) => {
    let new_files = [];
    files.forEach((x) => {
      new_files.push({
        fileName: x.fileName,
        uri: x.uri,
      });
    });

    setOrderFiles(new_files);
  };

  const getNewCustomerSuccess = (newCustomer) => {
    let optionCopy = [...customersOptions];
    optionCopy.unshift(newCustomer);
    setCustomerOptions(optionCopy);
    getCustomerDetails(newCustomer)
    setNewCustomerModal(false);
  }


  return (
    <div>
      <NewCustomerModal
        title="Create New Customer"
        isModalOpen={newCustomerModal}
        closeModal={() => setNewCustomerModal(false)}
        onCreateSuccess={getNewCustomerSuccess}
      />

      <div className={salesStyle.sectionCard}>
        <div className={salesStyle.locationCon}>
          <ReadOnlyField label={"Sale Location*"} value={orderInfo.location ? orderInfo.location.value : ""} />
          <StyledInput
            label="Order Number"
            type="text"
            name="orderNumber"
            value={orderInfo.orderNumber}
            onChange={(orderNumber) =>
              handleChange({
                target: { value: orderNumber, name: "orderNumber" },
              })
            }
            error={formErrors.orderNumber}
          />
          {orderInfo.suInvoice && (
            <StyledInput
              label="Invoice Number"
              type="text"
              name="invNumber"
              value={orderInfo.invNumber}
              onChange={(invNumber) =>
                handleChange({
                  target: { value: invNumber, name: "invNumber" },
                })
              }
              error={formErrors.invNumber}
            />
          )}
        </div>
      </div>

      <div className={salesStyle.sectionCard}>
        <SubTitle name="Customer Information" />
        <div className={purchaseStyle.orderInfoCon}>
          <div className={purchaseStyle.supplierCon}>
            <div>
              <CreateableSelectField
                  label="Customer*"
                  options={customersOptions}
                  name={"customer"}
                  value={orderInfo.customer}
                  onChange={(customer) =>
                    handleCustomerChange({
                      target: { value: customer },
                    })
                  }
                  placeholder="Select customer"
                  autoFocus={false}
                  readOnly={false}
                  clearable={true}
                  noResultText="No options"
                  addBtnName="Add a new customer"
                  openAddNewModal={() => setNewCustomerModal(true)}
                  error={formErrors.customer}
                />

            </div>

            {orderInfo.customer ? (
               <div className="bg-lightbg p-4">
               <ContactCard 
                  contactName = {orderInfo.customer.value}
                  billingName= {orderInfo.customerBillingName}
                  firstName = {orderInfo.firstName}
                  lastName = {orderInfo.lastName}
                  phone = {orderInfo.phone}
                  email = {orderInfo.email}
                  postalAddress = {orderInfo.postalAddress}
                  billingAddress = {orderInfo.billingAddress}
                  contactLink={`/customer/preview/${orderInfo.customer.id}`}
                />
              </div>

           
            ) : (
              <></>
            )}
            <div className="mt-4">
            {
              orderInfo.isNeedShipping ? <p>This order need shipping</p> : <p>This order does not need shipping</p> 
            }
            </div>
       
          </div>

          <div className={purchaseStyle.shippingCon}>
            <ReadOnlyField label={"Price Tier"} value={orderInfo.priceTier ? orderInfo.priceTier.value : ""} />
            <ReadOnlyField label={"Tax Rate*"} value={orderInfo.taxRate ? orderInfo.taxRate.value : ""} />
            <ReadOnlyField label={"Currency*"} value={orderInfo.currency ? orderInfo.currency : ""} />
          
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "6px" }}>
                <StyledInput
                  label="Payment Term*"
                  type="text"
                  name="paymentTerm"
                  value={orderInfo.paymentTerm}
                  onChange={(paymentTerm) =>
                    handleChange({
                      target: { value: paymentTerm, name: "paymentTerm" },
                    })
                  }
                  error={formErrors.paymentTerm}
                />
              </div>
              <span style={{ marginTop: "26px" }}>
                {" "}
                Days after invoice date
              </span>
            </div>
            {options.projectsOptions.length > 0 ? (
              <StyledSelect
                label="Project"
                placeHolder="Select project"
                value={orderInfo.project}
                selectOptions={options.projectsOptions}
                onChange={(project) =>
                  handleChange({
                    target: { value: project, name: "project" },
                  })
                }
              />
            ) : (
              <></>
            )}

            <StyledTextarea
              label="Notes"
              type="text"
              name="notes"
              value={orderInfo.notes}
              onChange={(notes) =>
                handleChange({
                  target: { value: notes, name: "notes" },
                })
              }
              error={formErrors.notes}
            />
          </div>
        </div>
      </div>

      {/************ order items ***********************/}
      <div className={salesStyle.sectionCard}>
        <div className={purchaseStyle.orderItemsCon}>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SubTitle name="Order Products" />
            <p>
              {orderInfo.taxIncluded
                ? "*Amounts are tax inclusive"
                : "*Amounts are tax exclusive"}
            </p>
          </div>

          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Unit</th>
                  <th>Unit Price</th>
                  <th>Discount</th>
                  <th>Net Price</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map((item, index1) => {
                  return (
                    <tr key={index1}>
                      <td>
                        {item.variantSku && (
                          <VariantSkuTag name={item.variantSku} />
                        )}
                      </td>

                      {item.type !== "package" ? (
                        <td className={purchaseStyle.productNameCol}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {item.type === "packageProduct" ? (
                              <div className={salesStyle.packageProductTag}>
                                Package
                              </div>
                            ) : (
                              <></>
                            )}
                            <div>
                              <div>
                                {item.productName}
                              </div>
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td className={salesStyle.productPackageNameCol}>
                          <div className={salesStyle.productPackageName}>
                            {item.packageName}
                          </div>
                        </td>
                      )}
                      <td>{item.unitName}</td>

                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <div>
                           {item.unitPrice}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <p>{item.discount}</p>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {item.type !== "packageProduct" ? (
                          <div>
                            {item.discount ? (
                              <PriceText
                                num={convtPrice(
                                  (1 - Number(item.discount) / 100) *
                                    Number(item.unitPrice)
                                )}
                              />
                            ) : (
                              <PriceText num={item.unitPrice} />
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.quantity}
                      </td>

                      <td
                        style={{
                          width: "10%",
                          maxWidth: "15%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <PriceText num={item.totalPrice} />
                        ) : (
                          <></>
                        )}
                      </td>

                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" className={commonStyle.tableFootName}>
                    Products Total
                  </td>

                  <td>
                    <PriceText num={orderSummary.productsTotal} />
                  </td>
                </tr>
              </tfoot>
            </table>
            <div className="w-full mt-8">
              <div className={purchaseStyle.summaryWrapper}>
                <div className={purchaseStyle.costCon}>
                  {/* additional cost */}
                  <SubTitle name="Additional Charge" />
                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Price</th>
                      </tr>
                    </thead>

                    <tbody>
                      {addtionalCosts.map((c, index2) => {
                        return (
                          <tr key={index2}>
                            <td
                              className={purchaseStyle.productNameCol}
                              style={{
                                width: "60%",
                              }}
                            >
                              {c.costName}
                            </td>

                            <td
                              style={{
                                width: "36%",
                              }}
                            >
                              {c.cost}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className={commonStyle.tableFootName}>Total</td>
                        <td>
                          <PriceText num={orderSummary.additionalCostTotal} />
                        </td>
                      </tr>
                    </tfoot>
                  </table>

                  <div className="mt-4">
                    <OrderAttachments
                      onUploadSuccess={getAttachments}
                      initialFiles={orderFiles}
                    />
                  </div>
                </div>

                <div className={purchaseStyle.summaryCon}>
                  {/* summary total price */}
                  <SubTitle name="Order Summary" />
                  <table
                    className={commonStyle.summaryTable}
                    style={{ marginTop: "12px" }}
                  >
                    {orderInfo.taxIncluded ? (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.productsTotal} />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Charge Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.additionalCostTotal} />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            {orderSummary.productsTotal ? (
                              <PriceText
                                num={
                                  Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)
                                }
                              />
                            ) : (
                              "0.00"
                            )}{" "}
                            {orderInfo.currency}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) /
                                    (1 + Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal) -
                                    (Number(orderSummary.productsTotal) +
                                      Number(
                                        orderSummary.additionalCostTotal
                                      )) /
                                      (1 + Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.productsTotal} />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Charge Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText num={orderSummary.additionalCostTotal} />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={
                                  Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)
                                }
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            {orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) *
                                    (Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            {orderSummary.productsTotal && orderInfo.taxRate ? (
                              <PriceText
                                num={convtPrice(
                                  Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal) +
                                    (Number(orderSummary.productsTotal) +
                                      Number(
                                        orderSummary.additionalCostTotal
                                      )) *
                                      (Number(orderInfo.taxRate.rate) / 100)
                                )}
                              />
                            ) : (
                              "0.00"
                            )}{" "}
                            {orderInfo.currency}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mb-20 py-4">

      <SimpleBlueButton
          name="Update"
          action={checkValidation}
        />
      </div>
    </div>
  );
}
