
export const SALE_ORDER_OPTIONS_REQUEST = "SALE_ORDER_OPTIONS_REQUEST";
export const SALE_ORDER_OPTIONS_SUCCESS = "SALE_ORDER_OPTIONS_SUCCESS";
export const SALE_ORDER_OPTIONS_FAIL = "SALE_ORDER_OPTIONS_FAIL";

export const SALE_ORDER_LIST_REQUEST = "SALE_ORDER_LIST_REQUEST";
export const SALE_ORDER_LIST_SUCCESS = "SALE_ORDER_LIST_SUCCESS";
export const SALE_ORDER_LIST_FAIL = "SALE_ORDER_LIST_FAIL";

export const SALE_ORDER_DETAILS_REQUEST = "SALE_ORDER_DETAILS_REQUEST";
export const SALE_ORDER_DETAILS_SUCCESS = "SALE_ORDER_DETAILS_SUCCESS";
export const SALE_ORDER_DETAILS_FAIL = "SALE_ORDER_DETAILS_FAIL";

export const SALE_ORDER_ACTIONS_REQUEST = "SALE_ORDER_ACTIONS_REQUEST";
export const SALE_ORDER_ACTIONS_SUCCESS = "SALE_ORDER_ACTIONS_SUCCESS";
export const SALE_ORDER_ACTIONS_FAIL = "SALE_ORDER_ACTIONS_FAIL";

export const SALE_ORDER_CREATE_REQUEST = "SALE_ORDER_CREATE_REQUEST";
export const SALE_ORDER_CREATE_SUCCESS = "SALE_ORDER_CREATE_SUCCESS";
export const SALE_ORDER_CREATE_FAIL = "SALE_ORDER_CREATE_FAIL";

export const SALE_ORDER_EDIT_REQUEST = "SALE_ORDER_EDIT_REQUEST";
export const SALE_ORDER_EDIT_SUCCESS = "SALE_ORDER_EDIT_SUCCESS";
export const SALE_ORDER_EDIT_FAIL = "SALE_ORDER_EDIT_FAIL";

export const SHIP_SALE_ORDER_REQUEST = "SHIP_SALE_ORDER_REQUEST";
export const SHIP_SALE_ORDER_SUCCESS = "SHIP_SALE_ORDER_SUCCESS";
export const SHIP_SALE_ORDER_FAIL = "SHIP_SALE_ORDER_FAIL";

export const SALE_ORDER_EDIT_AFTER_APPROVE_REQUEST = "SALE_ORDER_EDIT_AFTER_APPROVE_REQUEST";
export const SALE_ORDER_EDIT_AFTER_APPROVE_SUCCESS = "SALE_ORDER_EDIT_AFTER_APPROVE_SUCCESS";
export const SALE_ORDER_EDIT_AFTER_APPROVE_FAIL = "SALE_ORDER_EDIT_AFTER_APPROVE_FAIL";

