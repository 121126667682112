import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import { ScreenTitle } from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { ErrorMessage, Loader } from "../../../components/viewComponents";
import { SimpleDialog } from "../../../components/viewComponents";
import {
  purchaseOrderOptionsRequest,
  editPurchaseOrderRequest,
  purchaseOrderDetailsRequest,
} from "../../../actions/purchaseOrderActions";
import PurchaseOrderForm from "./PurchaseOrderForm";
import useDocumentTitle from "../../../useDocumentTitle";
import { convtPrice } from "../../../functions/functions";

export default function EditPurchaseOrderScreen() {
  useDocumentTitle("Edit Purchase Order");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id: poid } = useParams();

  useEffect(() => {
    dispatch(purchaseOrderOptionsRequest());
    if (poid) {
      dispatch(purchaseOrderDetailsRequest(poid, "edit"));
    }
  }, [dispatch, poid]);

  const { loading, purchaseOrderOptions, error, success } = useSelector(
    (state) => state.purchaseOrderOptions
  );

  const {
    loading: detailsLoading,
    orderInfo,
    error: detailsError,
    success: detailsSuccess,
  } = useSelector((state) => state.purchaseOrderDetails);

  const {
    loading: editLoading,
    error: editError,
    success: editSuccess,
  } = useSelector((state) => state.editPurchaseOrder);

  const orderBasicInfo = () => {
    let data = {
      id: orderInfo.basicInfo.id,
      orderNumber: orderInfo.basicInfo.orderNumber,
      supplier: purchaseOrderOptions.supplierOptions.filter(
        (x) => x.value === orderInfo.basicInfo.supplier
      )[0],
      taxRate: purchaseOrderOptions.taxrateOptions.filter(
        (x) => x.rate === orderInfo.basicInfo.taxrate
      )[0],
      project: purchaseOrderOptions.projectsOptions.filter(
        (x) => x.id === orderInfo.basicInfo.project_id
      ).length ? purchaseOrderOptions.projectsOptions.filter(
        (x) => x.id === orderInfo.basicInfo.project_id
      )[0] : "",
      currency: orderInfo.basicInfo.currency,
      supplierName: orderInfo.basicInfo.supplier
        ? orderInfo.basicInfo.supplier
        : "",
      supplierBillingName: orderInfo.basicInfo.supplierBillingName
      ? orderInfo.basicInfo.supplierBillingName
      : "",
      firstName: orderInfo.basicInfo.firstName
        ? orderInfo.basicInfo.firstName
        : "",
      lastName: orderInfo.basicInfo.lastName
        ? orderInfo.basicInfo.lastName
        : "",
      email: orderInfo.basicInfo.email ? orderInfo.basicInfo.email : "",
      phone: orderInfo.basicInfo.phone ? orderInfo.basicInfo.phone : "",
      postalAddress: orderInfo.basicInfo.postalAddress ?  orderInfo.basicInfo.postalAddress : {},
      billingAddress: orderInfo.basicInfo.billingAddress ?  orderInfo.basicInfo.billingAddress : {},
      taxIncluded: orderInfo.basicInfo.taxIncluded,
      expectedDate: orderInfo.basicInfo.expectedDate
        ? new Date(orderInfo.basicInfo.expectedDate * 1000)
        : "",
      deliveryAddress: orderInfo.basicInfo.deliveryAddress
        ? orderInfo.basicInfo.deliveryAddress
        : "",
      location: purchaseOrderOptions.locationOptions.filter(
        (x) => x.value === orderInfo.basicInfo.location
      )[0],
      notes: orderInfo.basicInfo.notes ? orderInfo.basicInfo.notes : "",
      status: orderInfo.basicInfo.status,
    };
    return data;
  };

  const orderFiles = () => {
    let copy = [...orderInfo.orderFiles];
    let data = [];
    copy.forEach((x)=>{
      data.push({
        fileName: x.fileName,
        uri: x.uri,
        loading: false,
        error: ""
      })
    })
    return data;
  };

  // edit dialog
  const [editDialog, setEditDialog] = useState(false);

  // submit
  const editPurchaseOrder = (values, status) => {
    setEditDialog(true);
    let isApproved = false;
    if (status === "Approved") {
      isApproved = true;
    }

    let orderItemValues = [];

    values.orderItems.forEach((prod) => {
      let costExclTax;
      if (values.orderInfo.taxIncluded) {
        costExclTax = convtPrice(
          prod.unitCost /
          (1 + values.orderInfo.taxRate.rate / 100)
        );
      } else {
        costExclTax = prod.unitCost;
      }
      let eachItem = {
        famid: prod.famid,
        variantCode: prod.variantCode,
        variantSku: prod.variantSku,
        quantity: prod.quantity,
        cost: prod.unitCost,
        costExclTax: costExclTax,
        discount: prod.discount,
        totalCost: prod.totalCost,
      };

      orderItemValues.push(eachItem);
    });

    let orderTotalExcltax;
    let orderTotalIncltax;
    let orderTax;

    if (values.orderInfo.taxIncluded) {
      orderTotalIncltax =
        Number(values.orderSummary.productsTotal) +
        Number(values.orderSummary.additionalCostTotal);

      orderTotalExcltax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) /
          (1 + values.orderInfo.taxRate.rate / 100)
      );

      orderTax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) -
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) /
            (1 + values.orderInfo.taxRate.rate / 100)
      );
    } else {
      orderTotalExcltax =
        Number(values.orderSummary.productsTotal) +
        Number(values.orderSummary.additionalCostTotal);

      orderTax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) *
          (values.orderInfo.taxRate.rate / 100)
      );

      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) +
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) *
            (values.orderInfo.taxRate.rate / 100)
      );
    }

    values.costs.forEach((c) => {
      let addCostExclTax;
      if (values.orderInfo.taxIncluded) {
        addCostExclTax = convtPrice(
          c.cost /
          (1 + values.orderInfo.taxRate.rate / 100)
        );
      } else {
        addCostExclTax = c.cost;
      }
      c["costExclTax"] = addCostExclTax;
    });

    if (typeof values.orderInfo.postalAddress === "object") {
      values.orderInfo.postalAddress = JSON.stringify(
        values.orderInfo.postalAddress
      );
    }
    if (typeof values.orderInfo.billingAddress === "object") {
      values.orderInfo.billingAddress = JSON.stringify(
        values.orderInfo.billingAddress
      );
    }
    let purchaseOrderValues = {
      approved: isApproved,
      status: status,
      orderInfo: {
        orderNumber: values.orderInfo.orderNumber,
        supplierId: values.orderInfo.supplier.id,
        taxrateId: values.orderInfo.taxRate.id,
        taxrate: values.orderInfo.taxRate.rate,
        project: values.orderInfo.project ? values.orderInfo.project.id : "",
        taxIncluded: values.orderInfo.taxIncluded,
        currency: values.orderInfo.currency,
        supplierName: values.orderInfo.supplierName,
        supplierBillingName: values.orderInfo.supplierBillingName,
        firstName: values.orderInfo.firstName,
        lastName: values.orderInfo.lastName,
        email: values.orderInfo.email,
        phone: values.orderInfo.phone,
        postalAddress: values.orderInfo.postalAddress,
        billingAddress: values.orderInfo.billingAddress,
        locationId: values.orderInfo.location.id,
        deliveryAddress: values.orderInfo.deliveryAddress,
        expectedDate: values.orderInfo.expectedDate
          ? Math.floor(values.orderInfo.expectedDate.getTime() / 1000)
          : "",
        notes: values.orderInfo.notes,
        // calcute order total amount
        orderTotalExcltax: orderTotalExcltax,
        orderTotalIncltax: orderTotalIncltax,
        orderTax: orderTax,
      },
      orderItems: orderItemValues,
      costs: values.costs,
      files: values.files,
    };

    dispatch(editPurchaseOrderRequest(purchaseOrderValues, poid));
  };

  const closeCreateDialogWithSuccess = () => {
    navigate(`/purchase/order/preview/${poid}`, {
      replace: true,
    });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit Purchase Order"
        successMessage="Purchase order updated successfully."
        isDialogOpen={editDialog}
        closeDialog={() => setEditDialog(false)}
        loading={editLoading}
        loadingMessage="Processing request..."
        success={editSuccess}
        error={editError}
        confirmAction={closeCreateDialogWithSuccess}
      />

      <Breadcrumbs screenName="Edit Purchase Order" />
      <ScreenTitle title="Edit Purchase Order" status="Edit" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting data, please wait a moment..." />
        ) : success ? (
          detailsLoading ? (
            <Loader mess="Requesting purchase order details, please wait a moment..." />
          ) : detailsSuccess ? (
            <PurchaseOrderForm
              initialOrderInfo={() => orderBasicInfo()}
              initialOrderItems={orderInfo.orderItems}
              initialCosts={orderInfo.orderCosts}
              initialFiles={orderFiles}
              initialOrderSummary={orderInfo.orderSummary}
              submit={editPurchaseOrder}
              type="edit"
              showDraftBtn={orderInfo.basicInfo.status === "Draft" ? true : false}
            />
          ) : (
            <ErrorMessage mess={detailsError} />
          )
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
