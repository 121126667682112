import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  deleteCategoryRequest,
  productCategoryListRequest,
} from "../../actions/productActions";
import {
  ScreenTitle,
  AddButton,
  EditButton,
  DeleteButton,
} from "../../components/editComponents";
import {
  Modal,
  ErrorMessage,
  Loader,
  SimpleDialog,
  DeleteDialog,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import CategoryForm from "./CategoryForm";
import useDocumentTitle from "../../useDocumentTitle";
import Pagination from "../../components/Pagination";

export default function ProductCategoriesScreen() {
  useDocumentTitle("Product category")
  const dispatch = useDispatch();
  // access check for the actions

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = useSelector((state) => state.deleteCategory);

  //define the add new category input values
  const initialValues = {
    cateName: "",
  };

  // get data from store by using useSelector method
  const { loading, list, error, success } = useSelector(
    (state) => state.categoryList
  );

  // new and edit Modal
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  // mange dialog content, new and edit
  const [newDialog, setNewDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [updateListCount, setUpdateListCount] = useState(0);

  const [editedCategory, setEditCategory] = useState("");
  // delete
  
  const [deleteId, setDeleteId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    dispatch(productCategoryListRequest(pageNum, pageSize));
  }, [dispatch, pageNum, updateListCount, deleteSuccess]);


  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const openEditModal = (item) => {
    setEditCategory(item);
    setEditModal(true);
  };

  const addNewCategory = () => {
    setNewModal(false);
    setNewDialog(true);
    setUpdateListCount((preState) => preState + 1);
  };

  const editCategory = () => {
    setEditModal(false);
    setUpdateDialog(true);
    setEditCategory()
    setUpdateListCount((preState) => preState + 1);
  };

  const deleteAction = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = () => {
    dispatch(deleteCategoryRequest(deleteId));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Product Category"
        isModalOpen={newModal}
        closeModal={() => setNewModal(false)}
        content={
          <CategoryForm
            cateId={""}
            type={"new"}
            values={initialValues}
            onSuccess={addNewCategory}
            btnName="Add New Category"
          />
        }
      />

      <Modal
        title="Edit Product Category"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <CategoryForm
            cateId={editedCategory ? editedCategory.id : ""}
            type={"edit"}
            values={editedCategory}
            onSuccess={editCategory}
            btnName="Update Category"
          />
        }
      />

      <SimpleDialog
        title="Add New Product Category"
        successMessage="New product category added successfully."
        isDialogOpen={newDialog}
        closeDialog={() => setNewDialog(false)}
        loading={false}
        loadingMessage="Processing request..."
        success={true}
        error={""}
        btnValue="Confirm"
        confirmAction={() => setNewDialog(false)}
      />

      <SimpleDialog
        title="Edit Product Category"
        successMessage="Product category updated successfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={false}
        loadingMessage="Processing request..."
        success={true}
        error={""}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <DeleteDialog
        title="Delete Product Category"
        preDeleteMess="Deleting this category can not be undone. All products associated with this category will have their category field changed to empty. Are you sure you want to proceed with deleting this category?"
        successMessage="Product category deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={() => setDeleteDialog(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      <Breadcrumbs screenName="Product Categories" />
      <ScreenTitle title="Product Categories" />

      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting category list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.shortDataTable}>
            {userDetailsSuccess ? (
              userDetails.data.permissions.includes(
                "add_delete_edit_product_categories"
              ) ? (
                <AddButton
                name="New Product Category"
                action={() => setNewModal(true)}
              />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
           

            {/* hold the table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  {/* <th></th> */}
                  <th>Category Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.categories && list.categories.length > 0 ? (
                  list.categories.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name} </td>
                      <td>
                        {/* check if has access for "new_product" */}
                        {userDetailsSuccess ? (
                          userDetails.data.permissions.includes(
                            "add_delete_edit_product_categories"
                          ) ? (
                            <div className={commonStyle.actionsGrop}>
                              <EditButton action={() => openEditModal(item)} />
                              <DeleteButton
                                action={() => deleteAction(item.id)}
                              />
                            </div>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td> There are no product category available. Please add a new product category now.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {success && list.categories.length > 0 ? (
              <Pagination
                totalPageNum={list.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
