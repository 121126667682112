import React, { useState } from "react";
import {
  SingleRatioButton,
  SimpleBlueButton,
  AddLinkOutlineButton,
} from "../../components/editComponents";
import {
  StyledSelect,
  StyledInput,
  StyledEmailInput,
  StyledTextarea,
} from "../../components/inputFields";
import { SubTitle } from "../../components/viewComponents";
import { K_COUNTRIES } from "../../constantsData/countries";

export default function SupplierDetailForm({
  btnName,
  values,
  onSubmit,
  options,
}) {
  const [sameBilling, setSameBilling] = useState(false);

  const [supplierValues, setSupplierValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});

  // handle change when input
  const handleBillingAddress = (value) => {
    setSameBilling(value);
    if (value) {
      setSupplierValues({
        ...supplierValues,
        billingAddress: supplierValues.postalAddress,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupplierValues({ ...supplierValues, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { type, name, value } = e.target;
    if (type === "postal") {
      let postalAddressCopy = { ...supplierValues.postalAddress };
      postalAddressCopy[name] = value;
      setSupplierValues({
        ...supplierValues,
        postalAddress: postalAddressCopy,
      });
    }
    if (type === "billing") {
      let billingAddressCopy = { ...supplierValues.billingAddress };
      billingAddressCopy[name] = value;
      setSupplierValues({
        ...supplierValues,
        billingAddress: billingAddressCopy,
      });
    }
  };

  // handle email input field change
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const errors = {};
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (re.test(value)) {
      // this is a valid email address
      setFormErrors({});
      setSupplierValues({ ...supplierValues, [name]: value });
    } else {
      // invalid email, maybe show an error to the user.
      setSupplierValues({ ...supplierValues, [name]: value });
      errors.email = "Please enter a valid email address";
      setFormErrors(errors);
    }
  };

  const checkValidation = () => {
    const hasErrors = validate(supplierValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(supplierValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Supplier company name is required!";
    }

    if (!values.taxRateId) {
      errors.taxRate = "Supplier tax rate is required!";
    }

    if (!values.country) {
      errors.country = "Supplier country is required!";
    }
    if (!values.currency) {
      errors.currency = "Supplier currency is required!";
    }

    return errors;
  };

  return (
    <div>
      <div>
        <SubTitle name="Supplier Information" />

        <div className="grid grid-cols-2 gap-8">
          <div>
            <StyledInput
              label="Company Name*"
              type="text"
              name="name"
              autofocus="autofocus"
              value={supplierValues.name}
              onChange={(name) =>
                handleChange({
                  target: { value: name, name: "name" },
                })
              }
              error={formErrors.name}
            />
            <div style={{ display: "flex" }}>
              <StyledInput
                label="Contact First Name"
                type="text"
                name="firstName"
                value={supplierValues.firstName}
                onChange={(firstName) =>
                  handleChange({
                    target: { value: firstName, name: "firstName" },
                  })
                }
                error={formErrors.firstName}
              />
              <div style={{ marginLeft: "10px", width: "50%" }}>
                <StyledInput
                  label="Contact Last Name"
                  type="text"
                  name="lastName"
                  value={supplierValues.lastName}
                  onChange={(lastName) =>
                    handleChange({
                      target: { value: lastName, name: "lastName" },
                    })
                  }
                  error={formErrors.lastName}
                />
              </div>
            </div>

            <StyledEmailInput
              label="Contact Email"
              type="email"
              name="email"
              value={supplierValues.email}
              onChange={(email) =>
                handleEmailChange({
                  target: { value: email, name: "email" },
                })
              }
              error={formErrors.email}
            />
            <StyledInput
              label="Contact Phone"
              type="text"
              name="phone"
              value={supplierValues.phone}
              onChange={(phone) =>
                handleChange({
                  target: { value: phone, name: "phone" },
                })
              }
              error={formErrors.userPhone}
            />
          </div>
          <div>
            <StyledSelect
              label="Country*"
              placeHolder="Select country"
              selectOptions={K_COUNTRIES}
              value={supplierValues.country}
              onChange={(country) =>
                handleChange({
                  target: { value: country, name: "country" },
                })
              }
              error={formErrors.country}
            />
            <StyledSelect
              label="Currency*"
              placeHolder="Select supplier currency"
              selectOptions={options.currencies}
              value={supplierValues.currency}
              onChange={(currency) =>
                handleChange({
                  target: { value: currency, name: "currency" },
                })
              }
              error={formErrors.currency}
            />
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <StyledSelect
                label="Tax Rate*"
                placeHolder="Select tax rate"
                selectOptions={options.salesTaxRates}
                value={supplierValues.taxRateId}
                extractKey="id"
                onChange={(taxRate) =>
                  handleChange({
                    target: { value: taxRate, name: "taxRateId" },
                  })
                }
                error={formErrors.taxRate}
              />
              <AddLinkOutlineButton path="/settings/tax-rates" />
            </div>
          </div>
        </div>

        <div className="mt-8 grid grid-cols-2 gap-8">
          <div>
            <SubTitle name="Postal Address" />
            <StyledSelect
              label="Country"
              placeHolder="Select country"
              selectOptions={K_COUNTRIES}
              value={supplierValues.postalAddress.country}
              onChange={(country) =>
                handleAddressChange({
                  target: { type: "postal", value: country, name: "country" },
                })
              }
            />
            <StyledInput
              label="Region"
              type="text"
              name="region"
              value={supplierValues.postalAddress.region}
              onChange={(region) =>
                handleAddressChange({
                  target: { type: "postal", value: region, name: "region" },
                })
              }
            />
            <StyledInput
              label="City"
              type="text"
              name="city"
              value={supplierValues.postalAddress.city}
              onChange={(city) =>
                handleAddressChange({
                  target: { type: "postal", value: city, name: "city" },
                })
              }
            />

            <StyledInput
              label="Street Address"
              type="text"
              name="addressLine"
              value={supplierValues.postalAddress.addressLine}
              onChange={(addressLine) =>
                handleAddressChange({
                  target: {
                    type: "postal",
                    value: addressLine,
                    name: "addressLine",
                  },
                })
              }
            />
            <StyledInput
              label="Postal Code"
              type="text"
              name="postalCode"
              value={supplierValues.postalAddress.postalCode}
              onChange={(postalCode) =>
                handleAddressChange({
                  target: {
                    type: "postal",
                    value: postalCode,
                    name: "postalCode",
                  },
                })
              }
            />
          </div>
          <div>
            <SubTitle name="Billing Address" />
            <StyledSelect
              label="Country"
              placeHolder="Select country"
              selectOptions={K_COUNTRIES}
              value={supplierValues.billingAddress.country}
              onChange={(country) =>
                handleAddressChange({
                  target: { type: "billing", value: country, name: "country" },
                })
              }
            />
            <StyledInput
              label="Region"
              type="text"
              name="region"
              value={supplierValues.billingAddress.region}
              onChange={(region) =>
                handleAddressChange({
                  target: { type: "billing", value: region, name: "region" },
                })
              }
            />
            <StyledInput
              label="City"
              type="text"
              name="city"
              value={supplierValues.billingAddress.city}
              onChange={(city) =>
                handleAddressChange({
                  target: { type: "billing", value: city, name: "city" },
                })
              }
            />

            <StyledInput
              label="Street Address"
              type="text"
              name="addressLine"
              value={supplierValues.billingAddress.addressLine}
              onChange={(addressLine) =>
                handleAddressChange({
                  target: {
                    type: "billing",
                    value: addressLine,
                    name: "addressLine",
                  },
                })
              }
            />
            <StyledInput
              label="Postal Code"
              type="text"
              name="postalCode"
              value={supplierValues.billingAddress.postalCode}
              onChange={(postalCode) =>
                handleAddressChange({
                  target: {
                    type: "billing",
                    value: postalCode,
                    name: "postalCode",
                  },
                })
              }
            />
          </div>
        </div>

        <div style={{ marginTop: "10px" }}>
          <SingleRatioButton
            label="Same billing address "
            name="sameBilling"
            isOn={sameBilling}
            onChange={(sameBilling) => handleBillingAddress(sameBilling)}
          />
        </div>

        <StyledTextarea
          label="Notes"
          type="text"
          name="notes"
          value={supplierValues.notes}
          onChange={(notes) =>
            handleChange({
              target: { value: notes, name: "notes" },
            })
          }
          error={formErrors.notes}
        />
        <div className="w-full flex items-center justify-center mt-6">
          <SimpleBlueButton name={btnName} action={checkValidation} />
        </div>
      </div>
    </div>
  );
}
