import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  LoadingButton,
  SimpleBlueButton,
} from "../../components/editComponents";
import {
  StyledInput,
} from "../../components/inputFields";
import { createUnitRequest, updateUnitRequest } from "../../api/unitServices";
import { ErrorMessage } from "../../components/viewComponents";

export default function UnitForm({unitId, type,  btnName, values, onSuccess}) {

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [formValues, setFormValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [updateStatus, setUpdateStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const checkValidation = (e) => {
    const hasErrors = validate(formValues);
    if (Object.keys(hasErrors).length === 0) {
      if(type === "new"){
        addNewUnit();
      }else{
        editUnit();
      }
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };
  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // check input validation
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Unit of measure name is required!";
    }
    return errors;
  };

  const addNewUnit = async () => {
    let newValues = {
      unitName: formValues.name,
    };

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await createUnitRequest(newValues, config);

      if (responseData.data.success) {
        let newUnit = {
          id: responseData.data.data,
          label: formValues.name,
          value: formValues.name,
        };
        onSuccess(newUnit);
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const editUnit = async () => {
    let newValues = {
      unitName: formValues.name,
    };
    try {
      setUpdateStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await updateUnitRequest(
        unitId,
        newValues,
        config
      );
      if (responseData.data.success) {
        onSuccess()
        setUpdateStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setUpdateStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  return (
    <div>
      <StyledInput
        label="Unit of Measure Name*"
        type="text"
        name="name"
        autofocus="autofocus"
        value={formValues.name}
        onChange={(name) =>
          handleChange({
            target: { value: name, name: "name" },
          })
        }
        error={formErrors.name}
      />
      {requestStatus.error ? (
        <div className="w-full mt-6">
          <ErrorMessage mess={requestStatus.error} />
        </div>
      ) : updateStatus.error ? (
        <div className="w-full mt-6">
          <ErrorMessage mess={updateStatus.error} />
        </div>
      ) : (
        <></>
      )}

      <div className="w-full mt-6">
        {requestStatus.loading ? (
          <LoadingButton name={"Creating unit of measure..."} />
        ) : updateStatus.loading ? (
          <LoadingButton name={"Updating unit of measure..."} />
        ) : (
          <SimpleBlueButton name={btnName} action={checkValidation} />
        )}
      </div>
    </div>
  );
}
