import axios from "axios";



const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// get package list
export const importXeroContactsRequest = async (type, config) => {

   try {
     const response = await axios.get(SERVER_URL + `/integration/xero/sync_xero_customers?contactType=${type}`, config)
     return response.data;
   } catch (error) {
     throw new Error(error.message);
   }
 
};

export const importXeroProductsRequest = async (config) => {

   try {
     const response = await axios.get(SERVER_URL + `/integration/xero/sync_xero_products`, config)
     return response.data;
   } catch (error) {
     throw new Error(error.message);
   }
 
};

export const createXeroImportTaskRequest = async (type, values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/integration/xero/data/import_task?type=${type}`, values, config)
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }

};





