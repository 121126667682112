import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaRegFile } from "react-icons/fa";
import UploadAttachmentsField from "./UploadAttachmentsField";
import { ContentModal, Modal, SubTitle } from "./viewComponents";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function OrderAttachments({ initialFiles, onUploadSuccess }) {
  const [modal, setModal] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setModal(true);
  };
  const onFilesUpload = (files) => {
    onUploadSuccess(files);
  };

  const saveFiles = () => {
    setModal(false);
  };

  return (
    <div>
      <ContentModal
        title="Attachments"
        isModalOpen={modal}
        closeOnOverlayClick={false}
        closeModal={() => setModal(false)}
        content={
          <UploadAttachmentsField
            initialImages={initialFiles}
            onFilesUpload={onFilesUpload}
            onSave={saveFiles}
          />
        }
      />

      <div className="">
        <SubTitle name={"Attachments"} />
        <button
          onClick={handleClick}
          className="mt-2 text-brandColor rounded hover:bg-brandColor hover:text-white p-1 flex flex-row items-center border border-borderGray px-3 py-3"
        >
          <div className="flex flex-row">
            <FaRegFile className="w-4 h-4 mr-2" />
            <span>Files</span>
            <span>({initialFiles.length})</span>
          </div>
        </button>
      </div>
    </div>
  );
}

function OrderAttachmentsPreview({ files, style = "details" }) {
  const [modal, setModal] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    if (files.length > 0) {
      setModal(true);
    }
  };

  return (
    <div>
      <Modal
        title="Attachments"
        isModalOpen={modal}
        closeModal={() => setModal(false)}
        content={<OrderAttachmentsPreviewForm files={files} />}
      />
      <div className="">
        {style === "details" ? <SubTitle name={"Attachments"} /> : <></>}
        <button
          onClick={handleClick}
          className={`${
            style === "list"
              ? "text-brandColor rounded hover:underline flex flex-row items-center"
              : "mt-2 text-brandColor rounded hover:bg-brandColor hover:text-white flex flex-row items-center border border-borderGray px-3 py-3"
          } `}
        >
          <div className="flex flex-row">
            <FaRegFile className="w-4 h-4 mr-1" />
            <span className={`${style === "list" ? "hidden" : "block"}`}>
              Files
            </span>
            <span>({files.length})</span>
          </div>
        </button>
      </div>
    </div>
  );
}

function OrderAttachmentsPreviewForm({ files }) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
    responseType: "blob",
  };

  const handleDownload = async (file) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/file/download?file_uri=${encodeURIComponent(file.uri)}`,
        config
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = file.fileName; // Use the file's name for download
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div>
      {files.length > 0 ? (
        <div>
          {files.map((file, index) => (
            <div
              key={index}
              className="relative min-w-0 border border-borderGray"
            >
              {file.uri ? (
                <div
                  className="flex flex-row items-center border border-dashed border-brandColor p-4 rounded text-brandColor hover:underline mt-4"
                  onClick={() => handleDownload(file)}
                >
                  <FaRegFile className="w-4 h-4 mr-1" />
                  <p className="text-brandColor cursor-pointer">
                    {file.fileName}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export { OrderAttachments, OrderAttachmentsPreview };
