import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// get package list

export const restartXeroTaskRequest = async (taskId, config) => {
   try {
     const response = await axios.get(SERVER_URL + `/integration/xero/restart_task/${taskId}`, config)
     return response;
   } catch (error) {
     throw error;
   }
 
};
