import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  deleteUnitRequest,
  productUnitListRequest,
} from "../../actions/productActions";
import {
  ScreenTitle,
  AddButton,
  EditButton,
  DeleteButton,
} from "../../components/editComponents";
import {
  Modal,
  ErrorMessage,
  Loader,
  SimpleDialog,
  DeleteDialog,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import UnitForm from "./UnitForm";
import Pagination from "../../components/Pagination";

export default function ProductUnitsScreen() {
  useDocumentTitle("Units of Measure");
  // access check for the actions
  const dispatch = useDispatch();
  // get data from store by using useSelector method
  const { loading, list, error, success } = useSelector(
    (state) => state.units
  );
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = useSelector((state) => state.deleteUnit);

  //define the add new category input values
  const initialValues = {
    name: "",
  };


  const [editedUnit, setEditUnit] = useState("");

  // new and edit Modal
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  // mange dialog content, new and edit
  const [newDialog, setNewDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [updateListCount, setUpdateListCount] = useState(0);

  // delete
  const [deleteId, setDeleteId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    dispatch(productUnitListRequest(pageNum, pageSize));
  }, [dispatch, pageNum, updateListCount, deleteSuccess]);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const openEditModal = (item) => {
    setEditUnit(item);
    setEditModal(true);
  };

  const addNewUnit = () => {
    setNewModal(false);
    setNewDialog(true);
    setUpdateListCount((preState) => preState + 1);
  };

  const editUnit = () => {
    setEditModal(false);
    setUpdateDialog(true);
    setEditUnit()
    setUpdateListCount((preState) => preState + 1);
  };

  const deleteAction = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = () => {
    dispatch(deleteUnitRequest(deleteId));
  };


  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New unit of measure"
        isModalOpen={newModal}
        closeModal={() => setNewModal(false)}
        content={
          <UnitForm
            unitId={""}
            type="new"
            values={initialValues}
            onSuccess={addNewUnit}
            btnName="Save"
          />
        }
      />
      <Modal
        title="Edit unit of measure"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <UnitForm 
          unitId={editedUnit ? editedUnit.id : ""}
          type="edit"
          values={editedUnit} 
          onSuccess={editUnit} 
          btnName="Update" />
        }
      />

      <SimpleDialog
        title="Add Unit of Measure"
        successMessage="New unit of measure added successfully."
        isDialogOpen={newDialog}
        closeDialog={() => setNewDialog(false)}
        loading={false}
        loadingMessage="Processing request..."
        success={true}
        error={""}
        btnValue="Confirm"
        confirmAction={() => setNewDialog(false)}
      />

      <SimpleDialog
        title="Edit Unit of Measure"
        successMessage="Unit of measure updated successfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={false}
        loadingMessage="Processing request..."
        success={true}
        error={""}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <DeleteDialog
        title="Delete Unit of Measure"
        preDeleteMess="Only un-used unit of measure can be deleted. Are you sure you want to proceed?"
        successMessage="Deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={() => setDeleteDialog(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      <Breadcrumbs screenName="Units of Measure" />
      <ScreenTitle title="Units of Measure" />

      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting units of measure, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.shortDataTable}>
            <AddButton
              name="New Unit of Measure"
              action={() => setNewModal(true)}
            />

            {/* hold the table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  {/* <th></th> */}
                  <th>Unit of Measure Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.units && list.units.length > 0 ? (
                  list.units.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name} </td>
                      <td>
                        <div className={commonStyle.actionsGrop}>
                          <EditButton action={() => openEditModal(item)} />
                          <DeleteButton action={() => deleteAction(item.id)} />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no unit of measure available. Please add one now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {success && list.units && list.units.length > 0 ? (
              <Pagination
                totalPageNum={list.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
