import React, { useEffect, useState } from "react";
import { BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  newSupplierRequest,
  createSupplierOptionsRequest,
} from "../../actions/supplierActions";
import SupplierDetailForm from "./SupplierDetailForm";
import {
  SimpleDialog,
  Loader,
  ErrorMessage,
} from "../../components/viewComponents";
import viewStyle from "../../style/styledViewComponentsStyle.module.css";
import { K_COUNTRIES } from "../../constantsData/countries";

export default function NewSupplierModal({
  title,
  isModalOpen,
  closeModal,
  onCreateSuccess,
}) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const { loading, options, error, success } = useSelector(
    (state) => state.createSupplierOptions
  );
  const {
    loading: newLoading,
    success: newSuccess,
    supplierId: newSupplierId,
    error: newError,
  } = useSelector((state) => state.newSupplier);
  //////////////////////////////////////////////////////////////////////////////

  // get
  const getInitialValues = () => {
    let initialValues = {
      name: "",
      country: userDetailsSuccess
        ? K_COUNTRIES.filter(
            (x) => x.value === userDetails.data.orgInfo.country
          )[0]
        : "",
      currency: options.currencies
        ? options.currencies.filter((x) => x.isBaseCurrency === 1)[0]
        : "",
      taxRateId: options.salesTaxRates
        ? options.salesTaxRates.filter((x) => x.isDefault === 1).length
          ? options.salesTaxRates.filter((x) => x.isDefault === 1)[0].id
          : ""
        : "",
      postalAddress: {
        country: "",
        city: "",
        region: "",
        addressLine: "",
        postalCode: "",
      },
      billingAddress: {
        country: "",
        city: "",
        region: "",
        addressLine: "",
        postalCode: "",
      },
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      notes: "",
    };
    return initialValues;
  };
  const [newSupplierName, setNewSupplierName] = useState("");
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    dispatch(createSupplierOptionsRequest());
  }, [dispatch]);

  const addNewSupplierRequest = (values) => {
    let supplierValues = {
      name: values.name,
      country: typeof values.country === "object" ? values.country.value : "",
      currency:
        typeof values.currency === "object" ? values.currency.value : "",
      taxRateId: values.taxRateId,
      postalAddress: JSON.stringify(values.postalAddress),
      billingAddress: JSON.stringify(values.billingAddress),
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      notes: values.notes,
    };
    setDialog(true);
    setNewSupplierName(values.name);
    dispatch(newSupplierRequest(supplierValues));
  };

  const closeDialogWithSuccess = () => {
    let newSupplier = {
      id: newSupplierId,
      label: newSupplierName,
      value: newSupplierName
    }
    setDialog(false);
    onCreateSuccess(newSupplier);
  };

  return (
    <div>
      <ReactModal
        isOpen={isModalOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.fullHeightContentModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.modalContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeModal}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>

          <div className={viewStyle.modalContent_full}>
            <SimpleDialog
              title="Create New Supplier"
              successMessage="New supplier added successfully."
              isDialogOpen={dialog}
              closeDialog={() => setDialog(false)}
              loading={newLoading}
              loadingMessage="Processing request..."
              success={newSuccess}
              error={newError}
              confirmAction={closeDialogWithSuccess}
            />
            {loading ? (
              <Loader mess="Requesting data..." />
            ) : success ? (
              <div className="w-full p-8">
                <SupplierDetailForm
                  values={getInitialValues}
                  onSubmit={addNewSupplierRequest}
                  options={options}
                  btnName="Create New Supplier"
                  title="Create New Supplier"
                />
              </div>
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
