import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  ScreenTitle,
  SimpleBlueButtonLink,
} from "../../components/editComponents";
import Breadcrumbs from "../../components/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import {
  SimpleBlueButton,
  LoadingButton,
} from "../../components/editComponents";
import {
  Loader,
  TextErrorMessage,
  ViewLabel,
} from "../../components/viewComponents";
import { BsArrowRight } from "react-icons/bs";
import integrationStyle from "./integrationStyle.module.css";
import XeroIntegrationTab from "./XeroIntegrationTab";
import { StyledSelect } from "../../components/inputFields";
import { useLocation, useNavigate } from "react-router-dom";
import DisconnectXero from "./DisconnectXero";
import { userDetailsRequest } from "../../actions/authAction";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function EditXeroConfigurationScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialData = location.state ? location.state.data : "";
  const tenantName = location.state ? location.state.tenantName : "";

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  const invoiceStatusOptions = [
    { id: 1, label: "DRAFT", value: "DRAFT" },
    { id: 2, label: "SUBMITTED", value: "SUBMITTED" },
    { id: 3, label: "AUTHORISED", value: "AUTHORISED" },
  ];

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [saveRequestStatus, setSaveRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [lostConenction, setLostConnection] = useState(false);

  const [xeroAccounts, setXeroAccounts] = useState();
  const [xeroTaxrates, setXeroTaxRates] = useState();
  const [xeroBrandingThemes, setXeroBrandingThemes] = useState();

  const [mappingValues, setMappingValues] = useState({
    salesProducts: "",
    salesFreight: "",
    stockOnHand: "",
    stockAdjustment: "",
    purchaseProducts: "",
    purchaseFreight: "",
    cogs: "",
  });

  const [statusValues, setStatusValues] = useState({
    saleInvoice: { id: 3, label: "AUTHORISED", value: "AUTHORISED" },
    saleJournal: { id: 3, label: "AUTHORISED", value: "AUTHORISED" },
    purchaseOrderBill: { id: 3, label: "AUTHORISED", value: "AUTHORISED" },
    stockAdjustJournal: { id: 3, label: "AUTHORISED", value: "AUTHORISED" },
  });

  const [revenueTaxs, setRevenueTaxs] = useState([]);
  const [expenseTaxs, setExpenseTaxs] = useState([]);
  const [brandingTheme, setBrandingTheme] = useState();

  const [formErrors, setFormErrors] = useState({});
  ////////////////////////////////////////////////////////////////////////////////////////
  const handleChange = (e) => {
    const { value, name } = e.target;
    setMappingValues({ ...mappingValues, [name]: value });
  };

  const handleRevenueTaxChange = (e) => {
    const { xeroRate, index } = e.target;
    let copy = [...revenueTaxs];
    copy[index] = { ...copy[index], xeroRate: xeroRate };
    //copy[type][index]["xeroRate"] = xeroRate;
    setRevenueTaxs(copy);
  };

  const handleExpenseTaxChange = (e) => {
    const { xeroRate, index } = e.target;
    let copy = [...expenseTaxs];
    copy[index] = { ...copy[index], xeroRate: xeroRate };
    //copy[type][index]["xeroRate"] = xeroRate;
    setExpenseTaxs(copy);
  };

  const handleStatusChange = (e) => {
    const { value, name } = e.target;
    setStatusValues({ ...statusValues, [name]: value });
  };

  const handleBrandingThemeChange = (value) => {
    setBrandingTheme(value);
  };

  ////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    // get xero connection status
    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      axios
        .get(SERVER_URL + `/integration/xero/accounts`, config)
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus((preState) => ({
              ...preState,
              loading: false,
              success: true,
              error: "",
            }));

            let a = response.data.data.accounts;
            let inventoryAccounts = [];
            let revenueAccounts = [];
            let assetAccounts = [];
            let cogsAccounts = [];
            let costAccounts = [];

            for (let i = 0; i < a.length; i++) {
              if (a[i].type === "INVENTORY") {
                a[i].label = a[i].name;
                a[i].value = a[i].name;
                inventoryAccounts.push(a[i]);
              }
              if (a[i]._class === "REVENUE") {
                a[i].label = a[i].name;
                a[i].value = a[i].name;
                revenueAccounts.push(a[i]);
              }
              if (a[i].type === "DIRECTCOSTS" && a[i].code !== "300") {
                a[i].label = a[i].name;
                a[i].value = a[i].name;
                cogsAccounts.push(a[i]);
              }
              if (a[i].type === "CURRENT" && a[i].code !== "610") {
                a[i].label = a[i].name;
                a[i].value = a[i].name;
                assetAccounts.push(a[i]);
              }
              if (
                a[i]._class === "EXPENSE" &&
                a[i].code !== "310" &&
                a[i].code !== "300"
              ) {
                a[i].label = a[i].name;
                a[i].value = a[i].name;
                costAccounts.push(a[i]);
              }
            }

            let accounts = {
              inventoryAccounts: inventoryAccounts,
              revenueAccounts: revenueAccounts,
              assetAccounts: assetAccounts,
              cogsAccounts: cogsAccounts,
              costAccounts: costAccounts,
            };

            setXeroAccounts(accounts);

            let brandingThemeOptions = response.data.data.brandingThemes;

            brandingThemeOptions.forEach((ele) => {
              ele.label = ele.name;
              ele.value = ele.brandingThemeID;
            });
            setXeroBrandingThemes(brandingThemeOptions);
            // fill account mapping value if has initialdata/.ofgip..p[8

            if (initialData) {
              setMappingValues({
                stockOnHand: accounts.inventoryAccounts.filter(
                  (x) => x.code === initialData.accounts.stockOnHand.code
                )[0],
                salesProducts: accounts.revenueAccounts.filter(
                  (x) => x.code === initialData.accounts.salesProducts.code
                )[0],
                salesFreight: accounts.revenueAccounts.filter(
                  (x) => x.code === initialData.accounts.salesFreight.code
                )[0],
                purchaseProducts: initialData.accounts.purchaseProducts
                  ? accounts.costAccounts.filter(
                      (x) =>
                        x.code === initialData.accounts.purchaseProducts.code
                    )[0]
                  : "",
                purchaseFreight: initialData.accounts.purchaseFreight
                  ? accounts.costAccounts.filter(
                      (x) =>
                        x.code === initialData.accounts.purchaseFreight.code
                    )[0]
                  : "",

                stockAdjustment: accounts.costAccounts.filter(
                  (x) => x.code === initialData.accounts.stockAdjustment.code
                )[0],
                cogs: accounts.cogsAccounts.filter(
                  (x) => x.code === initialData.accounts.cogs.code
                )[0],
              });

              setStatusValues({
                saleInvoice: invoiceStatusOptions.filter(
                  (x) => x.value === initialData.status.saleInvoice
                )[0],
                saleJournal: invoiceStatusOptions.filter(
                  (x) => x.value === initialData.status.saleJournal
                )[0],
                purchaseOrderBill: invoiceStatusOptions.filter(
                  (x) => x.value === initialData.status.purchaseOrderBill
                )[0],
                stockAdjustJournal: invoiceStatusOptions.filter(
                  (x) => x.value === initialData.status.stockAdjustJournal
                )[0],
              });
              setBrandingTheme(
                brandingThemeOptions.filter(
                  (x) =>
                    x.brandingThemeID ===
                    initialData.brandingTheme.brandingThemeID
                )[0]
              );
            }

            // set xero taxrates

            let xeroRates = response.data.data.xeroTaxRates;

            for (let i = 0; i < xeroRates.length; i++) {
              xeroRates[i].label = xeroRates[i].name;
              xeroRates[i].value = xeroRates[i].name;
            }
            setXeroTaxRates(xeroRates);

            // set rui revenue taxrates
            const initialTaxValues =
              initialData && "taxs" in initialData ? initialData.taxs : [];

            const findXeroRate = (taxRateId, type) => {
              let xeroTaxType = null;
              // find xero tax type in "initialTaxValues"

              initialTaxValues.forEach((elem) => {
                if (elem.taxrate_id === taxRateId && elem.type === type) {
                  xeroTaxType = elem.xero_tax_type;
                }
              });
              // find xero tax rate object in "xeroRates"
              if (xeroTaxType) {
                const foundTax = xeroRates.find(
                  (elem) => elem.taxType === xeroTaxType
                );
                if (foundTax) {
                  return foundTax;
                }
              }
              return null;
            };

            let revenueRates = [];
            let expenseRates = [];

            response.data.data.taxRates.forEach((elem) => {
              revenueRates.push({
                ...elem,
                xeroRate: findXeroRate(elem.id, "revenue"),
              });
              expenseRates.push({
                ...elem,
                xeroRate: findXeroRate(elem.id, "expense"),
              });
            });

            // set rui expense taxrates
            setRevenueTaxs(revenueRates);
            setExpenseTaxs(expenseRates);
          } else if (response.data.error === 10) {
            setRequestStatus((preState) => ({
              ...preState,
              loading: false,
            }));
            setLostConnection(true);
          } else {
            setRequestStatus((preState) => ({
              ...preState,
              loading: false,
              error: "Request Xero accounts falied, please try again later.",
            }));
          }
        })
        .catch((err) => {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            error: "Request Xero accounts falied, please try again later.",
          }));
        });
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: "Connection Error, please try again later.",
      }));
    }
  }, [SERVER_URL, userInfo.data.token, saveRequestStatus.success, initialData]);

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  const checkValidation = () => {
    let values = {
      accounts: mappingValues,
      revenueTaxs: revenueTaxs,
      expenseTaxs: expenseTaxs,
      status: statusValues,
      brandingTheme: brandingTheme,
    };
    const hasErrors = validate(values);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      saveConfiguration();
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.accounts.salesProducts) {
      errors.salesProducts = "Please select an account map to sales-products.";
    }
    if (!values.accounts.salesFreight) {
      errors.salesFreight =
        "Please select an account map to sales-other charge.";
    }
    if (!values.accounts.purchaseProducts) {
      errors.purchaseProducts =
        "Please select an account map to purchase-products.";
    }
    if (!values.accounts.purchaseFreight) {
      errors.purchaseFreight =
        "Please select an account map to purchase-other cost.";
    }
    if (!values.accounts.stockOnHand) {
      errors.stockOnHand = "Please select an account map to stock on hand.";
    }
    if (!values.accounts.stockAdjustment) {
      errors.stockAdjustment =
        "Please select an account map to stock adjustment.";
    }
    if (!values.accounts.cogs) {
      errors.cogs = "Please select an account map to COGS.";
    }

    if (!values.status.saleInvoice) {
      errors.saleInvoice = "Please select a status for sale invoice.";
    }
    if (!values.status.saleJournal) {
      errors.saleJournal = "Please select a status for sale journal.";
    }
    if (!values.status.purchaseOrderBill) {
      errors.purchaseOrderBill =
        "Please select a status for purchase order payable bill.";
    }
    if (!values.status.stockAdjustJournal) {
      errors.stockAdjustJournal =
        "Please select a status for stock change journal.";
    }
    if (!values.brandingTheme) {
      errors.brandingTheme = "Please select a branding theme for invoice.";
    }

    values.revenueTaxs.forEach((elem, index) => {
      if (!elem.xeroRate) {
        errors.revenueTax = {
          ...errors.revenueTax,
          [index]: "Please select a tax rate.",
        };
      }
    });

    values.expenseTaxs.forEach((elem, index) => {
      if (!elem.xeroRate) {
        errors.expenseTax = {
          ...errors.expenseTax,
          [index]: "Please select a tax rate.",
        };
      }
    });

    return errors;
  };

  const saveConfiguration = () => {
    let accountMappingValues = [
      {
        account: "salesProducts",
        xeroAccountCode: mappingValues.salesProducts.code,
        xeroAccountId: mappingValues.salesProducts.accountID,
        xeroAccountName: mappingValues.salesProducts.name,
      },
      {
        account: "salesFreight",
        xeroAccountCode: mappingValues.salesFreight.code,
        xeroAccountId: mappingValues.salesFreight.accountID,
        xeroAccountName: mappingValues.salesFreight.name,
      },
      {
        account: "purchaseProducts",
        xeroAccountCode: mappingValues.purchaseProducts.code,
        xeroAccountId: mappingValues.purchaseProducts.accountID,
        xeroAccountName: mappingValues.purchaseProducts.name,
      },
      {
        account: "purchaseFreight",
        xeroAccountCode: mappingValues.purchaseFreight.code,
        xeroAccountId: mappingValues.purchaseFreight.accountID,
        xeroAccountName: mappingValues.purchaseFreight.name,
      },
      {
        account: "stockOnHand",
        xeroAccountCode: mappingValues.stockOnHand.code,
        xeroAccountId: mappingValues.stockOnHand.accountID,
        xeroAccountName: mappingValues.stockOnHand.name,
      },
      {
        account: "stockAdjustment",
        xeroAccountCode: mappingValues.stockAdjustment.code,
        xeroAccountId: mappingValues.stockAdjustment.accountID,
        xeroAccountName: mappingValues.stockAdjustment.name,
      },
      {
        account: "cogs",
        xeroAccountCode: mappingValues.cogs.code,
        xeroAccountId: mappingValues.cogs.accountID,
        xeroAccountName: mappingValues.cogs.name,
      },
    ];

    let revenueTaxMappingValues = [];

    for (let i = 0; i < revenueTaxs.length; i++) {
      let taxMapping = {
        type: "revenue",
        taxrateId: revenueTaxs[i].id,
        xeroTaxType: revenueTaxs[i].xeroRate.taxType,
        xeroTaxName: revenueTaxs[i].xeroRate.name,
      };
      revenueTaxMappingValues.push(taxMapping);
    }

    let expenseTaxMappingValues = [];

    for (let i = 0; i < expenseTaxs.length; i++) {
      let taxMapping = {
        type: "expense",
        taxrateId: expenseTaxs[i].id,
        xeroTaxType: expenseTaxs[i].xeroRate.taxType,
        xeroTaxName: expenseTaxs[i].xeroRate.name,
      };
      expenseTaxMappingValues.push(taxMapping);
    }

    let statusMappingValues = [
      {
        settingType: "xero_so_invoice_status",
        value: statusValues.saleInvoice.value,
      },
      {
        settingType: "xero_so_invoice_journal",
        value: statusValues.saleJournal.value,
      },
      {
        settingType: "xero_po_payable_bill",
        value: statusValues.purchaseOrderBill.value,
      },
      {
        settingType: "xero_sto_journal",
        value: statusValues.stockAdjustJournal.value,
      },
    ];

    ///send request

    let saveValues = {
      revenueTaxs: revenueTaxMappingValues,
      expenseTaxs: expenseTaxMappingValues,
      accounts: accountMappingValues,
      invoiceStatus: statusMappingValues,
      brandingTheme: brandingTheme,
    };

    try {
      setSaveRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      axios
        .post(
          SERVER_URL + `/integration/xero/configuration`,
          saveValues,
          config
        )
        .then(async (response) => {
          if (response.data.success) {
            setSaveRequestStatus((preState) => ({
              ...preState,
              loading: false,
              success: true,
              error: "",
            }));
            dispatch(userDetailsRequest());
            navigate("/integration/xero", { replace: true, from: location });
          } else {
            setSaveRequestStatus((preState) => ({
              ...preState,
              loading: false,
              error:
                "Update Xero configuration failed, please try again later.",
            }));
          }
        })
        .catch((err) => {
          setSaveRequestStatus((preState) => ({
            ...preState,
            loading: false,
            error: "Save configuration failed, please try again later.",
          }));
        });
    } catch (error) {
      setSaveRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: "Connection Error, please try again later.",
      }));
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  const RightArrow = () => {
    return <BsArrowRight className={integrationStyle.arrowIcon} />;
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Xero Integration" />
      <ScreenTitle title="Xero Integration" />
      <div className={commonStyle.tabsContainer}>
        <XeroIntegrationTab tabName="config" />

        <div className={commonStyle.settingsContent}>
          <div className={integrationStyle.configCard}>
            <span>Connected Organization</span>
            <span className={integrationStyle.companyName}>{tenantName}</span>
            {lostConenction ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess="Lost connection with your Xero account, please reconnect to Xero" />
                <div className="w-fit">
                  <SimpleBlueButtonLink
                    path="/integration/xero/connect"
                    name="Reconnect to Xero"
                    data={{ reconnect: true }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className={integrationStyle.configCard}>
            <ViewLabel
              title="We will seamlessly synchronize your stock level, sales and purchasing data with
            Xero, ensuring that each component (.i.e subtotal, discount, tax,
            and shipping) of every sale and purchase is automatically mapped to
            your connected Xero account, allowing you to effortlessly track your
            revenue, expenses, and tax liabilities. Map below line items to your
            Xero account to get started."
            />
            {requestStatus.loading ? (
              <Loader mess="Requesting xero accounts options, please wait a moment..." />
            ) : requestStatus.success ? (
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <p className={integrationStyle.configSubTitle}>
                      Accounts Mapping
                    </p>
                    <div className={integrationStyle.lineItemCon}>
                      <div className={integrationStyle.lineNameEmpty}></div>
                      <div className={integrationStyle.arrowIcon}></div>
                      <div className={integrationStyle.accountTypeName}>
                        Xero Asset Account
                      </div>
                      <div className={integrationStyle.accountTypeName}>
                        Xero Revenue Account
                      </div>
                      <div className={integrationStyle.accountTypeName}>
                        Xero Expense Account
                      </div>
                    </div>

                    <div className={integrationStyle.lineItemCon}>
                      <div className={integrationStyle.lineName}>
                        Stock on Hand
                      </div>
                      <div>
                        <RightArrow />
                      </div>
                      <div className={integrationStyle.xeroAccountName}>
                        <StyledSelect
                          placeHolder="Select Xero account"
                          selectOptions={xeroAccounts.inventoryAccounts}
                          value={mappingValues.stockOnHand}
                          onChange={(stockOnHand) =>
                            handleChange({
                              target: {
                                value: stockOnHand,
                                name: "stockOnHand",
                              },
                            })
                          }
                          error={formErrors.stockOnHand}
                        />
                      </div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}></div>
                    </div>
                    <div className={integrationStyle.lineItemCon}>
                      <div className={integrationStyle.lineName}>
                        Sales-products
                      </div>
                      <div>
                        <RightArrow />
                      </div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}>
                        <StyledSelect
                          placeHolder="Select Xero account"
                          selectOptions={xeroAccounts.revenueAccounts}
                          value={mappingValues.salesProducts}
                          onChange={(salesProducts) =>
                            handleChange({
                              target: {
                                value: salesProducts,
                                name: "salesProducts",
                              },
                            })
                          }
                          error={formErrors.salesProducts}
                        />
                      </div>
                      <div className={integrationStyle.xeroAccountName}></div>
                    </div>

                    <div className={integrationStyle.lineItemCon}>
                      <div className={integrationStyle.lineName}>
                        Sales-Other Charge
                      </div>
                      <div>
                        <RightArrow />
                      </div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}>
                        <StyledSelect
                          placeHolder="Select Xero account"
                          selectOptions={xeroAccounts.revenueAccounts}
                          value={mappingValues.salesFreight}
                          onChange={(salesFreight) =>
                            handleChange({
                              target: {
                                value: salesFreight,
                                name: "salesFreight",
                              },
                            })
                          }
                          error={formErrors.salesFreight}
                        />
                      </div>
                      <div className={integrationStyle.xeroAccountName}></div>
                    </div>

                    <div className={integrationStyle.lineItemCon}>
                      <div className={integrationStyle.lineName}>
                        Purcahse-Products
                      </div>
                      <div>
                        <RightArrow />
                      </div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}>
                        <StyledSelect
                          placeHolder="Select Xero account"
                          selectOptions={xeroAccounts.costAccounts}
                          value={mappingValues.purchaseProducts}
                          onChange={(purchaseProducts) =>
                            handleChange({
                              target: {
                                value: purchaseProducts,
                                name: "purchaseProducts",
                              },
                            })
                          }
                          error={formErrors.purchaseProducts}
                        />
                      </div>
                    </div>

                    <div className={integrationStyle.lineItemCon}>
                      <div className={integrationStyle.lineName}>
                        Purchase-Other Cost
                      </div>
                      <div>
                        <RightArrow />
                      </div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}>
                        <StyledSelect
                          placeHolder="Select Xero account"
                          selectOptions={xeroAccounts.costAccounts}
                          value={mappingValues.purchaseFreight}
                          onChange={(purchaseFreight) =>
                            handleChange({
                              target: {
                                value: purchaseFreight,
                                name: "purchaseFreight",
                              },
                            })
                          }
                          error={formErrors.purchaseFreight}
                        />
                      </div>
                    </div>

                    <div className={integrationStyle.lineItemCon}>
                      <div className={integrationStyle.lineName}>
                        Stock Adjustment
                      </div>
                      <div>
                        <RightArrow />
                      </div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}>
                        <StyledSelect
                          placeHolder="Select Xero account"
                          selectOptions={xeroAccounts.costAccounts}
                          value={mappingValues.stockAdjustment}
                          onChange={(stockAdjustment) =>
                            handleChange({
                              target: {
                                value: stockAdjustment,
                                name: "stockAdjustment",
                              },
                            })
                          }
                          error={formErrors.stockAdjustment}
                        />
                      </div>
                    </div>

                    <div className={integrationStyle.lineItemCon}>
                      <div className={integrationStyle.lineName}>
                        Cost of Goods Sold
                      </div>
                      <div>
                        <RightArrow />
                      </div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}></div>
                      <div className={integrationStyle.xeroAccountName}>
                        <StyledSelect
                          placeHolder="Select Xero account"
                          selectOptions={xeroAccounts.cogsAccounts}
                          value={mappingValues.cogs}
                          onChange={(cogs) =>
                            handleChange({
                              target: {
                                value: cogs,
                                name: "cogs",
                              },
                            })
                          }
                          error={formErrors.cogs}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={integrationStyle.divider}></div>

                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <p className={integrationStyle.configSubTitle}>
                      Invoice/Bill Status
                    </p>
                    <div className={integrationStyle.statusLineItemCon}>
                      <div className={integrationStyle.statusLineName}>
                        Sale order invoice
                      </div>
                      <div style={{ flex: 1 }}>
                        <StyledSelect
                          placeHolder="select status"
                          selectOptions={invoiceStatusOptions}
                          value={statusValues.saleInvoice}
                          onChange={(saleInvoice) =>
                            handleStatusChange({
                              target: {
                                value: saleInvoice,
                                name: "saleInvoice",
                              },
                            })
                          }
                          error={formErrors.saleInvoice}
                        />
                      </div>
                    </div>
                    <div className={integrationStyle.statusLineItemCon}>
                      <div className={integrationStyle.statusLineName}>
                        Purchase order (payable bill)
                      </div>
                      <div style={{ flex: 1 }}>
                        <StyledSelect
                          placeHolder="select status"
                          selectOptions={invoiceStatusOptions}
                          value={statusValues.purchaseOrderBill}
                          onChange={(purchaseOrderBill) =>
                            handleStatusChange({
                              target: {
                                value: purchaseOrderBill,
                                name: "purchaseOrderBill",
                              },
                            })
                          }
                          error={formErrors.purchaseOrderBill}
                        />
                      </div>
                    </div>
                    <div className={integrationStyle.statusLineItemCon}>
                      <div className={integrationStyle.statusLineName}>
                        Stock adjustment journal
                      </div>
                      <div style={{ flex: 1 }}>
                        <StyledSelect
                          placeHolder="select status"
                          selectOptions={invoiceStatusOptions}
                          value={statusValues.stockAdjustJournal}
                          onChange={(stockAdjustJournal) =>
                            handleStatusChange({
                              target: {
                                value: stockAdjustJournal,
                                name: "stockAdjustJournal",
                              },
                            })
                          }
                          error={formErrors.stockAdjustJournal}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "32px", flex: 1 }}>
                    <p className={integrationStyle.configSubTitle}>
                      Invoice Branding Theme
                    </p>
                    <div className={integrationStyle.statusLineItemCon}>
                      <div className={integrationStyle.statusLineName}>
                        Invoice Branding Theme
                      </div>
                      <div style={{ flex: 1 }}>
                        <StyledSelect
                          placeHolder="select branding theme"
                          selectOptions={xeroBrandingThemes}
                          value={brandingTheme}
                          onChange={(brandingTheme) =>
                            handleBrandingThemeChange(brandingTheme)
                          }
                          error={formErrors.brandingTheme}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* tax rate mapping */}
                <div className={integrationStyle.divider}></div>

                <p className={integrationStyle.configSubTitle}>Tax Mapping</p>

                <div>
                  <div className={integrationStyle.lineItemCon}>
                    <div className={integrationStyle.lineNameEmpty}></div>
                    <div className={integrationStyle.arrowIcon}></div>
                    <div className={integrationStyle.rateTypeName}>
                      Revenue Tax Rate
                    </div>
                    <div className={integrationStyle.rateTypeName}>
                      Expense Tax Rate
                    </div>
                  </div>

                  {revenueTaxs.length > 0 ? (
                    revenueTaxs.map((item, index) => (
                      <div className={integrationStyle.lineItemCon} key={index}>
                        <div className={integrationStyle.lineName}>
                          {item.label}
                        </div>
                        <div>
                          <RightArrow />
                        </div>
                        <div className={integrationStyle.xeroRateName}>
                          <StyledSelect
                            placeHolder="Select Xero tax rate"
                            selectOptions={xeroTaxrates}
                            value={
                              revenueTaxs[index]
                                ? revenueTaxs[index].xeroRate
                                : ""
                            }
                            onChange={(xeroRate) =>
                              handleRevenueTaxChange({
                                target: {
                                  xeroRate: xeroRate,
                                  index: index,
                                },
                              })
                            }
                            error={
                              formErrors.revenueTax
                                ? formErrors.revenueTax[index]
                                : ""
                            }
                          />
                        </div>

                        <div className={integrationStyle.xeroRateName}>
                          <StyledSelect
                            placeHolder="Select Xero tax rate"
                            selectOptions={xeroTaxrates}
                            value={
                              expenseTaxs[index]
                                ? expenseTaxs[index].xeroRate
                                : ""
                            }
                            onChange={(xeroRate) =>
                              handleExpenseTaxChange({
                                target: {
                                  xeroRate: xeroRate,
                                  index: index,
                                },
                              })
                            }
                            error={
                              formErrors.expenseTax
                                ? formErrors.expenseTax[index]
                                : ""
                            }
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}

                  {saveRequestStatus.error ? (
                    <TextErrorMessage mess={saveRequestStatus.error} />
                  ) : (
                    <></>
                  )}

                  <div className="w-full mt-6">
                    {saveRequestStatus.loading ? (
                      <LoadingButton name="Saving data..." />
                    ) : (
                      <SimpleBlueButton
                        name="Save Configuration"
                        action={checkValidation}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : requestStatus.error ? (
              <TextErrorMessage mess={requestStatus.error} />
            ) : (
              <></>
            )}
          </div>
          <DisconnectXero />
        </div>
      </div>
    </div>
  );
}
