import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { IMPORT_XERO_DATA_TASK_LIST_FAIL, IMPORT_XERO_DATA_TASK_LIST_REQUEST, IMPORT_XERO_DATA_TASK_LIST_SUCCESS, XERO_TASK_LIST_FAIL, XERO_TASK_LIST_REQUEST, XERO_TASK_LIST_SUCCESS } from "../constants/xeroConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


export const xeroTaskListRequest = (type, pageNum, pageSize) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  try {
    dispatch({ type: XERO_TASK_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/integration/xero/data?type=${type}&pageNum=${pageNum}&pageSize=${pageSize}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {

          dispatch({
            type: XERO_TASK_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request Xero task list falied, please try again later."),
            { code: 1805 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: XERO_TASK_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: XERO_TASK_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};


export const importXeroDataTaskListRequest = (pageNum, pageSize) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  try {
    dispatch({ type: IMPORT_XERO_DATA_TASK_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/integration/xero/data/import-tasks?pageNum=${pageNum}&pageSize=${pageSize}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: IMPORT_XERO_DATA_TASK_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw response.data.message;
        }
      })
      .catch((err) => {
        dispatch({
          type: IMPORT_XERO_DATA_TASK_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: IMPORT_XERO_DATA_TASK_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

