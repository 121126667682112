import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { searchPurchaseProductRequest } from "../actions/purchaseOrderActions";
import NewProductModal from "../screens/inventory/product/NewProductModal";
import pickerStyle from "../style/productPickerStyle.module.css";
import { TypingSearchInput } from "./inputFields";
import {
  ErrorMessage,
  HightlightKeywords,
  Loader,
  SmallProductImage,
  TextErrorMessage
} from "./viewComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ProductPicker({
  locationId,
  priceTierId,
  onSelect,
  requiredType,
  locationRequired = false,
  showCreateBtn = false,
}) {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const divRef = useRef(null);
  const isFirstRender = useRef(true);
  const shouldSearch = useRef(true);

  const useDebounce = (text, delay) => {
    const [debounce, setDebounce] = useState(text);
    useEffect(() => {
      const timer = setTimeout(() => {
        setDebounce(text);
      }, delay);
      return () => {
        clearTimeout(timer);
      };
    }, [text, text]);
    return debounce;
  };

  const {
    loading: seacrhLoading,
    products,
    error: searchError,
    success: seacrhSuccess,
  } = useSelector((state) => state.searchPurchaseProduct);

  const [formError, setFormError] = useState("");

  const [searchKeywords, setSearchKeywords] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const decouncedText = useDebounce(searchKeywords, 500);

  const [newProductModal, setNewProductModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // Clicked outside of the div, so close it
        setShowSearchResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFocusSearch = () => {
    if (locationRequired && !locationId) {
      setFormError("Please select a location.");
      return;
    }
    setFormError("");
    searcProductsRequest(searchKeywords);
  };

  const handleSearchChange = (e) => {
    const { value, index } = e.target;
    setFormError("");
    setSearchKeywords(value);
    shouldSearch.current = true; // Allow search on input change
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (shouldSearch.current) {
      searcProductsRequest(decouncedText);
    }
  }, [decouncedText]);

  const searcProductsRequest = (keywords) => {
    // set the currect row show the search results
    setShowSearchResults(true);
    let productType = "allProduct";
    // requiredType
    // product -> only search product type: "product, rentalProduct"
    // productAndService -> only search product type: "product, rentalProduct, service"
    //saleProduct -> only search product type: "product, service, package"
    if (requiredType) {
      productType = requiredType;
    }
    dispatch(searchPurchaseProductRequest(keywords, productType));
  };

  const getOrderItemDetails = (pro) => {
    let famid = pro.id;
    let productType = pro.type;

    try {
      // construct url
      let url = SERVER_URL + `/product/search/details/${famid}`;

      if (productType === "package") {
        if (locationId) {
          url =
            SERVER_URL +
            `/product/product-package/details/${famid}?locationId=${locationId}`;
        } else {
          url = SERVER_URL + `/product/product-package/details/${famid}`;
        }
      } else {
        if (!priceTierId && locationId) {
          url =
            SERVER_URL +
            `/product/search/details/${famid}?locationId=${locationId}`;
        } else if (priceTierId && !locationId) {
          url =
            SERVER_URL +
            `/product/search/details/${famid}?priceTierId=${priceTierId}`;
        } else if (priceTierId && locationId) {
          url =
            SERVER_URL +
            `/product/search/details/${famid}?locationId=${locationId}&priceTierId=${priceTierId}`;
        } else {
          url = SERVER_URL + `/product/search/details/${famid}`;
        }
      }

      axios
        .get(url, config)
        .then(async (response) => {
          if (response.data.success) {
            setShowSearchResults(false);
            onSelect({ productType: productType, data: response.data.data });
            setSearchKeywords("");
            shouldSearch.current = false;
          }
        })
        .catch((err) => {});
    } catch (error) {}
  };

  const handleCreate = (e) => {
    e.preventDefault();
    setNewProductModal(true);
  };

  const getNewProductId = (newProId) => {
    let new_pro = {
      id: newProId,
      type: "product",
    };
    getOrderItemDetails(new_pro);
    setNewProductModal(false);
  };

  return (
    <div className={pickerStyle.serchCon}>
      <NewProductModal
        title="Create New Product"
        isModalOpen={newProductModal}
        closeModal={() => setNewProductModal(false)}
        onCreateSuccess={getNewProductId}
      />

      {formError ? <TextErrorMessage mess={formError} /> : <></>}
      <TypingSearchInput
        type="text"
        name="searchKeywords"
        placeholdertext="Type product name/code to search..."
        value={searchKeywords}
        onFocus={handleFocusSearch}
        onChange={(searchKeywords) =>
          handleSearchChange({
            target: {
              value: searchKeywords,
            },
          })
        }
      />

      {showSearchResults ? (
        <div className={pickerStyle.searchResultsCon} ref={divRef}>
          <div className={pickerStyle.searchResultsProductCon}>
            {
              showCreateBtn && <div className="w-full">
              <button
                className="text-green-500 flex flex-row items-center pl-2 h-10 hover:underline"
                onClick={handleCreate}
              >
                <BsPlusCircle className="mr-1" />
                <span>Create New Product</span>
              </button>
            </div>
            }
          
            {seacrhLoading ? (
              <Loader mess="Searching products...." />
            ) : seacrhSuccess ? (
              products.length > 0 ? (
                <ul>
                  {products.map((pro, index2) => (
                    <li
                      key={index2}
                      className={pickerStyle.searchResultLine}
                      onClick={() => getOrderItemDetails(pro)}
                    >
                      <div className="flex flex-row">
                        {pro.image && (
                          <div className="w-[60px] h-[60px] mr-2 bg-lightbg rounded">
                            <SmallProductImage
                              alt={"product image /" + pro.image}
                              src={pro.image}
                            />
                          </div>
                        )}
                        <div className="flex flex-col">
                          {pro.type === "package" ? (
                            <div className={pickerStyle.proTypeTag}>
                              Package
                            </div>
                          ) : pro.sku ? (
                            <div className="w-fit text-gray border border-solid border-gray rounded-sm px-1">
                              <HightlightKeywords
                                keywords={searchKeywords}
                                text={pro.sku}
                                key={index2}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="mt-1">
                            <HightlightKeywords
                              keywords={searchKeywords}
                              text={pro.name}
                              key={index2}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className={pickerStyle.noResultLine}>
                  No products match, please change search words and try again
                </p>
              )
            ) : (
              <ErrorMessage mess={searchError} />
            )}{" "}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
