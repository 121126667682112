import {
  SALE_ORDER_ACTIONS_FAIL,
  SALE_ORDER_ACTIONS_REQUEST,
  SALE_ORDER_ACTIONS_SUCCESS,
  SALE_ORDER_CREATE_FAIL,
  SALE_ORDER_CREATE_REQUEST,
  SALE_ORDER_CREATE_SUCCESS,
  SALE_ORDER_DETAILS_FAIL,
  SALE_ORDER_DETAILS_REQUEST,
  SALE_ORDER_DETAILS_SUCCESS,
  SALE_ORDER_EDIT_AFTER_APPROVE_FAIL,
  SALE_ORDER_EDIT_AFTER_APPROVE_REQUEST,
  SALE_ORDER_EDIT_AFTER_APPROVE_SUCCESS,
  SALE_ORDER_EDIT_FAIL,
  SALE_ORDER_EDIT_REQUEST,
  SALE_ORDER_EDIT_SUCCESS,
  SALE_ORDER_LIST_FAIL,
  SALE_ORDER_LIST_REQUEST,
  SALE_ORDER_LIST_SUCCESS,
  SALE_ORDER_OPTIONS_FAIL,
  SALE_ORDER_OPTIONS_REQUEST,
  SALE_ORDER_OPTIONS_SUCCESS,
  SHIP_SALE_ORDER_FAIL,
  SHIP_SALE_ORDER_REQUEST,
  SHIP_SALE_ORDER_SUCCESS,
} from "../constants/saleOrderConstants";


export const saleOrderOptionsReducer = (
  state = { options: [] },
  action
) => {
  switch (action.type) {
    case SALE_ORDER_OPTIONS_REQUEST:
      return { loading: true };
    case SALE_ORDER_OPTIONS_SUCCESS:
      return {
        loading: false,
        options: action.payload,
        success: true,
      };
    case SALE_ORDER_OPTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const createSaleOrderReducer = (state = {orderId:""}, action) => {
  switch (action.type) {
    case SALE_ORDER_CREATE_REQUEST:
      return { loading: true };
    case SALE_ORDER_CREATE_SUCCESS:
      return { loading: false,orderId:action.payload, success: true };
    case SALE_ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const saleOrderListReducer = (state = { saleOrders: [] }, action) => {
  switch (action.type) {
    case SALE_ORDER_LIST_REQUEST:
      return { loading: true, success: false };
    case SALE_ORDER_LIST_SUCCESS:
      return { loading: false, saleOrders: action.payload, success: true };
    case SALE_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const saleOrderDetailsReducer = (state = { orderInfo: [] }, action) => {
  switch (action.type) {
    case SALE_ORDER_DETAILS_REQUEST:
      return { loading: true, success: false };
    case SALE_ORDER_DETAILS_SUCCESS:
      return { loading: false, orderInfo: action.payload, success: true };
    case SALE_ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const saleOrderActionsReducer = (state = { actions: [] }, action) => {
  switch (action.type) {
    case SALE_ORDER_ACTIONS_REQUEST:
      return { loading: true, success: false };
    case SALE_ORDER_ACTIONS_SUCCESS:
      return { loading: false, actions: action.payload, success: true };
    case SALE_ORDER_ACTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const editSaleOrderReducer = (state = {orderId:""}, action) => {
  switch (action.type) {
    case SALE_ORDER_EDIT_REQUEST:
      return { loading: true };
    case SALE_ORDER_EDIT_SUCCESS:
      return { loading: false,orderId:action.payload, success: true };
    case SALE_ORDER_EDIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const editSaleOrderAfterApproveReducer = (state = {orderId:""}, action) => {
  switch (action.type) {
    case SALE_ORDER_EDIT_AFTER_APPROVE_REQUEST:
      return { loading: true };
    case SALE_ORDER_EDIT_AFTER_APPROVE_SUCCESS:
      return { loading: false, orderId:action.payload, success: true };
    case SALE_ORDER_EDIT_AFTER_APPROVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const shipSaleOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIP_SALE_ORDER_REQUEST:
      return { loading: true };
    case SHIP_SALE_ORDER_SUCCESS:
      return { loading: false, success: true };
    case SHIP_SALE_ORDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};



