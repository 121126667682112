import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  customerListRequest,
  deleteCustomerRequest,
} from "../../actions/customerActions";
import Breadcrumbs from "../../components/Breadcrumbs";
import Pagination from "../../components/Pagination";
import {
  AddLinkButton,
  DeleteButton,
  EditButtonLink,
  ScreenTitle,
  ViewButtonLink,
} from "../../components/editComponents";
import {
  DeleteDialog,
  ErrorMessage,
  Loader,
  SortedTableTitle,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { Search } from "../../components/inputFields";
import { useNavigate } from "react-router-dom";

export default function CustomersScreen() {
  useDocumentTitle("Customers");
  const navigate = useNavigate();
  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, customers, error, success } = useSelector(
    (state) => state.customerList
  );

  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = useSelector((state) => state.deleteCustomer);

  const sortOptions = [
    { id: 1, name: "Sort by name A - Z", value: "asc" },
    { id: 2, name: "Sort by name Z - A", value: "desc" },
  ];

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  // search
  const [keyWords, setKeywords] = useState("");

  // sort
  // const [sortBy, setSortBy] = useState("name"); // default sort column
  const [sortOrder, setSortOrder] = useState(""); // 'asc' or 'desc'

  // delete
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(customerListRequest(pageNum, pageSize, keyWords, sortOrder));
  }, [dispatch, pageNum, keyWords, sortOrder, deleteSuccess]);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };
  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };
  // Handle column sort
  const handleSort = (value) => {
    setSortOrder(value);
  };

  ///////////////////////////////////////////////////////

  const deleteAction = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = () => {
    dispatch(deleteCustomerRequest(deleteId));
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const naviogateToDetails = (customerId) => {
    navigate(`/customer/preview/${customerId}`, { replace: false });
  }


  return (
    <div className={commonStyle.pageContainer}>
      <DeleteDialog
        title="Delete Customer"
        preDeleteMess="Deleting this customer can not be undone. This action will not affect the history sale orders' records. Are you sure you want to proceed with deleting this customer?"
        successMessage="Customer deleted successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={() => setDeleteDialog(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      <Breadcrumbs screenName="Manage Customers" />
      <ScreenTitle title="Customers" />

      <div className={commonStyle.pageContentContainer}>
        {/* check if has access for "new_product" */}
        <div className="flex flex-row justify-between">
          <div className="w-2/3 mr-6">
            <Search
              placeholdertext="Type customer name to search..."
              initialQuery={keyWords}
              onChange={handleSearchChange}
            />
          </div>
          {userDetailsSuccess ? (
            userDetails.data.permissions.includes(
              "add_delete_edit_customers"
            ) ? (
              <AddLinkButton name="New Customer" path="/customer/new" />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>

        {loading ? (
          <Loader mess="Requesting customer list, please wait a moment..." />
        ) : success ? (
          <div>
            {Number(customers.totalCount) > 0 ? (
              <p className="mt-4">
                Found
                <span className="text-brandColor font-medium mx-1">
                  {customers.totalCount}
                </span>
                customers
              </p>
            ) : (
              <></>
            )}

            {/* hold the tax rates table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>
                    <SortedTableTitle
                      name={"Customer Name"}
                      onSelect={handleSort}
                      options={sortOptions}
                    />
                  </th>
                  <th>Country</th>
                  <th>Currency</th>
                  <th>Tax Rate</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {customers.customers.length > 0 ? (
                  customers.customers.map((item, index) => (
                    <tr key={index}>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">{item.name} </td>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">{item.country} </td>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">{item.currency} </td>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">{item.taxRate} </td>
                      <td>
                        <div className={commonStyle.actionsGrop}>
                          {userDetailsSuccess ? (
                            userDetails.data.permissions.includes(
                              "view_customers"
                            ) ? (
                              <ViewButtonLink
                                path={`/customer/preview/${item.id}`}
                                data={item.taxRate}
                              />
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                          {userDetailsSuccess ? (
                            userDetails.data.permissions.includes(
                              "add_delete_edit_customers"
                            ) ? (
                              <>
                                <EditButtonLink
                                  path={`/customer/edit/${item.id}`}
                                />
                                <DeleteButton
                                  action={() => deleteAction(item.id)}
                                />
                              </>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no customer available, add a new customer now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {customers.customers.length > 0 ? (
              <Pagination
                totalPageNum={customers.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
