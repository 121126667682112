import React, { useState } from "react";
import {
  AddItem,
  SimpleBlueButton,
  XDeleteButton,
} from "../../../components/editComponents";
import {
  DateInput,
  NoBorderInput,
  StyledInput,
} from "../../../components/inputFields";
import {
  BatchNumberCollect,
  SerialNumberCollect,
} from "../../../components/trackingNumbersComponents";
import {
  FromTag,
  PriceText,
  SubTitle,
  TextErrorMessage,
  VariantSkuTag,
  ViewDateWithTitle,
  ViewLabel,
  ViewText,
  XeroAccountMappingTip,
} from "../../../components/viewComponents";
import { convtPrice, convtQuantity } from "../../../functions/functions";
import { validatePrice, validateQuantity } from "../../../functions/validate";
import commonStyle from "../../../style/commonStyle.module.css";
import purchaseStyle from "../purchaseStyle.module.css";
import { OrderAttachments } from "../../../components/attachmentComponents";

export default function ReceivePurchaseOrderForm({
  notes,
  orderBasicInfo,
  orderItems,
  initialCost,
  initialFiles,
  initialSummary,
  submitReceiveStock,
}) {
  // get data from store by using useSelector method
  const [invoice, setInvoice] = useState({
    invoiceNo: "",
    invoiceDate: "",
    invoiceDue: "",
  });
  const [orderNotes, setOrderNotes] = useState(notes);
  const [orderProducts, setOrderProducts] = useState(orderItems);
  const [addtionalCosts, setAddtionalCosts] = useState(initialCost);
  const [orderFiles, setOrderFiles] = useState(initialFiles);
  const [orderSummary, setOrderSummary] = useState(initialSummary);

  //define the error message
  const [formErrors, setFormErrors] = useState("");

  ////////////////////////////////////Handle Change/////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoice({ ...invoice, [name]: value });
  };

  const handleReceivedStockChange = (e) => {
    const { key, value, index } = e.target;
    const validated = validateQuantity(value);
    if (!validated) return;

    let newOrderProducts = [...orderProducts];
    newOrderProducts[index][key] = value;
    setOrderProducts(newOrderProducts);

    // re calculate order summary
    if (
      newOrderProducts[index].unitCost &&
      newOrderProducts[index].receivedQuantity >= 0
    ) {
      if (newOrderProducts[index].discount) {
        // has discount value
        let discountedUnitCost = convtPrice(
          newOrderProducts[index].unitCost *
            (1 - newOrderProducts[index].discount / 100)
        );
        // has discount value
        newOrderProducts[index]["totalCost"] = convtPrice(
          discountedUnitCost * Number(newOrderProducts[index].receivedQuantity)
        );
      } else {
        // no discount value
        newOrderProducts[index]["totalCost"] = convtPrice(
          Number(newOrderProducts[index].unitCost) *
            Number(newOrderProducts[index].receivedQuantity)
        );
      }
    } else {
      newOrderProducts[index]["totalCost"] = "";
    }

    // caculate order total price before tax
    let orderSummaryCopy = { ...orderSummary };
    let newProductsTotal = newOrderProducts.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.totalCost),
      0
    );

    orderSummaryCopy.productsTotal = newProductsTotal;
    setOrderSummary(orderSummaryCopy);
  };

  const handleCostChange = (e) => {
    const { key, value, index } = e.target;
    const type = e.type;

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    let costsCopy = [...addtionalCosts];
    costsCopy[index][key] = value;
    setAddtionalCosts(costsCopy);

    // calculate cost total
    let orderSummaryCopy = { ...orderSummary };
    let costTotal = costsCopy.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.cost),
      0
    );
    orderSummaryCopy.additionalCostTotal = costTotal;
    setOrderSummary(orderSummaryCopy);
  };

  const addAdditionalCost = () => {
    let costCopy = [...addtionalCosts];
    costCopy.push({
      costName: "",
      cost: "",
    });

    setAddtionalCosts(costCopy);
  };

  const deleteCostItem = (index) => {
    let costCopy = [...addtionalCosts];
    costCopy.splice(index, 1);

    // re calculate cost total
    let orderSummaryCopy = { ...orderSummary };
    let costTotal = costCopy.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.cost),
      0
    );
    orderSummaryCopy.additionalCostTotal = costTotal;

    setAddtionalCosts(costCopy);
    setOrderSummary(orderSummaryCopy);
  };

  /////////////////////////////////Batch and Serial tracking//////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const getSerialNumbersStockReveive = (serialNumbers, index) => {
    let orderProductsCopy = [...orderProducts];
    orderProductsCopy[index].serialNumbers = serialNumbers;
    setOrderProducts(orderProductsCopy);
  };

  const getBatchNumbersStockReveive = (batchNumbers, index) => {
    let orderProductsCopy = [...orderProducts];
    orderProductsCopy[index].batchNumbers = batchNumbers;
    setOrderProducts(orderProductsCopy);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////Submit and check values/////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const checkValidation = () => {
    if (orderProducts.length <= 0) {
      setFormErrors("No product!");
      return;
    }

    if (!orderProducts.some((x) => Number(x.receivedQuantity) > 0)) {
      setFormErrors("Please enter at least one received quantity!");
      return;
    }

    for (const item of orderProducts) {
      if (Number(item.receivedQuantity) > Number(item.quantity)) {
        setFormErrors(
          "The received quantity is greater than the ordered quantity!"
        );
        return;
      }
    }

    if (
      orderProducts.some(
        (item) =>
          item.serialized &&
          Number(item.serialNumbers.length) !== Number(item.receivedQuantity)
      )
    ) {
      setFormErrors("Please enter correct serial numbers.");
      return;
    }
    if (
      orderProducts.some(
        (item) =>
          item.batchTracked &&
          parseFloat(
            item.batchNumbers
              .reduce((accumulator, currentValue) => {
                return accumulator + Number(currentValue.quantity);
              }, 0)
              .toFixed(2)
          ) !== Number(item.receivedQuantity)
      )
    ) {
      setFormErrors("Please enter correct batch numbers.");
      return;
    }

    let receiveStockInfo = {
      orderNotes: orderNotes,
      invoice: invoice,
      receivedStock: orderProducts,
      costs: addtionalCosts,
      files: orderFiles,
      orderSummary: orderSummary,
    };
    setFormErrors("");
    submitReceiveStock(receiveStockInfo);
  };

  const getAttachments = (files) => {
    let new_files = [];
    files.forEach((x) => {
      new_files.push({
        fileName: x.fileName,
        uri: x.uri,
      });
    });

    setOrderFiles(new_files);
  };

  return (
    <div>
      {/* purchase order infomation */}
      <XeroAccountMappingTip pageName="purchaseOrder" />
      <SubTitle name="Purchase Order Information" />
      <div className="w-full flex flex-row mt-4">
        <div className="w-[60%] bg-lightbg">
          <div className="w-full grid grid-cols-2 px-2">
            <div className="w-full">
              <ViewText title="Order No." value={orderBasicInfo.orderNumber} />
              <ViewText title="Supplier" value={orderBasicInfo.supplier} />
              <ViewLabel title="Billing Address" />
              {orderBasicInfo.billingAddress.country ? (
                <p>{orderBasicInfo.billingAddress.country.value}</p>
              ) : (
                <></>
              )}
              {orderBasicInfo.billingAddress.region ? (
                <p>{orderBasicInfo.billingAddress.region}</p>
              ) : (
                <></>
              )}
              {orderBasicInfo.billingAddress.city ? (
                <p>{orderBasicInfo.billingAddress.city}</p>
              ) : (
                <></>
              )}
              {orderBasicInfo.billingAddress.addressLine ? (
                <p>{orderBasicInfo.billingAddress.addressLine}</p>
              ) : (
                <></>
              )}
              {orderBasicInfo.billingAddress.postalCode ? (
                <p>{orderBasicInfo.billingAddress.postalCode}</p>
              ) : (
                <></>
              )}
            </div>
            <div className="w-full">
              <ViewDateWithTitle
                title="Order Date"
                value={orderBasicInfo.createDate}
              />
              <ViewText title="Location" value={orderBasicInfo.location} />
              <ViewText title="Tax Rate" value={orderBasicInfo.taxrateName} />
              <ViewText title="Currecy" value={orderBasicInfo.currency} />
              {orderBasicInfo.expectedDate > 0 ? (
                <ViewDateWithTitle
                  title="Expected Delvery Date"
                  value={orderBasicInfo.expectedDate}
                />
              ) : (
                <></>
              )}

              {orderBasicInfo.deliveryAddress && (
                <ViewText
                  title="Address"
                  value={orderBasicInfo.deliveryAddress}
                />
              )}

              {orderBasicInfo.projectName && (
                <ViewText title="Project" value={orderBasicInfo.projectName} />
              )}

              {/* {orderBasicInfo.notes && (
              <ViewText title="Notes" value={orderBasicInfo.notes} />
            )} */}
            </div>
          </div>
          <div className="w-full p-2">
            <StyledInput
              label="Notes"
              type="text"
              name="notes"
              value={orderNotes}
              onChange={(orderNotes) => setOrderNotes(orderNotes)}
            />
          </div>
        </div>

        <div className="w-6"></div>

        <div className={purchaseStyle.invoiceCon}>
          <p className={purchaseStyle.invoiceTitle}>Invoice</p>
          <StyledInput
            label="Invoice No."
            type="text"
            name="invoiceNo"
            value={invoice.invoiceNo}
            onChange={(invoiceNo) =>
              handleChange({
                target: {
                  value: invoiceNo,
                  name: "invoiceNo",
                },
              })
            }
          />

          <DateInput
            label="Invoice Date"
            value={invoice.invoiceDate}
            onChange={(invoiceDate) =>
              handleChange({
                target: {
                  value: invoiceDate,
                  name: "invoiceDate",
                },
              })
            }
          />

          <DateInput
            label="Invoice Due Date"
            value={invoice.invoiceDue}
            onChange={(invoiceDue) =>
              handleChange({
                target: {
                  value: invoiceDue,
                  name: "invoiceDue",
                },
              })
            }
          />
        </div>
      </div>
      {/* invoice section */}

      <div className="flex flex-row items-end justify-between mt-4">
        <SubTitle name="Order Products" />
        <p className="text-gray ">
          {orderBasicInfo.taxIncluded
            ? "* Amounts are tax inclusive"
            : "* Amounts are tax exclusive"}
        </p>
      </div>
      {formErrors ? (
        <div style={{ margin: "10px 0" }}>
          <TextErrorMessage mess={formErrors} />
        </div>
      ) : (
        <></>
      )}
      <div className={commonStyle.longDataTable}>
        <table className={commonStyle.pageTable}>
          <thead>
            <tr>
              <th>Code</th>
              <th>Product Name</th>
              {/* <th>Attributes</th> */}
              <th>Unit</th>
              <th>Unit Cost</th>
              <th>Discount</th>
              <th>Net Cost</th>
              <th>Quantity</th>
              <th className={purchaseStyle.receiveStockTitle}>
                Received Quantity
              </th>
              <th></th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {orderProducts.map((item, index1) => {
              return (
                <tr key={index1}>
                  <td>
                    {item.variantSku && (
                      <VariantSkuTag name={item.variantSku} />
                    )}
                  </td>
                  <td>
                    {item.productName}
                    {item.variant ? (
                      <span className={purchaseStyle.variantTag}>
                        {item.variant}
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                  {/* <td>{item.variant}</td> */}
                  <td>{item.unitName}</td>

                  <td
                    style={{
                      width: "10%",
                    }}
                  >
                    <PriceText num={item.unitCost} />
                  </td>
                  <td
                    style={{
                      width: "10%",
                    }}
                  >
                    {item.discount}% <FromTag name="OFF" />
                  </td>
                  <td>
                    {item.discount ? (
                      <PriceText
                        num={convtPrice(
                          (1 - Number(item.discount) / 100) *
                            Number(item.unitCost)
                        )}
                      />
                    ) : (
                      <PriceText num={item.unitCost} />
                    )}
                  </td>
                  <td className={purchaseStyle.orderStockCol}>
                    {convtQuantity(item.quantity)}
                  </td>

                  <td className={purchaseStyle.receiveStockCol}>
                    <NoBorderInput
                      type="text"
                      name="quantity"
                      value={item.receivedQuantity}
                      onChange={(receivedQuantity) =>
                        handleReceivedStockChange({
                          type: "quantity",
                          target: {
                            value: receivedQuantity,
                            key: "receivedQuantity",
                            index: index1,
                          },
                        })
                      }
                    />
                  </td>
                  <td>
                    {item.serialized === 1 ? (
                      <SerialNumberCollect
                        btnName="Add Serial Number"
                        prodName={item.productName}
                        requiredQty={Number(item.receivedQuantity)}
                        onSave={getSerialNumbersStockReveive}
                        index={index1}
                        initialNumbers={item.serialNumbers}
                      />
                    ) : (
                      <></>
                    )}

                    {item.batchTracked === 1 ? (
                      <BatchNumberCollect
                        btnName="Add Batch"
                        prodName={item.productName}
                        requiredQty={item.receivedQuantity}
                        onSave={getBatchNumbersStockReveive}
                        index={index1}
                        initialNumbers={item.batchNumbers}
                      />
                    ) : (
                      <></>
                    )}
                  </td>
                  <td
                    style={{
                      width: "10%",
                      maxWidth: "15%",
                    }}
                  >
                    <PriceText num={item.totalCost} />
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="8" className={commonStyle.tableFootName}>
                Total
              </td>

              <td>
                <PriceText num={orderSummary.productsTotal} />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="mt-8 w-full">
        <div className={purchaseStyle.summaryWrapper}>
          <div className={purchaseStyle.costCon}>
            {/* additional cost */}
            <SubTitle name="Additional Cost" />
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Cost Name</th>
                  <th>Cost</th>
                  <th
                    style={{
                      borderLeft: "1px solid var(--border-color)",
                    }}
                  ></th>
                </tr>
              </thead>

              <tbody>
                {addtionalCosts.map((c, index2) => {
                  return (
                    <tr key={index2}>
                      <td
                        className={purchaseStyle.productNameCol}
                        style={{
                          width: "60%",
                        }}
                      >
                        <NoBorderInput
                          type="text"
                          name="costName"
                          value={c.costName}
                          placeHolder="Eg.shipping cost / tax "
                          onChange={(costName) =>
                            handleCostChange({
                              target: {
                                value: costName,
                                key: "costName",
                                index: index2,
                              },
                            })
                          }
                        />
                      </td>

                      <td
                        style={{
                          width: "36%",
                        }}
                      >
                        <NoBorderInput
                          type="text"
                          name="cost"
                          value={c.cost}
                          onChange={(cost) =>
                            handleCostChange({
                              type: "price",
                              target: {
                                value: cost,
                                key: "cost",
                                index: index2,
                              },
                            })
                          }
                        />
                      </td>

                      <td
                        style={{
                          borderLeft: "1px solid var(--border-color)",
                          width: "4%",
                        }}
                      >
                        {" "}
                        <XDeleteButton action={() => deleteCostItem(index2)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td className={commonStyle.tableFootName}>Total</td>

                  <td>
                    {" "}
                    <PriceText num={orderSummary.additionalCostTotal} />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <AddItem name="Add Additional Cost" action={addAdditionalCost} />
            <div className="mt-4">
              <OrderAttachments
                onUploadSuccess={getAttachments}
                initialFiles={orderFiles}
              />
            </div>
          </div>

          <div className={purchaseStyle.summaryCon}>
            {/* summary total price */}
            <SubTitle name="Order Summary" />
            <table
              className={commonStyle.summaryTable}
              style={{ marginTop: "12px" }}
            >
              {orderBasicInfo.taxIncluded ? (
                <tbody>
                  <tr>
                    <td className={commonStyle.tableFootName}>
                      Products Total
                    </td>

                    <td style={{ width: "30%" }}>
                      <PriceText num={orderSummary.productsTotal} />
                    </td>
                  </tr>
                  <tr>
                    <td className={commonStyle.tableFootName}>
                      Additional Cost Total
                    </td>
                    <td style={{ width: "30%" }}>
                      <PriceText num={orderSummary.additionalCostTotal} />
                    </td>
                  </tr>

                  <tr className={commonStyle.summaryTableTotalRow}>
                    <td className={commonStyle.summaryTableTotalName}>
                      Order Total{" "}
                      <span className={commonStyle.taxText}>Tax Incl.</span>
                    </td>

                    <td className={commonStyle.summaryTableTotalValue}>
                      {orderSummary.productsTotal ? (
                        <PriceText
                          num={
                            Number(orderSummary.productsTotal) +
                            Number(orderSummary.additionalCostTotal)
                          }
                        />
                      ) : (
                        "0.00"
                      )}{" "}
                      {orderBasicInfo.currency}
                    </td>
                  </tr>

                  <tr className={commonStyle.summaryTableTotalRow}>
                    <td className={commonStyle.tableFootName}>
                      Total{" "}
                      <span className={commonStyle.taxText}>Tax Excl.</span>
                    </td>

                    <td style={{ width: "30%" }}>
                      {orderBasicInfo.taxrate ? (
                        <PriceText
                          num={
                            (Number(orderSummary.productsTotal) +
                              Number(orderSummary.additionalCostTotal)) /
                            (1 + orderBasicInfo.taxrate / 100)
                          }
                        />
                      ) : (
                        "0.00"
                      )}
                    </td>
                  </tr>

                  <tr className={commonStyle.summaryTableTotalRow}>
                    <td className={commonStyle.tableFootName}>
                      Tax
                      <span className={commonStyle.taxText}>
                        {orderBasicInfo.taxrate
                          ? "-" + orderBasicInfo.taxrate + "%"
                          : ""}
                      </span>
                    </td>

                    <td style={{ width: "30%" }}>
                      {orderBasicInfo.taxrate ? (
                        <PriceText
                          num={
                            ((Number(orderSummary.productsTotal) +
                              Number(orderSummary.additionalCostTotal)) /
                              (1 + orderBasicInfo.taxrate / 100)) *
                            (orderBasicInfo.taxrate / 100)
                          }
                        />
                      ) : (
                        "0.00"
                      )}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td className={commonStyle.tableFootName}>
                      Products Total
                    </td>

                    <td style={{ width: "30%" }}>
                      <PriceText num={orderSummary.productsTotal} />
                    </td>
                  </tr>
                  <tr>
                    <td className={commonStyle.tableFootName}>
                      Additional Cost Total
                    </td>
                    <td style={{ width: "30%" }}>
                      <PriceText num={orderSummary.additionalCostTotal} />
                    </td>
                  </tr>

                  <tr className={commonStyle.summaryTableTotalRow}>
                    <td className={commonStyle.tableFootName}>
                      Total{" "}
                      <span className={commonStyle.taxText}>Tax Excl.</span>
                    </td>

                    <td style={{ width: "30%" }}>
                      {orderBasicInfo.taxrate ? (
                        <PriceText
                          num={
                            Number(orderSummary.productsTotal) +
                            Number(orderSummary.additionalCostTotal)
                          }
                        />
                      ) : (
                        "0.00"
                      )}
                    </td>
                  </tr>

                  <tr className={commonStyle.summaryTableTotalRow}>
                    <td className={commonStyle.tableFootName}>
                      Tax
                      <span className={commonStyle.taxText}>
                        {orderBasicInfo.taxrate
                          ? "-" + orderBasicInfo.taxrate
                          : ""}
                      </span>
                    </td>

                    <td style={{ width: "30%" }}>
                      {orderBasicInfo.taxrate ? (
                        <PriceText
                          num={
                            (Number(orderSummary.productsTotal) +
                              Number(orderSummary.additionalCostTotal)) *
                            (orderBasicInfo.taxrate / 100)
                          }
                        />
                      ) : (
                        "0.00"
                      )}
                    </td>
                  </tr>

                  <tr className={commonStyle.summaryTableTotalRow}>
                    <td className={commonStyle.summaryTableTotalName}>
                      Order Total{" "}
                      <span className={commonStyle.taxText}>Tax Incl.</span>
                    </td>

                    <td className={commonStyle.summaryTableTotalValue}>
                      {orderSummary.productsTotal ? (
                        <PriceText
                          num={
                            (Number(orderSummary.productsTotal) +
                              Number(orderSummary.additionalCostTotal)) *
                            (1 + orderBasicInfo.taxrate / 100)
                          }
                        />
                      ) : (
                        "0.00"
                      )}{" "}
                      {orderBasicInfo.currency}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>

      <div className="w-full mt-4">
        <SimpleBlueButton
          name="Proceed Receive Stock"
          action={() => checkValidation()}
        />
      </div>
    </div>
  );
}
