import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaRegCheckSquare } from "react-icons/fa";
import { MdOutlineDoneAll } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  AddLinkOutlineButton,
  GreenButton,
  SimpleBlueButton,
  SingleRatioButton,
} from "../../../components/editComponents";
import {
  CreateableSelectField,
  NoBorderInput,
  StyledInput,
  StyledSelect,
  StyledTextarea,
} from "../../../components/inputFields";
import {
  ContentModal,
  Loader,
  ProductImage,
  QuestionMark,
  SubTitle,
  VariantTag,
  XeroAccountMappingTip,
  XeroSmallLogo,
} from "../../../components/viewComponents";
import { productTypeOptions } from "../../../constantsData/productType";
import { validatePrice, validateQuantity } from "../../../functions/validate";
import commonStyle from "../../../style/commonStyle.module.css";
import inventoryStyle from "../inventoryStyle.module.css";
import ProductImageUploader from "./ProductImageUploader";
import CategoryForm from "../CategoryForm";
import { Modal } from "../../../components/viewComponents";
import UnitForm from "../UnitForm";
import NewSupplierModal from "../../supplier/NewSupplierModal";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ProductDetailsForm({
  initialBasicInfo,
  onSave,
  btnName,
  type,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const { loading, options, success } = useSelector(
    (state) => state.productOptions
  );
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const [productOptions, setProductOptions] = useState({});

  const [basicInfo, setBasicInfo] = useState(initialBasicInfo);
  const [formErrors, setFormErrors] = useState({});

  const [uploadLoading, setUploadLoading] = useState(false);
  const [newCategoryModal, setNewCategoryModal] = useState(false);
  const [newUnitModal, setNewUnitModal] = useState(false);
  const [newSupplierModal, setNewSupplierModal] = useState(false);

  //////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (Object.keys(options).length) {
      let cate_copy = [...options.category];
      let location_copy = [...options.location];
      let taxrates_copy = [...options.salesTaxRates];
      let suppliers_copy = [...options.suppliers];
      let units_copy = [...options.units];

      let new_options = {
        category: cate_copy,
        location: location_copy,
        salesTaxRates: taxrates_copy,
        suppliers: suppliers_copy,
        units: units_copy,
      };
      setProductOptions(new_options);
    } else {
      setProductOptions({});
    }
  }, [success]);
  //////////////////////////////////////////////////////////////////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    const type = e.type;

    if (type === "QTY") {
      if (validateQuantity(value)) {
        setBasicInfo({ ...basicInfo, [name]: value });
      }
    } else if (type === "FLOAT") {
      if (validatePrice(value)) {
        setBasicInfo({ ...basicInfo, [name]: value });
      }
    } else {
      setBasicInfo({ ...basicInfo, [name]: value });
    }
  };

  const handleSerializedChange = (e) => {
    const { name, value } = e.target;
    if (value) {
      if (name === "serialized") {
        setBasicInfo({ ...basicInfo, [name]: value, batchTracked: !value });
      }

      if (name === "batchTracked") {
        setBasicInfo({ ...basicInfo, [name]: value, serialized: !value });
      }
    } else {
      setBasicInfo({ ...basicInfo, [name]: value });
    }
  };

  const deleteProductImage = () => {
    setBasicInfo({ ...basicInfo, image: "" });
  };

  ///////////////////////////////////////////////////////
  // handle change when upload image
  const handleImageChange = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      // if is edit product, append product id in the file
      formData.append("productId", "0");

      try {
        setUploadLoading(true);
        axios
          .post(SERVER_URL + `/product/image/update`, formData, config)
          .then(async (response) => {
            setUploadLoading(false);
            if (response.data.success) {
              setBasicInfo({ ...basicInfo, image: response.data.data.url });
            } else {
              setFormErrors({
                image: "Update product image failed, please try again later",
              });
            }
          })
          .catch((err) => {
            setUploadLoading(false);
            setFormErrors({
              image: "Update product image failed, please try again later",
            });
          });
      } catch (error) {
        setUploadLoading(false);
        setFormErrors({ image: "Connection error, please try again later" });
      }
    }
  };
  /////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////
  const checkValidation = (isDraft) => {
    const hasErrors = validate(basicInfo);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSave(basicInfo, isDraft);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.type) {
      errors.type = "Product type is required!";
    }
    if (!values.productName) {
      errors.productName = "Product name is required!";
    }
    if (!values.unit) {
      errors.unit = "Unit is required!";
    }
    if (values.sku && values.sku.length > 30) {
      errors.sku = "Product code must not be more than than 30 characters long";
    }
    return errors;
  };

  const addNewCategorySuccess = (newCate) => {
    let optionCopy = { ...productOptions };
    let categoriesCopy = optionCopy.category;
    categoriesCopy.unshift(newCate);
    setProductOptions(optionCopy);
    setBasicInfo({ ...basicInfo, category: newCate });
    setNewCategoryModal(false);
  };
  const addNewUnitSuccess = (newUnit) => {
    let optionCopy = { ...productOptions };
    let unitsCopy = optionCopy.units;
    unitsCopy.unshift(newUnit);
    setProductOptions(optionCopy);
    setBasicInfo({ ...basicInfo, unit: newUnit });
    setNewUnitModal(false);
  };

  const getNewSupplier = (newSupplier) => {
    let optionCopy = { ...productOptions };
    let suppliersCopy = optionCopy.suppliers;
    suppliersCopy.unshift(newSupplier);
    setProductOptions(optionCopy);
    setBasicInfo({ ...basicInfo, supplier: newSupplier });
    setNewSupplierModal(false);
  };

  const XeroManagedStatus = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "12px",
          }}
        >
          <XeroSmallLogo />
          <span style={{ width: "auto", fontWeight: "700" }}>
            Xero Track Inventory Item
          </span>
        </div>
        <p style={{ marginTop: "16px" }}>
          This product will be synchronized with your Xero system as a "Track
          Inventory Item." Any changes to this product's stock levels will be
          updated in your Xero account.
        </p>
      </div>
    );
  };
  const XeroNonManagedStatus = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "12px",
          }}
        >
          <span style={{ width: "auto", fontWeight: "700" }}>
            Xero Non-Track Inventory Item
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        title="New Category"
        isModalOpen={newCategoryModal}
        closeModal={() => setNewCategoryModal(false)}
        content={
          <CategoryForm
            projectId=""
            type="new"
            btnName="Add New Category"
            values={{ name: "" }}
            onSuccess={addNewCategorySuccess}
          />
        }
      />

      <Modal
        title="New Unit"
        isModalOpen={newUnitModal}
        closeModal={() => setNewUnitModal(false)}
        content={
          <UnitForm
            unitId=""
            type="new"
            btnName="Add New Unit"
            values={{ name: "" }}
            onSuccess={addNewUnitSuccess}
          />
        }
      />

      <NewSupplierModal
        title="Create New Supplier"
        isModalOpen={newSupplierModal}
        closeModal={() => setNewSupplierModal(false)}
        onCreateSuccess={getNewSupplier}
      />

      {loading ? (
        <Loader mess="Requesting data..." />
      ) : success ? (
        <div>
          {/* basic info con */}
          <div className={inventoryStyle.basicInfo}>
            <XeroAccountMappingTip pageName="productDetails" />
            <SubTitle name="Product Basic Details" />
            <div className={inventoryStyle.basicInfoCol}>
              <div className={inventoryStyle.col1}>
                {type === "editActive" ? (
                  <StyledInput
                    label="Product Type"
                    type="text"
                    name="type"
                    value={basicInfo.type.label}
                    isReadOnly
                  />
                ) : (
                  <StyledSelect
                    label="Product Type*"
                    placeHolder="Select type"
                    selectOptions={productTypeOptions}
                    value={basicInfo.type}
                    onChange={(type) =>
                      handleChange({
                        target: { value: type, name: "type" },
                      })
                    }
                    clearable={false}
                    error={formErrors.type}
                  />
                )}

                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <CreateableSelectField
                    label="Category"
                    options={productOptions.category}
                    name={"category"}
                    value={basicInfo.category}
                    onChange={(category) =>
                      handleChange({
                        target: { value: category, name: "category" },
                      })
                    }
                    placeholder="Select category"
                    autoFocus={false}
                    readOnly={false}
                    clearable={true}
                    noResultText="No options"
                    addBtnName="Add a new category"
                    openAddNewModal={() => setNewCategoryModal(true)}
                    error={formErrors.category}
                  />
                </div>

                <StyledInput
                  label="Product Name*"
                  type="text"
                  name="productName"
                  autofocus="autofocus"
                  value={basicInfo.productName}
                  onChange={(productName) =>
                    handleChange({
                      target: { value: productName, name: "productName" },
                    })
                  }
                  error={formErrors.productName}
                />
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <CreateableSelectField
                    label="Unit*"
                    options={productOptions.units}
                    name={"unit"}
                    value={basicInfo.unit}
                    onChange={(unit) =>
                      handleChange({
                        target: { value: unit, name: "unit" },
                      })
                    }
                    placeholder="Select unit of measure"
                    autoFocus={false}
                    readOnly={false}
                    clearable={true}
                    noResultText="No options"
                    addBtnName="Add a new unit of measure"
                    openAddNewModal={() => setNewUnitModal(true)}
                    error={formErrors.unit}
                  />
                </div>

                <CreateableSelectField
                  label="Default Supplier"
                  options={productOptions.suppliers}
                  name={"supplier"}
                  value={basicInfo.supplier}
                  onChange={(supplier) =>
                    handleChange({
                      target: { value: supplier, name: "supplier" },
                    })
                  }
                  placeholder="Select supplier"
                  autoFocus={false}
                  readOnly={false}
                  clearable={true}
                  noResultText="No options"
                  addBtnName="Add a new supplier"
                  openAddNewModal={() => setNewSupplierModal(true)}
                  error={formErrors.supplier}
                />

                <StyledTextarea
                  label="Description"
                  type="text"
                  name="description"
                  value={basicInfo.description}
                  onChange={(description) =>
                    handleChange({
                      target: { value: description, name: "description" },
                    })
                  }
                  error={formErrors.description}
                />
                {basicInfo.hasBatches ? (
                  <div>
                    <div className="mt-2 flex items-center">
                      <FaRegCheckSquare className="text-gray-500 w-4 h-4 mr-1" />
                      <span>
                        {basicInfo.serialized
                          ? "Product is serialized"
                          : basicInfo.batchTracked
                          ? "Product is batch-tracked"
                          : "Unknow"}
                      </span>
                    </div>
                    <p className="mt-2 text-gray">
                      The product has existing batch or serial records and
                      cannot be changed to a non-tracked product.
                    </p>
                  </div>
                ) : (
                  <>
                    <SingleRatioButton
                      label="Serialized"
                      name="hasAttrs"
                      isOn={basicInfo.serialized}
                      onChange={(serialized) =>
                        handleSerializedChange({
                          target: { value: serialized, name: "serialized" },
                        })
                      }
                    />

                    <SingleRatioButton
                      label="Batch Tracked"
                      name="hasAttrs"
                      isOn={basicInfo.batchTracked}
                      onChange={(batchTracked) =>
                        handleSerializedChange({
                          target: { value: batchTracked, name: "batchTracked" },
                        })
                      }
                    />
                  </>
                )}
              </div>

              <div className={inventoryStyle.col3}>
                <div className={inventoryStyle.imageWrapper}>
                  <ProductImageUploader
                    imageUri={basicInfo.image}
                    uploadLoading={uploadLoading}
                    uploadError={formErrors.image}
                    onChange={handleImageChange}
                    onDelete={deleteProductImage}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* basic info con end*/}
          {userDetailsSuccess &&
          userDetails.data.orgInfo.xeroConnected &&
          userDetails.data.orgInfo.xeroConfigured ? (
            <div className={inventoryStyle.basicInfo}>
              <SubTitle name="Manage Inventory in Xero" />
              {/* hasStockOnHand = true: mean has stock ops record, can not change xero managed status*/}
              {type === "editActive" && basicInfo.hasStockOnHand ? (
                <>
                  {basicInfo.originalManaged ? (
                    <XeroManagedStatus />
                  ) : (
                    <XeroNonManagedStatus />
                  )}
                </>
              ) : type === "editActive" && !basicInfo.hasStockOnHand ? (
                <>
                  {basicInfo.originalManaged ? (
                    <div>
                      <XeroManagedStatus />
                    </div>
                  ) : (
                    <div>
                      <SingleRatioButton
                        label="Xero Track Inventory Item"
                        name="hasAttrs"
                        isOn={basicInfo.managed}
                        onChange={(managed) =>
                          handleChange({
                            target: { value: managed, name: "managed" },
                          })
                        }
                      />
                      <p style={{ marginTop: "16px" }}>
                        When you select this option, the product will sync with
                        your Xero account as a "Track Inventory Item". Any
                        updates to the stock levels for this product will
                        automatically reflect in Xero.
                      </p>
                      <p style={{ marginTop: "8px" }}>
                        Note: Once a product is marked as "Xero Track Inventory
                        Item" it cannot be reverted to a non-tracked status.
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <div>
                  <SingleRatioButton
                    label="Xero Track Inventory Item"
                    name="hasAttrs"
                    isOn={basicInfo.managed}
                    onChange={(managed) =>
                      handleChange({
                        target: { value: managed, name: "managed" },
                      })
                    }
                  />
                  <p style={{ marginTop: "16px" }}>
                    When you select this option, the product will sync with your
                    Xero account as a "Track Inventory Item". Any updates to the
                    stock levels for this product will automatically reflect in
                    Xero.
                  </p>
                  <p style={{ marginTop: "8px" }}>
                    Note: Once a product is marked as "Xero Track Inventory
                    Item" it cannot be reverted to a non-tracked status.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          {/************ Attrs ***********************/}

          <div className={inventoryStyle.basicInfo}>
            <SubTitle name="Product Transaction Details" />

            <div>
              <table className={inventoryStyle.dynTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Barcode</th>
                    <th>Re-order Level</th>
                    <th>Sale Price</th>
                    <th>
                      <QuestionMark
                        title="Latest Cost"
                        text="The latest tax exclusive cost of this product, it is not a fixed value, will be automatically updated when new stock added into system."
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "15%" }}>
                      <NoBorderInput
                        type="text"
                        name="code"
                        placeHolder={"-Auto-"}
                        value={basicInfo.sku}
                        isReadOnly={type === "editActive" ? true : false}
                        onChange={(sku) =>
                          handleChange({
                            type: "TEXT",
                            target: {
                              value: sku,
                              name: "sku",
                            },
                          })
                        }
                        error={formErrors.sku}
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <NoBorderInput
                        type="text"
                        name="barcode"
                        value={basicInfo.barcode}
                        onChange={(barcode) =>
                          handleChange({
                            type: "TEXT",
                            target: {
                              value: barcode,
                              name: "barcode",
                            },
                          })
                        }
                      />
                    </td>
                    <td style={{ width: "15%" }}>
                      <NoBorderInput
                        type="text"
                        name="salePrice"
                        value={basicInfo.reorderLevel}
                        onChange={(reorderLevel) =>
                          handleChange({
                            type: "QTY",
                            target: {
                              value: reorderLevel,
                              name: "reorderLevel",
                            },
                          })
                        }
                      />
                    </td>
                    <td style={{ width: "15%" }}>
                      <NoBorderInput
                        type="text"
                        name="salePrice"
                        value={basicInfo.salePrice}
                        onChange={(salePrice) =>
                          handleChange({
                            type: "FLOAT",
                            target: {
                              value: salePrice,
                              name: "salePrice",
                            },
                          })
                        }
                      />
                    </td>

                    <td style={{ width: "15%" }}>
                      <NoBorderInput
                        type="text"
                        name="latestCost"
                        value={basicInfo.latestCost}
                        onChange={(latestCost) =>
                          handleChange({
                            type: "FLOAT",
                            target: {
                              value: latestCost,
                              name: "latestCost",
                            },
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={commonStyle.btnWrapper}>
        {/************  ***********************/}

        {type === "editActive" ? (
          <div className="w-1/2 my-4">
            <SimpleBlueButton
              name="Update Product"
              action={() => checkValidation(false)}
            />
          </div>
        ) : type === "modalNew" ? (
          <div className="w-1/2 my-4">
            <SimpleBlueButton
              name="Create Product"
              action={() => checkValidation(false)}
            />
          </div>
        ) : (
          <>
            <div className="w-full grid grid-cols-2 gap-4">
              <GreenButton
                name="Save as Draft"
                action={() => checkValidation(true)}
              />

              <SimpleBlueButton
                name="Save as Approved"
                action={() => checkValidation(false)}
              />
            </div>
            {type !== "modalNew" && (
              <p className={commonStyle.saveHintText}>
                *** Draft status products can not be sold or purchased.***
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}
