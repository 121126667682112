import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import commonStyle from "../../style/commonStyle.module.css";
import Breadcrumbs from "../../components/Breadcrumbs";
import { EditButtonLink, ScreenTitle } from "../../components/editComponents";
import {
  ViewText,
  ErrorMessage,
  Loader,
  SubTitle,
  TipsText,
  ViewLabel,
} from "../../components/viewComponents";
import { customerDetailsRequest } from "../../actions/customerActions";
import salesStyle from "../sale/salesStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function PreviewCustomerScreen() {
  useDocumentTitle("Preview Customer");
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customerDetailsRequest(id));
  }, [dispatch, id]);

  const { loading, customerInfo, success, error } = useSelector(
    (state) => state.customerDetails
  );

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview Customer" />
      <ScreenTitle title="Preview Customer" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting customer details, please wait a moment..." />
        ) : success ? (
          <div className={salesStyle.previewCon}>
            <SubTitle name="Customer Information" />
            <div className="flex justify-end">
              <EditButtonLink path={`/customer/edit/${id}`} />
            </div>
            <div style={{ display: "flex" }}>
              <div className={salesStyle.col1}>
                <ViewText
                  title="Customer Name"
                  value={
                    customerInfo.firstName || customerInfo.lastName
                      ? customerInfo.firstName + " " + customerInfo.lastName
                      : "null"
                  }
                />

                <ViewText
                  title="Customer Company Name (Optional)"
                  value={customerInfo.name ? customerInfo.name : "null"}
                />
                <ViewText
                  title="Email"
                  value={customerInfo.email ? customerInfo.email : "null"}
                />
                <ViewText
                  title="Phone"
                  value={customerInfo.phone ? customerInfo.phone : "null"}
                />
                <ViewText
                  title="Defalut Location"
                  value={
                    customerInfo.locationName
                      ? customerInfo.locationName
                      : "null"
                  }
                />
              </div>
              <div className={salesStyle.col2}>
                <ViewText
                  title="Country"
                  value={
                    customerInfo.country ? customerInfo.country : "null"
                  }
                />
                <ViewText
                  title="Currency"
                  value={
                    customerInfo.currency ? customerInfo.currency : null
                  }
                />
                <ViewText
                  title="Tax Rate"
                  value={customerInfo.taxRate ? customerInfo.taxRate : "null"}
                />
                <ViewText
                  title="Price Tier"
                  value={
                    customerInfo.priceTierName
                      ? customerInfo.priceTierName
                      : null
                  }
                />

                <ViewText
                  title="Payment Term"
                  value={
                    customerInfo.paymentTerm
                      ? customerInfo.paymentTerm + " days after invoice date"
                      : null
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className={salesStyle.col1}>
                <div style={{ width: "50%", marginRight: "20px" }}>
                  <ViewLabel title="Postal Address" />
                  <TipsText
                    text={
                      customerInfo.postalAddress.country
                        ? customerInfo.postalAddress.country.value
                        : ""
                    }
                  />
                  <TipsText text={customerInfo.postalAddress.region} />
                  <TipsText text={customerInfo.postalAddress.city} />
                  <TipsText text={customerInfo.postalAddress.addressLine} />
                  <TipsText text={customerInfo.postalAddress.postalCode} />
                </div>
              </div>
              <div className={salesStyle.col2}>
                <div style={{ width: "50%", marginRight: "20px" }}>
                  <ViewLabel title="Billing Address" />
                  <TipsText
                    text={
                      customerInfo.billingAddress.country
                        ? customerInfo.billingAddress.country.value
                        : ""
                    }
                  />
                  <TipsText text={customerInfo.billingAddress.region} />
                  <TipsText text={customerInfo.billingAddress.city} />
                  <TipsText text={customerInfo.billingAddress.addressLine} />
                  <TipsText text={customerInfo.billingAddress.postalCode} />
                </div>
              </div>
            </div>

            <ViewText
              title="Notes"
              value={customerInfo.notes ? customerInfo.notes : "null"}
            />
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
