import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  LoadingButton,
  RefreshButton,
  ScreenTitle,
  SimpleBlueButton,
} from "../../../components/editComponents";
import { ImportErrors, SimpleDialog } from "../../../components/viewComponents";
import ProductIOTabs from "./ProductIOTabs";
import commonStyle from "../../../style/commonStyle.module.css";
import UploadFileField from "../../../components/UploadFileField";
import ioStyle from "../../io/ioStyle.module.css";
import {
  importCsvRequest,
  importExportLogsRequest,
} from "../../../actions/IOActions";
import useDocumentTitle from "../../../useDocumentTitle";
import {
  ErrorMessage,
  Loader,
  Tag,
  ViewDateWithTime,
} from "../../../components/viewComponents";
import { FaFileCsv } from "react-icons/fa";
import { CsvWriter } from "../../reporting/CsvWriter.js";
import { unBatchedDataRequest } from "../../../api/productServices.js";

export default function ImportBatchSerialScreen() {
  useDocumentTitle("Import Batch/Serial Numbers");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const {
    loading: logsLoading,
    logsList,
    error: logsError,
    success: logsSuccess,
  } = useSelector((state) => state.IOLogs);

  ////////////////////////////////////////////////////////////////////////////////////////////

  const { loading, success, error, showBtn } = useSelector(
    (state) => state.importCsv
  );

  ////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////
  const [importDialog, setImportDialog] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const uploadProductsFile = (file) => {
    const formData = new FormData();
    formData.append("csv", file);
    dispatch(importCsvRequest(formData, "batches"));
    setImportDialog(true);
    // setShowUploadBtn(false);
  };

  useEffect(() => {
    dispatch(importExportLogsRequest("batches", "IMPORT"));
  }, [dispatch, success]);

  const refreshData = () => {
    dispatch(importExportLogsRequest("batches", "IMPORT"));
  };

  const downloadFile = async () => {
    let list = [];

    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await unBatchedDataRequest(config);

      if (responseData.data.success) {
        list = responseData.data.data;

        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }

    //get csv file
    let csvWriter = new CsvWriter([
      "Code*",
      "Product Name",
      "Serialized(Y/N)",
      "Batch-tracked(Y/N)",
      "Location*",
      "Stock On Hand",
      "Batch/Serial Qty",
      "Batch/Serial Required Qty",
      "Batch/Serial Number*",
      "Batch/Serial Qty*",
      "Expire Date(dd/mm/yyyy)",
    ]);

    if (list.length) {
      for (let line of list) {
        csvWriter.addLine([
          line.sku,
          line.name,
          line.serialized === 1 ? "Y" : "N",
          line.batchTracked === 1 ? "Y" : "N",
          line.locationName,
          line.stockLevel,
          line.numQty,
          line.unbatchedQty,
          "",
          "",
          "",
        ]);
      }
      const csvString = csvWriter.content;

      // create a download link
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          "import-batch-serial-numbers-template.csv"
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Upload CSV"
        successMessage="CSV file uploaded successfully. The data is currently being processed and saved to the database. You can check the results in below."
        isDialogOpen={importDialog}
        closeDialog={() => setImportDialog(false)}
        loading={loading}
        loadingMessage="Uploading file..."
        success={success}
        error={error}
        confirmAction={() => setImportDialog(false)}
      />
      <Breadcrumbs screenName={"Import Batch/Serial Numbers"} />
      <ScreenTitle title={"Import Batch/Serial Numbers"} />

      <div className={commonStyle.pageContentContainer}>
        <div>
          <div className={ioStyle.importTips}>
            <p>
              1. Please click the button below to download products with enabled
              batch/serialized tracking where stock quantity differs from
              batch/serial numbers. Use the template to import batch/serial
              numbers for these products.
            </p>
          </div>
          <div className="w-fit">
            {downloadStatus.loading ? (
              <LoadingButton name="Downloading.." />
            ) : (
              <SimpleBlueButton
                name="Download Import Template"
                action={downloadFile}
              />
            )}
          </div>

          <div className={ioStyle.importTips}>
            <p>
              2.Drag and drop your CSV file into the area below to upload, or
              click anywhere in the area to select your CSV file for upload.
            </p>
          </div>
          <div className={ioStyle.importTips}>
            <p>(Only .csv format files are supported.)</p>
          </div>

          <UploadFileField
            requiredFileType={"csv"}
            onSubmit={uploadProductsFile}
            showBtn={showBtn ? showBtn : true}
            btnName={"Start to Import Data"}
            uploadFileSuccess={success}
          />

          <div
            style={{
              marginTop: "40px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2>Import Tasks</h2>
            <RefreshButton name="Refresh Task Status" action={refreshData} />
          </div>
          {logsLoading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : logsSuccess ? (
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  {/* <th></th> */}
                  <th>Date</th>
                  <th>File</th>
                  <th>Operator</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {logsList.length > 0 ? (
                  logsList.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <ViewDateWithTime value={item.atime} />
                      </td>
                      <td>
                        <a
                          href={item.inputFileUrl}
                          download={item.inputFileUrl}
                          rel="noreferrer"
                          className={ioStyle.fileName}
                        >
                          <FaFileCsv className={ioStyle.csvLogo} />
                          <p>{item.inputFile}</p>
                        </a>
                      </td>
                      <td>
                        {item.operatorFirstName} {item.operatorLastName}{" "}
                      </td>
                      <td>
                        {item.status === "QUEUED" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "PROCESSING" ? (
                          <Tag name={item.status} color="green" />
                        ) : item.status === "SUCCESS" ||
                          item.status === "PARTIAL SUCCESS" ? (
                          <Tag name={item.status} color="blue" />
                        ) : item.status === "FAILED" ? (
                          <Tag name={item.status} color="red" />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {item.errors ? (
                          <div>
                            {Number(item.successCnt) > 0 ? (
                              <p>
                                Success imported quantity: {item.successCnt}
                              </p>
                            ) : (
                              <></>
                            )}
                            {Number(item.errorCnt) > 0 ? (
                              <p>Failed quantity: {item.errorCnt}</p>
                            ) : (
                              <></>
                            )}
                            <div className="mt-2">
                              <p>{item.errors}</p>
                              {item.errorDetails &&
                              item.errorDetails.length > 0 ? (
                                <div className="pl-4">
                                  <ImportErrors errors={item.errorDetails} />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}{" "}
                        <a
                          href={item.logFileUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="mt-2 block text-brandColor hover:cursor-pointer hover:underline "
                        >
                          {" "}
                          Import logs
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no import records.</td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : logsError ? (
            <ErrorMessage mess={logsError} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
