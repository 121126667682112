import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// get package list

export const stocktakeReportRequest = async (
  category,
  location,
  config
) => {
  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/stocktake-list?page_num=0&page_size=9999999999&category=${category}&location=${location}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const saleSummaryReportRequest = async (
  page_num,
  page_size,
  locationId,
  salePersonId,
  customerId,
  config,
  startTime=null,
  endTime=null,
) => {
  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/sale/summary?page_num=${page_num}&page_size=${page_size}&locationId=${locationId}&salePersonId=${salePersonId}&customerId=${customerId}&startTime=${startTime}&endTime=${endTime}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const stockAlertReportRequest = async (
  pageNum,
  pageSize,
  category,
  location,
  config
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/stock-alert?page_num=${pageNum}&page_size=${pageSize}&category=${category}&location=${location}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const productListReportRequest = async (
  pageNum,
  pageSize,
  category,
  config
) => {
  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/product-list?page_num=${pageNum}&page_size=${pageSize}&category=${category}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const serialBatchNumbersReportFilterOptionsRequest = async (
  config
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/serial-batch-numbers/filter-options`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const serialBatchNumbersReportRequest = async (
  pageNum,
  pageSize,
  category,
  location,
  product,
  config
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/serial-batch-numbers?page_num=${pageNum}&page_size=${pageSize}&category=${category}&location=${location}&product=${product}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const stockAdjustmentReportRequest = async (
  pageNum,
  pageSize,
  category,
  location,
  startTime,
  endTime,
  filterKeyword,
  config
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/stock-adjust?page_num=${pageNum}&page_size=${pageSize}&notes=${filterKeyword}&category=${category}&location=${location}&startTime=${startTime}&endTime=${endTime}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const stockTransferReportRequest = async (
  pageNum,
  pageSize,
  category,
  location,
  startTime,
  endTime,
  filterKeyword,
  config
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/stock-transfer?page_num=${pageNum}&page_size=${pageSize}&notes=${filterKeyword}&category=${category}&location=${location}&startTime=${startTime}&endTime=${endTime}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const productsMovementReportRequest = async (
  keyWords,
  pageNum,
  pageSize,
  locationId,
  startTimestamp,
  endTimestamp,
  config
) => {
  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/products-movement?keyword=${keyWords}&page_num=${pageNum}&page_size=${pageSize}&locationId=${locationId}&startTime=${startTimestamp}&endTime=${endTimestamp}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


export const salesByProductReportRequest = async (
  pageNum,
  pageSize,
  category,
  location,
  startTime,
  endTime,
  config
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/sale/summary-by-product?page_num=${pageNum}&page_size=${pageSize}&category=${category}&location=${location}&startTime=${startTime}&endTime=${endTime}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const purchaseSummaryReportOptionsRequest = async (
  config,
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/purchase/filter-options`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const purchaseSummaryReportRequest = async (
  page_num,
  page_size,
  locationId,
  creatorId,
  supplierId,
  config,
  startTime=null,
  endTime=null,
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/purchase/summary?page_num=${page_num}&page_size=${page_size}&locationId=${locationId}&creatorId=${creatorId}&supplierId=${supplierId}&startTime=${startTime}&endTime=${endTime}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const productsByProjectReportFilterOptionRequest = async (
  config
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/project/filter-options`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const productsByProjectReportRequest = async (
  pageNum,
  pageSize,
  projectId,
  config
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/project/products-summary/${projectId}?page_num=${pageNum}&page_size=${pageSize}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const productsTransactionByProjectReportRequest = async (
  pageNum,
  pageSize,
  projectId,
  locationIds,
  startTimestamp,
  endTimestamp,
  config
) => {

  try {
    const response = await axios.get(
      SERVER_URL +
        `/reporting/project/products-transaction?page_num=${pageNum}&page_size=${pageSize}&projectId=${projectId}&location=${locationIds}&startTime=${startTimestamp}&endTime=${endTimestamp}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getLocationOptionsRequest = async (config) => {
  try {
    const response = await axios.get(
      SERVER_URL +
        `/org/location/list`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

