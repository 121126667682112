import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  AiFillEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import { IoIosMore } from "react-icons/io";
import { MdFileCopy } from "react-icons/md";
import {
  CloneButtonLink,
  EditButtonLink,
  ReceiveStockButton,
  ViewButtonLink,
  DeleteButton,
} from "../../../components/editComponents";
import { DeleteDialog } from "../../../components/viewComponents";
import { deletePurchaseOrderRequest } from "../../../api/purchaseOrderServices";

function EditLink({ orderId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("new_purchase_order") ? (
    <>
      {buttonView ? (
        <NavLink
          to={`/purchase/order/edit/${orderId}`}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandGreen rounded border border-solid border-brandGreen hover:bg-brandGreen hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <AiFillEdit className="w-4 h-4 mr-2" />Edit
        </NavLink>
      ) : (
        <EditButtonLink path={`/purchase/order/edit/${orderId}`} />
      )}
    </>
  ) : (
    <></>
  );
}


function CloneLink({ orderId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("new_purchase_order") ? (
    <>
      {buttonView ? (
        <NavLink
          to={`/purchase/order/new`}
          state={{ orderId: orderId }}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandYellow rounded border border-solid border-brandYellow hover:bg-brandYellow hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <MdFileCopy className="w-4 h-4 mr-2" /> Clone
        </NavLink>
      ) : (
        <CloneButtonLink
          path={`/purchase/order/new`}
          data={{ orderId: orderId }}
        />
      )}
    </>
  ) : (
    <></>
  );
}

function PreviewLink({ orderId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("new_purchase_order") ? (
    <ViewButtonLink path={`/purchase/order/preview/${orderId}`} />
  ) : (
    <></>
  );
}

function ReceiveStockLink({
  orderId,
  buttonView = false,
  marginRight = false,
}) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("receive_stock") ? (
    <>
      {buttonView ? (
        <NavLink
          to={`/purchase/order/receive-stock/${orderId}`}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandGreen rounded border border-solid border-brandGreen hover:bg-brandGreen hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <MdFileCopy className="w-4 h-4 mr-2" /> Receive Stock
        </NavLink>
      ) : (
        <NavLink to={`/purchase/order/receive-stock/${orderId}`}>
          <ReceiveStockButton />
        </NavLink>
      )}
    </>
  ) : (
    <></>
  );
}

function DeleteOrderButton({
  orderId,
  deleteSuccess,
  buttonView = false,
  marginRight = false,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const openDeleteDialog = () => {
    setDeleteDialog(true);
  };

  const sendDeleteRequest = async () => {
    try {
      setDeleteStatus({
        ...deleteStatus,
        loading: true,
        success: false,
        error: "",
      });
      const responseData = await deletePurchaseOrderRequest(orderId, config);

      if (responseData.success) {
        setDeleteStatus({
          ...deleteStatus,
          loading: false,
          success: true,
        });
      } else {
        throw "Delete purchase order failed.";
      }
    } catch (error) {
      setDeleteStatus({
        ...deleteStatus,
        loading: false,
        error: error,
      });
    }
  };

  const closeDeleteDialogWithSuccess = () => {
    deleteSuccess();
    setDeleteDialog(false);
    setDeleteStatus({
      loading: false,
      success: false,
      error: "",
    });
  };

  return (
    <div>
      <DeleteDialog
        title="Delete Purchase Order"
        preDeleteMess={`Please note that the delete action can not be undone. Are you sure you want to proceed?`}
        successMessage="Purchase order deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={closeDeleteDialogWithSuccess}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      {userDetailsSuccess &&
      userDetails.data.permissions.includes("new_purchase_order") ? (
       
        <>
        {buttonView ? (
          <button
            onClick={openDeleteDialog}
            className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-red-500 rounded border border-solid border-red-500 hover:bg-red-500 hover:text-white ${
              marginRight ? "mr-2" : ""
            }`}
          >
            <AiOutlineDelete className="w-4 h-4 mr-2"/> Delete
          </button>
        ) : (
          <DeleteButton action={openDeleteDialog} />
        )}
      </>

      ) : (
        <></>
      )}
    </div>
  );
}

function MoreActionLink({orderId}) {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <div className="relative">
      <div
        className="h-11 w-11 rounded flex items-center justify-center rounded border border-solid border-darkGray hover:bg-brandColor hover:text-white hover:cursor-pointer hover:border-brandColor"
        onClick={() => setFilterOpen(!filterOpen)}
      >
        <IoIosMore className="w-4 h-4" />
      </div>

      <div
        className={`${
          filterOpen ? "absolute block top-14 right-0 z-20 " : "hidden"
        }  min-w-[140px] bg-white shadow-lg p-4 rounded`}
      >
        <div className="w-full">
        <NavLink
          to={`/purchase/order/after-receive-edit/${orderId}`}
          className={`w-full h-8 flex flex-row items-center justify-start hover:underline hover:text-brandColor`}
        >
          <AiFillEdit className="w-4 h-4 mr-2" />Edit
        </NavLink>
        </div>
      </div>
    </div>
  );
}


export {
  EditLink,
  CloneLink,
  PreviewLink,
  ReceiveStockLink,
  DeleteOrderButton,
  MoreActionLink
};
