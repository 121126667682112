
export const PURCHASE_ORDER_OPTIONS_REQUEST = "PURCHASE_ORDER_OPTIONS_REQUEST";
export const PURCHASE_ORDER_OPTIONS_SUCCESS = "PURCHASE_ORDER_OPTIONS_SUCCESS";
export const PURCHASE_ORDER_OPTIONS_FAIL = "PURCHASE_ORDER_OPTIONS_FAIL";

export const SEARCH_PURCHASE_PRODUCT_REQUEST = "SEARCH_PURCHASE_PRODUCT_REQUEST";
export const SEARCH_PURCHASE_PRODUCT_SUCCESS = "SEARCH_PURCHASE_PRODUCT_SUCCESS";
export const SEARCH_PURCHASE_PRODUCT_FAIL = "SEARCH_PURCHASE_PRODUCT_FAIL";

export const PURCHASE_ORDER_LIST_REQUEST = "PURCHASE_ORDER_LIST_REQUEST";
export const PURCHASE_ORDER_LIST_SUCCESS = "PURCHASE_ORDER_LIST_SUCCESS";
export const PURCHASE_ORDER_LIST_FAIL = "PURCHASE_ORDER_LIST_FAIL";

export const PURCHASE_ORDER_DETAILS_REQUEST = "PURCHASE_ORDER_DETAILS_REQUEST";
export const PURCHASE_ORDER_DETAILS_SUCCESS = "PURCHASE_ORDER_DETAILS_SUCCESS";
export const PURCHASE_ORDER_DETAILS_FAIL = "PURCHASE_ORDER_DETAILS_FAIL";

export const PURCHASE_ORDER_ACTIONS_REQUEST = "PURCHASE_ORDER_ACTIONS_REQUEST";
export const PURCHASE_ORDER_ACTIONS_SUCCESS = "PURCHASE_ORDER_ACTIONS_SUCCESS";
export const PURCHASE_ORDER_ACTIONS_FAIL = "PURCHASE_ORDER_ACTIONS_FAIL";

export const PURCHASE_ORDER_CREATE_REQUEST = "PURCHASE_ORDER_CREATE_REQUEST";
export const PURCHASE_ORDER_CREATE_SUCCESS = "PURCHASE_ORDER_CREATE_SUCCESS";
export const PURCHASE_ORDER_CREATE_FAIL = "PURCHASE_ORDER_CREATE_FAIL";

export const PURCHASE_ORDER_EDIT_REQUEST = "PURCHASE_ORDER_EDIT_REQUEST";
export const PURCHASE_ORDER_EDIT_SUCCESS = "PURCHASE_ORDER_EDIT_SUCCESS";
export const PURCHASE_ORDER_EDIT_FAIL = "PURCHASE_ORDER_EDIT_FAIL";

export const RECEIVE_STOCK_REQUEST = "RECEIVE_STOCK_REQUEST";
export const RECEIVE_STOCK_SUCCESS = "RECEIVE_STOCK_SUCCESS";
export const RECEIVE_STOCK_FAIL = "RECEIVE_STOCK_FAIL";

export const LATEST_PRODUCT_PURCHASE_DETAILS_REQUEST = "LATEST_PRODUCT_PURCHASE_DETAILS_REQUEST";
export const LATEST_PRODUCT_PURCHASE_DETAILS_SUCCESS = "LATEST_PRODUCT_PURCHASE_DETAILS_SUCCESS";
export const LATEST_PRODUCT_PURCHASE_DETAILS_FAIL = "LATEST_PRODUCT_PURCHASE_DETAILS_FAIL";

export const PURCHASE_ORDER_EDIT_AFTER_RECEIVE_REQUEST = "PURCHASE_ORDER_EDIT_AFTER_RECEIVE_REQUEST";
export const PURCHASE_ORDER_EDIT_AFTER_RECEIVE_SUCCESS = "PURCHASE_ORDER_EDIT_AFTER_RECEIVE_SUCCESS";
export const PURCHASE_ORDER_EDIT_AFTER_RECEIVE_FAIL = "PURCHASE_ORDER_EDIT_AFTER_RECEIVE_FAIL";