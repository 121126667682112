import { IMPORT_XERO_DATA_TASK_LIST_FAIL, IMPORT_XERO_DATA_TASK_LIST_REQUEST, IMPORT_XERO_DATA_TASK_LIST_SUCCESS, XERO_TASK_LIST_FAIL, XERO_TASK_LIST_REQUEST, XERO_TASK_LIST_SUCCESS } from "../constants/xeroConstants";


export const xeroTaskListReducer = (state = { xeroTasks: [] }, action) => {
  switch (action.type) {
    case XERO_TASK_LIST_REQUEST:
      return { loading: true, success: false };
    case XERO_TASK_LIST_SUCCESS:
      return { loading: false, xeroTasks: action.payload, success: true };
    case XERO_TASK_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const importXeroDataTaskListReducer = (state = { xeroImportTasks: {} }, action) => {
  switch (action.type) {
    case IMPORT_XERO_DATA_TASK_LIST_REQUEST:
      return { loading: true, success: false };
    case IMPORT_XERO_DATA_TASK_LIST_SUCCESS:
      return { loading: false, xeroImportTasks: action.payload, success: true };
    case IMPORT_XERO_DATA_TASK_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
