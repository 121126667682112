import React, { useState } from "react";
import { XeroButton } from "../../../components/editComponents";
import { FullHeightModal } from "../../../components/viewComponents";
import ImportXeroCustomersForm from "./ImportXeroCustomersForm";


export default function ImportXeroCustomers({onTaskSuccess}) {

  const [impotCustomersModal, setImportCustomersModal] = useState(false);

  const syncCustomersFromXero = () => {
    setImportCustomersModal(true);
  };

  const closeImportModalWithSuccess = () => {
    setImportCustomersModal(false);
    onTaskSuccess();
  };

  return (
    <>
      <FullHeightModal
        title="Import Customers from Xero"
        isModalOpen={impotCustomersModal}
        closeModal={() => setImportCustomersModal(false)}
        content={
          <ImportXeroCustomersForm
            importSuccess={closeImportModalWithSuccess}
          />
        }
      />

      <XeroButton
        name="Import Customers from Xero"
        action={syncCustomersFromXero}
      />
    </>
  );
}
