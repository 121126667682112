import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import commonStyle from "../../style/commonStyle.module.css";
import Breadcrumbs from "../../components/Breadcrumbs";
import { EditButtonLink, ScreenTitle } from "../../components/editComponents";
import {
  ViewText,
  ErrorMessage,
  Loader,
  SubTitle,
  ViewLabel,
  TipsText,
} from "../../components/viewComponents";
import { supplierDetailsRequest } from "../../actions/supplierActions";
import purchaseStyle from "../purchase/purchaseStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function PreviewSupplier() {
  useDocumentTitle("Preview Supplier");
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(supplierDetailsRequest(id));
  }, [dispatch, id]);

  const { loading, supplierInfo, error, success } = useSelector(
    (state) => state.supplierDetails
  );

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview Supplier" />
      <ScreenTitle title="Preview Supplier" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting supplier details, please wait a moment..." />
        ) : success ? (
          <div className={purchaseStyle.supplierPreviewCon}>
            <SubTitle name="Supplier Information" />
            <div className="flex justify-end">
              <EditButtonLink path={`/supplier/edit/${id}`} />
            </div>
            <div style={{ display: "flex" }}>
              <div className={purchaseStyle.col1}>
                <ViewText
                  title="Supplier Name"
                  value={supplierInfo.name ? supplierInfo.name : "null"}
                />
                <ViewText
                  title="Contact Name"
                  value={
                    supplierInfo.firstName || supplierInfo.lastName
                      ? supplierInfo.firstName + " " + supplierInfo.lastName
                      : "null"
                  }
                />
                <ViewText
                  title="Contact Email"
                  value={supplierInfo.email ? supplierInfo.email : "null"}
                />
                <ViewText
                  title="Contact Phone"
                  value={supplierInfo.phone ? supplierInfo.phone : "null"}
                />
              </div>
              <div className={purchaseStyle.col2}>
                <ViewText
                  title="Country"
                  value={
                    supplierInfo.country ? supplierInfo.country : "null"
                  }
                />
                <ViewText
                  title="Currency"
                  value={
                    supplierInfo.currency ? supplierInfo.currency : "null"
                  }
                />
                <ViewText
                  title="Tax Rate"
                  value={supplierInfo.taxRate ? supplierInfo.taxRate : "null"}
                />
              </div>
            </div>

            <SubTitle name="Contact Information" />
            <div style={{ display: "flex" }}>
              <div className={purchaseStyle.col1}>
                <div style={{ width: "50%", marginRight: "20px" }}>
                  <ViewLabel title="Postal Address" />
                  <TipsText
                    text={
                      supplierInfo.postalAddress.country
                        ? supplierInfo.postalAddress.country.value
                        : ""
                    }
                  />
                  <TipsText text={supplierInfo.postalAddress.region} />
                  <TipsText text={supplierInfo.postalAddress.city} />
                  <TipsText text={supplierInfo.postalAddress.addressLine} />
                  <TipsText text={supplierInfo.postalAddress.postalCode} />
                </div>
              </div>
              <div className={purchaseStyle.col2}>
                <div style={{ width: "50%", marginRight: "20px" }}>
                  <ViewLabel title="Billing Address" />
                  <TipsText
                    text={
                      supplierInfo.billingAddress.country
                        ? supplierInfo.billingAddress.country.value
                        : ""
                    }
                  />
                  <TipsText text={supplierInfo.billingAddress.region} />
                  <TipsText text={supplierInfo.billingAddress.city} />
                  <TipsText text={supplierInfo.billingAddress.addressLine} />
                  <TipsText text={supplierInfo.billingAddress.postalCode} />
                </div>
              </div>
            </div>

            <ViewText
              title="Notes"
              value={supplierInfo.notes ? supplierInfo.notes : "null"}
            />
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
