import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import {
  SALE_ORDER_ACTIONS_FAIL,
  SALE_ORDER_ACTIONS_REQUEST,
  SALE_ORDER_ACTIONS_SUCCESS,
  SALE_ORDER_CREATE_FAIL,
  SALE_ORDER_CREATE_REQUEST,
  SALE_ORDER_CREATE_SUCCESS,
  SALE_ORDER_DETAILS_FAIL,
  SALE_ORDER_DETAILS_REQUEST,
  SALE_ORDER_DETAILS_SUCCESS,
  SALE_ORDER_EDIT_AFTER_APPROVE_FAIL,
  SALE_ORDER_EDIT_AFTER_APPROVE_REQUEST,
  SALE_ORDER_EDIT_AFTER_APPROVE_SUCCESS,
  SALE_ORDER_EDIT_FAIL,
  SALE_ORDER_EDIT_REQUEST,
  SALE_ORDER_EDIT_SUCCESS,
  SALE_ORDER_LIST_FAIL,
  SALE_ORDER_LIST_REQUEST,
  SALE_ORDER_LIST_SUCCESS,
  SALE_ORDER_OPTIONS_FAIL,
  SALE_ORDER_OPTIONS_REQUEST,
  SALE_ORDER_OPTIONS_SUCCESS,
  SHIP_SALE_ORDER_FAIL,
  SHIP_SALE_ORDER_REQUEST,
  SHIP_SALE_ORDER_SUCCESS,
} from "../constants/saleOrderConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const saleOrderOptionsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   
    dispatch({ type: SALE_ORDER_OPTIONS_REQUEST });
    axios
      .get(SERVER_URL + `/sale_order/options`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: SALE_ORDER_OPTIONS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request sale order options failed, please try again later."),
            { code: 301 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: SALE_ORDER_OPTIONS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: SALE_ORDER_OPTIONS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};


export const createNewSaleOrderRequest =
  (values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    try {
     

      dispatch({ type: SALE_ORDER_CREATE_REQUEST });
      axios
        .post(SERVER_URL + `/sale_order/create`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: SALE_ORDER_CREATE_SUCCESS, payload: response.data.data });
          } else if (response.data.error === 40) {
            //console.log(response.data.error);
            throw Object.assign(
              new Error(
                "Order quantity must be greater than zero, please change the order quantity."
              ),
              { code: 303 }
            );
          } else if (response.data.error === 42) {
            throw Object.assign(
              new Error(
                "Product stock not enough"
              ),
              { code: 323 }
            );
          } else if (response.data.error === 23) {
            throw Object.assign(
              new Error(
                response.data.message
              ),
            );
          } else if (response.data.error === 26) {
            throw Object.assign(
              new Error(
                response.data.message
              ),
            );
          } else {
            throw Object.assign(
              new Error(
                "Create new sale order failed, please try again later."
              ),
              { code: 304 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: SALE_ORDER_CREATE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SALE_ORDER_CREATE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

// get purchase order list
export const saleOrderListRequest =
  (keywords, pageNum, pageSize, range) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     
      dispatch({ type: SALE_ORDER_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/sale_order/list?keyword=${keywords}&page_num=${pageNum}&page_size=${pageSize}&range=${range}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: SALE_ORDER_LIST_SUCCESS,
              payload: {
                orders: response.data.data.sale_orders,
                totalPages: response.data.data.total_pages,
                hasSuInvoice: response.data.data.hasSuInvoice,
              },
            });
          } else {
            throw Object.assign(
              new Error("Request sale order list failed, please try again later."),
              { code: 305 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: SALE_ORDER_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SALE_ORDER_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const saleOrderDetailsRequest =
  (soid, type) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      
      dispatch({ type: SALE_ORDER_DETAILS_REQUEST });

      axios
        .get(SERVER_URL + `/sale_order/${soid}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            let orderInfo = response.data.data;
         
            let productsTotal = parseFloat(orderInfo.orderItems.reduce(
              (preTotal, item) => Number(preTotal) + Number(item.totalPrice),
              0
            ).toFixed(2));
            let additionalCostTotal = parseFloat(orderInfo.orderCosts.reduce(
              (preTotal, item) => Number(preTotal) + Number(item.cost),
              0
            ).toFixed(2));

            let orderSummary = {
              productsTotal: productsTotal,
              additionalCostTotal: additionalCostTotal,
            };

            orderInfo["orderSummary"] = orderSummary;

            for(const item of orderInfo.orderItems){
              item.unitPrice = Number(item.unitPrice);
              item.discount = Number(item.discount);
              item.quantity = Number(item.quantity);
              item.returnedQuantity = Number(item.returnedQuantity);
              item.totalPrice = Number(item.totalPrice);
              item.stockOnHand = Number(item.stockOnHand);
            }

            dispatch({
              type: SALE_ORDER_DETAILS_SUCCESS,
              payload: orderInfo,
            });
          } else {
            throw Object.assign(
              new Error(
                "Request sale order details failed, please try again later."
              ),
              { code: 306 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: SALE_ORDER_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SALE_ORDER_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const editSaleOrderRequest =
  (values, soid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: SALE_ORDER_EDIT_REQUEST });
      axios
        .put(SERVER_URL + `/sale_order/${soid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: SALE_ORDER_EDIT_SUCCESS, payload: response.data.data});
          } else if (response.data.error === 40) {
            throw Object.assign(
              new Error(
                "Order quantity must be greater than zero, please change the order quantity."
              ),
              { code: 303 }
            );
          } else if (response.data.error === 42) {
            throw Object.assign(
              new Error(
                "Product stock not enough"
              ),
            );
          } else if (response.data.error === 23) {
            throw Object.assign(
              new Error(
                response.data.message
              ),
            );
          } else if (response.data.error === 26) {
            throw Object.assign(
              new Error(
                response.data.message
              ),
            );
          } else {
            throw Object.assign(
              new Error(
                "Update sale order failed, please try again later."
              ),
              { code: 307 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: SALE_ORDER_EDIT_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SALE_ORDER_EDIT_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };


export const shipSaleOrderRequest =
  (values, soid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: SHIP_SALE_ORDER_REQUEST });
      axios
        .put(SERVER_URL + `/sale_order/ship/${soid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: SHIP_SALE_ORDER_SUCCESS });
          } else {
            throw Object.assign(
              new Error("Update sale order shipping details failed, please try again later."),
              { code: 308 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: SHIP_SALE_ORDER_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SHIP_SALE_ORDER_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const saleOrderActionsRequest =
(soid) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    
    dispatch({ type: SALE_ORDER_ACTIONS_REQUEST });

    axios
      .get(SERVER_URL + `/sale_order/actions/${soid}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: SALE_ORDER_ACTIONS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error(
              "Request sale order actions failed, please try again later."
            ),
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: SALE_ORDER_ACTIONS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: SALE_ORDER_ACTIONS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const editSaleOrderAfterApproveRequest =
  (values, soid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: SALE_ORDER_EDIT_AFTER_APPROVE_REQUEST });
      axios
        .post(SERVER_URL + `/sale_order/after-approve-edit/${soid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: SALE_ORDER_EDIT_AFTER_APPROVE_SUCCESS, payload: response.data.data});
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: SALE_ORDER_EDIT_AFTER_APPROVE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SALE_ORDER_EDIT_AFTER_APPROVE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

