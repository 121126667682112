import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  RefreshButton,
  ScreenTitle,
  SimpleBlueButton,
  TextLink,
} from "../../components/editComponents";
import Breadcrumbs from "../../components/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import XeroIntegrationTab from "./XeroIntegrationTab";
import VerticalTabs from "../../components/VerticalTabs";
import {
  Loader,
  ViewDateWithTime,
  Tag,
  ErrorMessage,
  LongTextError,
} from "../../components/viewComponents";
import XeroLink from "../../components/XeroLink";
import { xeroTaskListRequest } from "../../actions/xeroActions";
import Pagination from "../../components/Pagination";
import { restartXeroTaskRequest } from "../../api/xeroServices";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function XeroSyncDataScreen() {
  useDocumentTitle("Xero Integration");
  const dispatch = useDispatch();
  ////////////////////////////////////////////////////////////////////////////////////////
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

 ////////////////////////////////////////////////////////////////////////////////////////
  const { loading, xeroTasks, error, success } = useSelector(
    (state) => state.xeroTaskList
  );
 

  const [restartTaskStatus, setRestartTaskStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const pageSize = 40;
  const [pageNum, setPageNum] = useState(0);

  ////////////////////////////////////////////////////////////////////////////////////////
  const [activeTab, setActiveTab] = useState("sale_order_invoice");
  const [refreshTaskCnt, setRefreshTaskCnt] = useState(0);

  const tabs = [
    { id: 1, tabName: "Sale order", value: "sale_order_invoice" },
    { id: 3, tabName: "Purchase order", value: "purchase_order_bill" },
    { id: 4, tabName: "Stock Adjustment", value: "on_hand_stock_journal" },
    { id: 4, tabName: "Products", value: "sync_product" },
  ];

  const changeTabAction = (tabName) => {
    setActiveTab(tabName);
  };
  ////////////////////////////////////////////////////////////////////////////////////////
  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };


  useEffect(() => {
    dispatch(xeroTaskListRequest(activeTab, pageNum, pageSize));
  }, [dispatch, activeTab, pageNum, refreshTaskCnt]);

  ////////////////////////////////////////////////////////////////////////////////////////

  const restartXeroTask = async(taskId) => {
    try {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await restartXeroTaskRequest(taskId, config);
      if (responseData.data.success) {
        setRefreshTaskCnt(refreshTaskCnt + 1)
        setRestartTaskStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
       
      } else {
        throw "Request restart Xero task falied, please try again later."
      }
    } catch (error) {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const refreshData = () => {
    setRefreshTaskCnt(refreshTaskCnt + 1)
  }
  
  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Xero Integration" />
      <ScreenTitle title="Xero Integration" />
      <div className={commonStyle.tabsContainer}>
        <XeroIntegrationTab tabName="data" />
        <div className={commonStyle.settingsContent}>
          <div style={{display:"flex", justifyContent:"space-between",marginTop:"20px", marginBottom:"-10px"}}>
            <p>*This page displays the historical data synchronized from StockUnify to your Xero system.</p>
            <RefreshButton name="Refresh Task Status" action={refreshData} />
          </div>
          <div className={commonStyle.pageContentContainerTwoColumn}>
            <VerticalTabs
              tabsData={tabs}
              changeTab={changeTabAction}
              activeTab={activeTab}
            />
            <div className={commonStyle.templatesContentWrapper}>
              {loading ? (
                <Loader mess="Requesting Xero tasks data..." />
              ) : success ? (
                <div>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Xero Link</th>
                      <th>Status</th>
                      <th>Original Data</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {xeroTasks.tasks.length > 0 ? (
                      xeroTasks.tasks
                        .filter((x) => x.type === activeTab)
                        .map((item) => (
                          <tr key={item.id}>
                            <td>
                              <ViewDateWithTime value={item.atime} />
                            </td>
                            <td>
                              {" "}
                              {item.type === "on_hand_stock_journal" ? (
                                <p>Stock adjustment</p>
                              ) : item.type === "sale_order_invoice" ? (
                                <p>New sale order - Invoice</p>
                              ) : item.type === "sale_order_journal" ? (
                                <p>New sale order - COGS</p>
                              ) : item.type === "purchase_order_bill" ? (
                                <p>New purchase order</p>
                              ) : item.type === "sync_product" ? (
                                <p>Update product</p>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td>
                              {item.status === "SUCCEED" ? (
                                <>
                                  {item.type === "on_hand_stock_journal" && item.reference.adjust_type === "RECEIVESTOCK" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="stock_adjust_receive_stock"
                                    />
                                  ) : item.type === "on_hand_stock_journal" && item.reference.adjust_type === "STOCKDEDUCTION" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="stock_adjust_deduct_stock"
                                    />
                                  ) : item.type === "sale_order_invoice" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="sale_order_invoice"
                                    />
                                  ) : item.type === "sale_order_journal" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="sale_order_journal"
                                    />
                                  ) : item.type === "purchase_order_bill" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="purchase_order_bill"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td className="w-1/3">
                              {item.status === "QUEUED" ? (
                                <Tag name={item.status} color="yellow" />
                              ) : item.status === "SUCCEED" ? (
                                <Tag name={item.status} color="green" />
                              ) : item.status === "PROCESSING" ? (
                                <Tag name={item.status} color="green" />
                              ) : item.status === "FAILED" ? (
                                <Tag name={item.status} color="red" />
                              ) : item.status === "DELAYED" ? (
                                <Tag name={"SCHEDULED"} color="yellow" />
                              ) : item.status === "PARTIAL_SUCCEED" ? (
                                <Tag name={"PARTIAL SUCCEED"} color="green" />
                              ) : (
                                <></>
                              )}
                              {
                                item.status !== "SUCCEED" && <LongTextError error={item.error ? item.error : ""} />
                              }
                            </td>

                            <td>
                              {"sale_order_id" in item.reference ? (
                                <TextLink
                                  path={`/sale/order/preview/${item.reference.sale_order_id}`}
                                  name="Go to sale order"
                                />
                              ) : "purchase_order_id" in item.reference ? (
                                <TextLink
                                  path={`/purchase/order/preview/${item.reference.purchase_order_id}`}
                                  name="Go to purchase order"
                                />
                              ) : "product_family_id" in item.reference ? (
                                <TextLink
                                  path={`/inventory/preview/${item.reference.product_family_id}`}
                                  name="Go to product"
                                />
                              ) : "adjust_id" in item.reference ? (
                                <TextLink
                                  path={`/inventory/bulk-stock-adjustment/preview/${item.reference.adjust_id}`}
                                  name="Go to stock adjustment"
                                />
                              ) : "family_id" in item.reference ? (
                                <TextLink
                                  path={`/inventory/preview/${item.reference.family_id}`}
                                  name="Go to product details"
                                />
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              {item.status === "FAILED" ? (
                                <>
                                  {restartTaskStatus.loading ? (
                                    <Loader />
                                  ) : (
                                    <SimpleBlueButton
                                      name="Retry"
                                      action={() => restartXeroTask(item.id)}
                                    />
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td>There are no records.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {xeroTasks.tasks.length > 0 ? (
                  <Pagination
                    totalPageNum={xeroTasks.totalPages}
                    forcePage={pageNum}
                    onPageChange={onPageChangeRequest}
                  />
                ) : (
                  <></>
                )}
                </div>

              ) : error ? (
                <ErrorMessage mess={error} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
