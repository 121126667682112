import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productOptionsRequest } from "../../../actions/productActions";
import {
  importXeroProductsRequest,
  createXeroImportTaskRequest,
} from "../../../api/importXeroDataServices";
import {
  LoadingButton,
  SimpleBlueButton,
} from "../../../components/editComponents";
import { NoBorderSelect } from "../../../components/inputFields";
import {
  Loader,
  SimpleDialog,
  TextErrorMessage,
  VariantSkuTag,
  ViewDateWithTime,
} from "../../../components/viewComponents";
import { convtPrice } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import integrationStyle from "../integrationStyle.module.css";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ImportXeroProductsForm({ importSuccess }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, options, error, success } = useSelector(
    (state) => state.productOptions
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [importStatus, setImportStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [locationErrors, setLocationErrors] = useState({});

  const [dialog, setDialog] = useState(false);
  const [xeroProducts, setXeroProducts] = useState([]);
  const [lastImportTime, setLastImportTime] = useState("");

  const handleChange = (e) => {
    const { name, value, index } = e.target;
    let copy = [...xeroProducts];
    copy[index][name] = value;
    setXeroProducts(copy);
  };

  const importXeroProducts = async () => {
    try {
      setRequestStatus((preState) => ({ ...preState, loading: true }));
      const responseData = await importXeroProductsRequest(config);
      if (responseData.success) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
        setXeroProducts(responseData.data);
        setLastImportTime(responseData.latstImportTime);
      } else {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: false,
          error: "Import products form Xero failed.",
        }));
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  useEffect(() => {
    importXeroProducts();
    dispatch(productOptionsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (success && requestStatus.success) {
      let hasDefaultLocation = options.location.filter((x) => x.isDefault)
        .length
        ? options.location.filter((x) => x.isDefault)[0]
        : "";

      let defaulLocation = hasDefaultLocation
        ? hasDefaultLocation
        : options.location[0];
      let copy = [...xeroProducts];
      copy.forEach((x) => {
        x.location = defaulLocation;
      });
      setXeroProducts(copy);
    }
  }, [success, requestStatus.success]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  const checkValidation = () => {
    const hasErrors = validate(xeroProducts);
    if (hasErrors && Object.keys(hasErrors).length === 0) {
      setLocationErrors({});
      saveProductsRequest();
    } else {
      setLocationErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};

    values.forEach((x, index) => {
      if (x.tracked && x.quantityOnHand > 0 && !x.location) {
        errors[index] = "Please select a location";
      }
    });

    return errors;
  };

  const saveProductsRequest = async () => {
    if (!xeroProducts.length) {
      return;
    }
    try {
      setDialog(true);
      setImportStatus((preState) => ({ ...preState, loading: true }));
      const responseData = await createXeroImportTaskRequest("product", xeroProducts, config);
      if (responseData.success) {
        setImportStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setImportStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    importSuccess();
  };

  return (
    <div style={{ height: "100%", paddingTop: "20px" }}>
      {importStatus.error ? (
        <TextErrorMessage mess={importStatus.error} />
      ) : (
        <></>
      )}

      <div className={integrationStyle.resultWrapper}>
        <SimpleDialog
          title="Import Products From Xero"
          successMessage="Create import products from Xero task successfully."
          isDialogOpen={dialog}
          closeDialog={() => setDialog(false)}
          loading={importStatus.loading}
          loadingMessage="Processing request..."
          success={importStatus.success}
          error={importStatus.error}
          confirmAction={closeDialogWithSuccess}
        />

        {requestStatus.loading ? (
          <Loader mess="Requesting products from Xero, it may take a while if you have a large quantity items in your Xero system." />
        ) : requestStatus.success ? (
          <div style={{ height: "calc(100vh - 220px)", position: "relative" }}>
          
              <div className="flex flex-row items-center">
                <span>Found</span>
                <span className="text-brandColor font-medium mx-1">
                  {xeroProducts.length}
                </span>
                <span> products from your Xero system</span>{" "}
                {lastImportTime && (
                  <div className="flex flex-row items-center">
                    <span className="mr-1">, since you last import on</span>
                    <ViewDateWithTime value={lastImportTime} />
                  </div>
                )}
              </div>
   
            {xeroProducts.length > 0 ? (
              <div className={integrationStyle.xeroProductsCon}>
                <p>
                  The tracked item, which has a stock quantity greater than 0,
                  has been assigned a default location. However, you have the
                  option to change its location. Additionally, you can assign a
                  unit or category to the product.
                </p>

                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "22px",
                          }}
                        ></div>
                      </th>
                      <th>Code</th>
                      <th>Product Name</th>
                      <th>Sale Price</th>
                      <th>Purchase Cost</th>
                      <th>Tracked</th>
                      <th>Stock on Hand</th>
                      <th>Total Cost</th>
                      <th>Average Cost</th>
                      <th>location(Required)</th>
                      <th>Unit(Optional)</th>
                      <th>Category(Optional)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {xeroProducts.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "22px",
                            }}
                          ></div>
                        </td>
                        <td>
                          <VariantSkuTag name={item.code} />
                        </td>
                        <td>{item.name} </td>
                        <td>
                          {item.salePrice ? convtPrice(item.salePrice) : ""}{" "}
                        </td>
                        <td>
                          {item.purchaseCost
                            ? convtPrice(item.purchaseCost)
                            : ""}{" "}
                        </td>
                        <td>{item.tracked ? "Yes" : "No"} </td>
                        <td>{item.tracked ? item.quantityOnHand : ""} </td>

                        <td>
                          {item.tracked ? convtPrice(item.totalCostPool) : ""}{" "}
                        </td>
                        <td>
                          {item.tracked ? convtPrice(item.averageCost) : ""}{" "}
                        </td>
                        <td className={integrationStyle.xeroProductsEditCol}>
                          {" "}
                          {item.quantityOnHand > 0 ? (
                            <NoBorderSelect
                              placeHolder="Select Location"
                              value={item.location}
                              selectOptions={success ? options.location : []}
                              onChange={(location) =>
                                handleChange({
                                  target: {
                                    value: location,
                                    name: "location",
                                    index: index,
                                  },
                                })
                              }
                              error={locationErrors[index]}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className={integrationStyle.xeroProductsEditCol}>
                          <NoBorderSelect
                            placeHolder="Select Unit"
                            value={item.unit}
                            selectOptions={success ? options.units : []}
                            onChange={(unit) =>
                              handleChange({
                                target: {
                                  value: unit,
                                  name: "unit",
                                  index: index,
                                },
                              })
                            }
                          />
                        </td>
                        <td className={integrationStyle.xeroProductsEditCol}>
                          <NoBorderSelect
                            placeHolder="Select Category"
                            value={item.category}
                            selectOptions={success ? options.category : []}
                            onChange={(category) =>
                              handleChange({
                                target: {
                                  value: category,
                                  name: "category",
                                  index: index,
                                },
                              })
                            }
                          />
                        </td>

                        <td>
                          <div className={commonStyle.actionsGrop}></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
            <div style={{ position: "absolute", bottom: 0 }}>
            <SimpleBlueButton
                  name="Import Products from Xero"
                  action={checkValidation}
                />
            </div>
          </div>
        ) : requestStatus.error ? (
          <TextErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
