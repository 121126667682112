
import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { PRICE_TIER_LIST_REQUEST, PRICE_TIER_LIST_FAIL, PRICE_TIER_LIST_SUCCESS, NEW_PRICE_TIER_REQUEST, NEW_PRICE_TIER_SUCCESS, NEW_PRICE_TIER_FAIL, UPDATE_PRICE_TIER_REQUEST, UPDATE_PRICE_TIER_SUCCESS, UPDATE_PRICE_TIER_FAIL, DELETE_PRICE_TIER_REQUEST, DELETE_PRICE_TIER_SUCCESS, DELETE_PRICE_TIER_FAIL,   PRODUCT_VARIANT_DETAIL_REQUEST,
  PRODUCT_VARIANT_DETAIL_SUCCESS,
  PRODUCT_VARIANT_DETAIL_FAIL,
  UPDATE_PRODUCT_CUSTOM_PRICE_REQUEST,
  UPDATE_PRODUCT_CUSTOM_PRICE_SUCCESS,
  UPDATE_PRODUCT_CUSTOM_PRICE_FAIL, } from "../constants/customPriceConstants";

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

//////////////////////////// price Tier///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
export const priceTiersRequest = () => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: PRICE_TIER_LIST_REQUEST });
      axios
        .get(SERVER_URL + `/product/price_tier/list`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            let result = response.data.data;
            result.forEach(ele => {
                ele["isEdited"] = false;
            });
            dispatch({
              type: PRICE_TIER_LIST_SUCCESS,
              payload: result,
            });
          } else {
            throw Object.assign(
              new Error("Request price tier name list failed, please try again later."),
              { code: 601 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: PRICE_TIER_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PRICE_TIER_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
};

export const newPriceTierRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {

    dispatch({ type: NEW_PRICE_TIER_REQUEST });
    axios
      .post(SERVER_URL + `/product/price_tier/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_PRICE_TIER_SUCCESS });
          dispatch(priceTiersRequest());
        } else {
          if (response.data.error === 11) {
            throw Object.assign(
              new Error("Price tier name exists, please use another name"),
              { code: 602 }
           );
          } else {
            throw Object.assign(
              new Error("Create new price tier failed, please try again later."),
              { code: 603 }
           );
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_PRICE_TIER_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_PRICE_TIER_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updatePriceTierRequest =
  (values, id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: UPDATE_PRICE_TIER_REQUEST });
      axios
        .put(SERVER_URL + `/product/price_tier/${id}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_PRICE_TIER_SUCCESS });
            dispatch(priceTiersRequest());
          } else {
            if (response.data.error === 11) {
              throw Object.assign(
                new Error("Price tier name exists, please use another name"),
                { code: 602 }
             );
            } else {
              throw Object.assign(
                new Error("Update price tier failed, please try again later."),
                { code: 604 }
             );
         
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_PRICE_TIER_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_PRICE_TIER_FAIL,
        payload: "Connection error, please try again later",
      });
    }
};

export const deletePriceTierRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: DELETE_PRICE_TIER_REQUEST });
    axios
      .delete(SERVER_URL + `/product/price_tier/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: DELETE_PRICE_TIER_SUCCESS });
          dispatch(priceTiersRequest());
        } else {
          throw Object.assign(
            new Error("Delete price tier failed, please try again later."),
            { code: 605 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_PRICE_TIER_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: DELETE_PRICE_TIER_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

  // get product variant details
  export const productVariantDetailsRequest =
  (id) => async (dispatch, getState) => {
  
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: PRODUCT_VARIANT_DETAIL_REQUEST });
      axios
        .get(SERVER_URL + `/product/custom_price/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {

            let basic_price = response.data.data.variant && response.data.data.variant.length ? response.data.data.variant[0].salePrice : 0;
            basic_price = Number(basic_price);
            let famid = response.data.data.variant && response.data.data.variant.length ? response.data.data.variant[0].famid : "";
            let variantSku = response.data.data.variant && response.data.data.variant.length ? response.data.data.variant[0].variantSku : "";
            let variantCode = response.data.data.variant && response.data.data.variant.length ? response.data.data.variant[0].variantCode : "";

            let product_basic = {
              famid: famid,
              variantSku: variantSku,
              variantCode: variantCode,
              productName: response.data.data.productName,
              salePrice: basic_price
            }

           
            let price_tiers = response.data.data.priceTier;

            let copy_price_tiers = price_tiers.length ? [...price_tiers] : [];
            let prices = response.data.data.customPrices;

            if(copy_price_tiers.length){
            copy_price_tiers.forEach((item) => {
              // find tier price
              let exist_tier_price = prices.find((x)=> Number(x.priceTierId) === Number(item.id));
              if(exist_tier_price){
                let is_static_price = true;
                if(Number(exist_tier_price.customPrice) === -1){
                  is_static_price = false;
                }
    
                item.isStaticPrice = is_static_price;
                item.customPrice = is_static_price ? Number(exist_tier_price.customPrice) : "";
                item.discount = !is_static_price ? Number(exist_tier_price.discount) : "";

                if(!is_static_price && Number(item.discount) > 0){
                  let discountedPrice = Number(basic_price - ((Number(item.discount) / 100) * basic_price))
                  discountedPrice = discountedPrice.toFixed(2);
                  discountedPrice = Number(discountedPrice);
                  item.discountedPrice = discountedPrice;
                }else{
                  item.discountedPrice = "";
                }
              }else{
                item.isStaticPrice = true;
                item.customPrice = "";
                item.discount = "";
                item.discountedPrice = "";
              }
            })
          }

            dispatch({
              type: PRODUCT_VARIANT_DETAIL_SUCCESS,
              payload: {prices: copy_price_tiers, productBasic:product_basic},
            });
          } else {
            throw Object.assign(
              new Error("Request product variant details failed, please try again late."),
              { code: 606 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: PRODUCT_VARIANT_DETAIL_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PRODUCT_VARIANT_DETAIL_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

// new or edit custom price
  export const updateProductCustomPriceRequest =
  (values, id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: UPDATE_PRODUCT_CUSTOM_PRICE_REQUEST });
      axios
        .post(SERVER_URL + `/product/custom_price/${id}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_PRODUCT_CUSTOM_PRICE_SUCCESS });
          }else{
            throw "Update custom price failed"
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_PRODUCT_CUSTOM_PRICE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_CUSTOM_PRICE_FAIL,
        payload: "Connection UPDATE_PRODUCT_CUSTOM_PRICE_FAIL, please try again later",
      });
    }
};