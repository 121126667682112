import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import billingStyle from "./billingStyle.module.css";
import { AiFillCheckCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { HiUser, HiUserGroup } from "react-icons/hi";
import { K_PRICING_FEATURES } from "../../constantsData/pricingFeatures";
import BillingSteps from "./BillingSteps";
import {
  DollarTag,
  ErrorMessage,
  FromTag,
  Loader,
  SubTitle,
  TextErrorMessage,
  TipsText,
} from "../../components/viewComponents";
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import {
  LoadingButton,
  SimpleBlueButton,
} from "../../components/editComponents";
import { organizationDetailsRequest } from "../../actions/orgSettingsActions";
import { NoBorderInput } from "../../components/inputFields";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function BillingStepOne({
  userPlanPrices,
  selectedPlan,
  annually,
  couponCode,
  setCouponCode,
  discount,
  setDiscount,
  onPlanSelect,
  onPayMethodChange,
  nextAction,
  extraUsers,
  setExtraUsers,
  isStep1,
  isStep2,
  userCurrency,
  currencyLabel,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const dispatch = useDispatch();
  const { loading, orgInfo, error, success } = useSelector(
    (state) => state.orgDetails
  );

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const addExtraUser = () => {
    setExtraUsers((prevState) => prevState + 1);
  };

  const reduceExtraUser = () => {
    setExtraUsers((prevState) => prevState - 1);
  };
  // switch button

  // components
  const PlanTitle = ({ title }) => {
    return <div className={billingStyle.planTitle}>{title}</div>;
  };

  const PlanPrice = ({ price, annually }) => {
    return (
      <div className={billingStyle.planPriceCon}>
        <span className={billingStyle.monthTag}>{userCurrency}</span>
        <span className={billingStyle.monthTag}>{currencyLabel}</span>
        <span className={billingStyle.planPrice}>{price}</span>
        <span className={billingStyle.monthTag}>
          {annually ? "/Annually" : "/Monthly"}
        </span>
      </div>
    );
  };

  const UserNumber = ({ num }) => {
    return (
      <div className={billingStyle.userNumberCon}>
        {Number(num) > 1 ? (
          <HiUserGroup className={billingStyle.userIcon} />
        ) : (
          <HiUser className={billingStyle.userIcon} />
        )}
        <span className={billingStyle.userNum}>{num}</span>{" "}
        <span>{Number(num) > 1 ? "users included" : "user included"}</span>
      </div>
    );
  };

  const handlePromotionCodeChange = (e) => {
    const { value } = e.target;
    setCouponCode(value);
  };

  /////////////////////apply coupon code/////////////////////
  ///////////////////////////////////////////////////////////
  const applyCouponCode = () => {
    try {
      setRequestStatus({ ...requestStatus, loading: true });
      axios
        .get(SERVER_URL + `/billing/coupon_code/${couponCode}`, config)
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              success: true,
              error: "",
            });

            let coupon = response.data.data;
            if (coupon.amount_off) {
              setDiscount("amount", Number(coupon.amount_off) / 100);
            }

            if (coupon.percent_off) {
              setDiscount("percent", coupon.percent_off);
            }
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          setRequestStatus({
            ...requestStatus,
            loading: false,
            error:
              typeof err === "string"
                ? err
                : "Connection error, please try again later.",
          });
        });
    } catch (error) {
      setRequestStatus({
        ...requestStatus,
        loading: false,
        error: "Connection error, please try again later.",
      });
    }
  };

  useEffect(() => {
    dispatch(organizationDetailsRequest());
  }, [dispatch]);

  return (
    <div>
      <BillingSteps step1={isStep1} step2={isStep2} />

      <div className={billingStyle.billingContentWrapper}>
        <div className={billingStyle.billingContentCon}>
          <div className={billingStyle.switchWrapper}>
            <p>Paid Monthly</p>
            <div
              className={
                !annually
                  ? billingStyle.switchCon
                  : `${billingStyle.switchCon} ${billingStyle.annually}`
              }
              onClick={onPayMethodChange}
              style={{ justifyContent: annually ? "flex-end" : "flex-start" }}
            >
              <div className={billingStyle.handleSwitch}></div>
            </div>
            <div className={billingStyle.annuallyCon}>
              <span>Paid Annually</span>
              <div className={billingStyle.discountTag}></div>
            </div>
          </div>
          <div className={billingStyle.priceCardWrapper}>
            <div
              className={
                selectedPlan === "startUp"
                  ? `${billingStyle.priceCard} ${billingStyle.priceCardSelected}`
                  : billingStyle.priceCard
              }
              onClick={() => onPlanSelect("startUp")}
            >
              <PlanTitle title="Start up" />
              {annually ? (
                <PlanPrice
                  price={userPlanPrices["startUp"].annually}
                  annually
                />
              ) : (
                <PlanPrice price={userPlanPrices["startUp"].monthly} />
              )}
              <UserNumber num={1} />
            </div>
            <div
              className={
                selectedPlan === "basic"
                  ? `${billingStyle.priceCard} ${billingStyle.priceCardSelected}`
                  : billingStyle.priceCard
              }
              onClick={() => onPlanSelect("basic")}
            >
              <PlanTitle title="Basic" />
              {annually ? (
                <PlanPrice price={userPlanPrices["basic"].annually} annually />
              ) : (
                <PlanPrice price={userPlanPrices["basic"].monthly} />
              )}
              <UserNumber num={3} />
            </div>
            <div
              className={
                selectedPlan === "business"
                  ? `${billingStyle.priceCard} ${billingStyle.priceCardSelected} ${billingStyle.popular}`
                  : `${billingStyle.priceCard} ${billingStyle.popular}`
              }
              onClick={() => onPlanSelect("business")}
            >
              <PlanTitle title="Business" />
              {annually ? (
                <PlanPrice
                  price={userPlanPrices["business"].annually}
                  annually
                />
              ) : (
                <PlanPrice price={userPlanPrices["business"].monthly} />
              )}
              <UserNumber num={5} />
              <div className={billingStyle.popularTag}>Best Value</div>
            </div>
            <div
              className={
                selectedPlan === "enterprise"
                  ? `${billingStyle.priceCard} ${billingStyle.priceCardSelected}`
                  : billingStyle.priceCard
              }
              onClick={() => onPlanSelect("enterprise")}
            >
              <PlanTitle title="Enterprise" />
              {annually ? (
                <PlanPrice
                  price={userPlanPrices["enterprise"].annually}
                  annually
                />
              ) : (
                <PlanPrice price={userPlanPrices["enterprise"].monthly} />
              )}
              <UserNumber num={12} />
            </div>
          </div>

          <div className={billingStyle.featuresTableWrapper}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Start up</th>
                  <th>Basic</th>
                  <th>Business</th>
                  <th>Enterprise</th>
                </tr>
              </thead>
              <tbody>
                {K_PRICING_FEATURES.map((item, index) =>
                  item.usageFeature ? (
                    <tr key={index}>
                      <td className={billingStyle.featureTableName}>
                        {item.name}
                      </td>
                      <td style={{ width: "15%" }}>
                        <span className={billingStyle.featureText}>
                          {item.startUp}
                        </span>
                      </td>
                      <td style={{ width: "15%" }}>
                        <span className={billingStyle.featureText}>
                          {item.basic}
                        </span>
                      </td>
                      <td style={{ width: "15%" }}>
                        <span className={billingStyle.featureText}>
                          {item.business}
                        </span>
                      </td>
                      <td style={{ width: "15%" }}>
                        <span className={billingStyle.featureText}>
                          {item.enterprise}
                        </span>
                      </td>
                    </tr>
                  ) : (
                    <tr key={index}>
                      <td className={billingStyle.featureTableName}>
                        {item.name}
                      </td>
                      <td style={{ width: "15%" }}>
                        {item.startUp ? (
                          <AiFillCheckCircle
                            className={billingStyle.featureIcon}
                          />
                        ) : (
                          <AiOutlineMinusCircle
                            className={billingStyle.uncheckFeatureIcon}
                          />
                        )}
                      </td>
                      <td style={{ width: "15%" }}>
                        {item.basic ? (
                          <AiFillCheckCircle
                            className={billingStyle.featureIcon}
                          />
                        ) : (
                          <AiOutlineMinusCircle
                            className={billingStyle.uncheckFeatureIcon}
                          />
                        )}
                      </td>
                      <td style={{ width: "15%" }}>
                        {item.business ? (
                          <AiFillCheckCircle
                            className={billingStyle.featureIcon}
                          />
                        ) : (
                          <AiOutlineMinusCircle
                            className={billingStyle.uncheckFeatureIcon}
                          />
                        )}
                      </td>
                      <td style={{ width: "15%" }}>
                        {item.enterprise ? (
                          <AiFillCheckCircle
                            className={billingStyle.featureIcon}
                          />
                        ) : (
                          <AiOutlineMinusCircle
                            className={billingStyle.uncheckFeatureIcon}
                          />
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={billingStyle.summaryCon}>
          <p className={billingStyle.summaryTitle}>Billing Summary</p>

          <div className="mt-4">
            <SubTitle name="Organization Details" />
            {loading ? (
              <Loader mess="Requesting organization information, please wait a minute..." />
            ) : success ? (
              <table className={billingStyle.billingSummaryTable}>
                <tbody>
                  <tr>
                    <td className={billingStyle.textLabel}>
                      Organization Name
                    </td>
                    <td className={billingStyle.summaryPriceColumn}>
                      {orgInfo.name}
                    </td>
                  </tr>
                  <tr>
                    <td className={billingStyle.textLabel}>Base Currency</td>
                    <td className={billingStyle.summaryPriceColumn}>
                      {orgInfo.baseCurrency}
                    </td>
                  </tr>

                  <tr>
                    <td className={billingStyle.textLabel}>
                      Account Administrator
                    </td>
                    <td className={billingStyle.summaryPriceColumn}>
                      {orgInfo.adminEmail}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
            <div className="mt-4">
              <SubTitle name="Subscription Details" />
            </div>

            <table className={billingStyle.billingSummaryTable}>
              <tbody>
                <tr>
                  <td className={billingStyle.textLabel}>Plan Name</td>
                  <td className={billingStyle.summaryPriceColumn}>
                    {selectedPlan === "startUp"
                      ? "Start-up Plan"
                      : selectedPlan === "basic"
                      ? "Basic Plan"
                      : selectedPlan === "business"
                      ? "Business Plan"
                      : selectedPlan === "enterprise"
                      ? "Enterprise Plan"
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className={billingStyle.textLabel}>Payment Method</td>
                  <td className={billingStyle.summaryPriceColumn}>
                    {annually ? "Paid annually" : "Paid monthly"}
                  </td>
                </tr>

                <tr>
                  <td className={billingStyle.textLabel}>Users</td>
                  <td className={billingStyle.summaryPriceColumn}>
                    {selectedPlan === "startUp"
                      ? "1 user included"
                      : selectedPlan === "basic"
                      ? "3 users included"
                      : selectedPlan === "business"
                      ? "5 users included"
                      : selectedPlan === "enterprise"
                      ? "12 users included"
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className={billingStyle.textLabel}>Plan Price</td>
                  <td className={billingStyle.summaryPriceColumn}>
                    {currencyLabel}
                    {annually
                      ? userPlanPrices[selectedPlan].annually
                      : userPlanPrices[selectedPlan].monthly}
                  </td>
                </tr>
              </tbody>
            </table>

            {selectedPlan === "business" || selectedPlan === "enterprise" ? (
              <table className={billingStyle.billingSummaryTable}>
                <tbody>
                  <tr style={{ height: "60px" }}>
                    <td>
                      <span>Extra Users</span>
                      {selectedPlan === "business" ? (
                        <TipsText text={`${currencyLabel}49/user`} />
                      ) : selectedPlan === "enterprise" ? (
                        <TipsText text={`${currencyLabel}39/user`} />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <div className={billingStyle.extraUsersCon}>
                        <AiOutlineMinusSquare
                          className={billingStyle.adjustIcon}
                          onClick={reduceExtraUser}
                        />
                        <span className={billingStyle.extraUserNum}>
                          {extraUsers}
                        </span>
                        <AiOutlinePlusSquare
                          className={billingStyle.adjustIcon}
                          onClick={addExtraUser}
                        />
                      </div>
                    </td>
                    {annually ? (
                      <td className={billingStyle.summaryPriceColumn}>
                        {currencyLabel}
                        {selectedPlan === "business"
                          ? Number(extraUsers) * 49 * 12
                          : selectedPlan === "enterprise"
                          ? Number(extraUsers) * 39 * 12
                          : ""}
                      </td>
                    ) : (
                      <td className={billingStyle.summaryPriceColumn}>
                        {currencyLabel}
                        {selectedPlan === "business"
                          ? Number(extraUsers) * 49
                          : selectedPlan === "enterprise"
                          ? Number(extraUsers) * 39
                          : ""}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            ) : (
              <></>
            )}

            <table className={billingStyle.billingSummaryTable}>
              <tbody>
                <tr style={{ height: "60px" }}>
                  <td>
                    <span>Promotion Code</span>
                  </td>
                  <td>
                    <NoBorderInput
                      type="text"
                      name="Attribute"
                      value={couponCode}
                      isReadOnly={discount.value ? true : false}
                      onChange={(code) =>
                        handlePromotionCodeChange({
                          target: {
                            value: code,
                          },
                        })
                      }
                    />
                  </td>
                  {discount.value ? (
                    <td>
                      {discount.type === "percent" ? (
                        <>
                          {discount.value}% <FromTag name="OFF" />
                        </>
                      ) : (
                        <span>-{discount.value}</span>
                      )}
                    </td>
                  ) : (
                    <td style={{ paddingRight: "12px" }}>
                      {requestStatus.loading ? (
                        <LoadingButton name="Applying..." />
                      ) : (
                        <SimpleBlueButton
                          name="Apply"
                          action={applyCouponCode}
                        />
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
            {requestStatus.error ? (
              <TextErrorMessage mess={requestStatus.error} />
            ) : (
              <></>
            )}

            <SimpleBlueButton name="Next" action={nextAction} />
          </div>
        </div>
      </div>
    </div>
  );
}
