import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { stockAdjustListRequest } from "../../../actions/productActions";
import {
  Loader,
  ErrorMessage,
  ViewDateText,
  Tag,
  AdjustTypeTag,
} from "../../../components/viewComponents";
import {
  ScreenTitle,
  AddLinkButton,
  ViewButtonLink,
} from "../../../components/editComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import Pagination from "../../../components/Pagination";
import XeroLink from "../../../components/XeroLink";
import { useNavigate } from "react-router-dom";

export default function StockBulkAdjustmentScreen() {
  useDocumentTitle("Stock Adjustment");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, adjustList, error, success } = useSelector(
    (state) => state.stockAdjustList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(stockAdjustListRequest(pageNum, pageSize));
  }, [dispatch, pageNum]);

  const naviogateToDetails = (orderId) => {
    navigate(`/inventory/bulk-stock-adjustment/preview/${orderId}`, { replace: false });
  }

  const PreviewLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("stock_adjustment") ? (
        <ViewButtonLink
          path={`/inventory/bulk-stock-adjustment/preview/${itemId}`}
        />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Stock Adjustment" />
      <ScreenTitle title="Stock Adjustment" />

      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting data, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <AddLinkButton
              name="New Stock Adjustment"
              path="/inventory/bulk-stock-adjustment/new"
            />

            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Create Date</th>
                  <th>Location</th>
                  <th>Adjust Type</th>
                  <th>Adjust Reason</th>
                  <th>Notes</th>
                  <th>Creator</th>
                  {userDetailsSuccess &&
                    userDetails.data.orgInfo.xeroConnected &&
                    userDetails.data.orgInfo.xeroConfigured && (
                      <th>Xero Task Status</th>
                    )}
                  {userDetailsSuccess &&
                    userDetails.data.orgInfo.xeroConnected &&
                    userDetails.data.orgInfo.xeroConfigured && (
                      <th>Xero Link</th>
                    )}
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {adjustList.stockAdjustments.length > 0 ? (
                  adjustList.stockAdjustments.map((item, index) => (
                    <tr key={index}>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">
                        <ViewDateText value={item.atime} />
                      </td>

                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">
                        <Tag name={item.location} color="gray" />
                      </td>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">
                        <AdjustTypeTag name={item.type} />
                      </td>
                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">
                        {item.reasonName}
                      </td>
                      <td onClick={() => naviogateToDetails(item.id)} className="w-1/4 hover:cursor-pointer">
                        {item.notes}
                      </td>

                      <td onClick={() => naviogateToDetails(item.id)} className="hover:cursor-pointer">
                        {item.creatorFirstName} {item.creatorLastName}
                      </td>

                      {userDetailsSuccess &&
                        userDetails.data.orgInfo.xeroConnected &&
                        userDetails.data.orgInfo.xeroConfigured && (
                          <td onClick={() => naviogateToDetails(item.id)} className="w-[100px] hover:cursor-pointer">
                            {item.journal_status === "ISSUED" ? (
                              <Tag name={item.journal_status} color="green" />
                            ) : (
                              <></>
                            )}
                          </td>
                        )}
                      {userDetailsSuccess &&
                        userDetails.data.orgInfo.xeroConnected &&
                        userDetails.data.orgInfo.xeroConfigured && (
                          <td onClick={() => naviogateToDetails(item.id)} className="w-[100px] hover:cursor-pointer">
                            {item.journal_id ? (
                              <XeroLink
                                invoiceId={item.journal_id}
                                type={
                                  item.type === "RECEIVESTOCK"
                                    ? "stock_adjust_receive_stock"
                                    : "stock_adjust_deduct_stock"
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </td>
                        )}
                      <td onClick={() => naviogateToDetails(item.id)} className="w-[100px] hover:cursor-pointer">
                        {" "}
                        {item.status === "Approved" ? (
                          <Tag name={item.status} color="green" />
                        ) : item.status === "Draft" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Completed" ? (
                          <Tag name={item.status} color="blue" />
                        ) : (
                          <></>
                        )}{" "}
                      </td>

                      {/* according to different status show different actions */}
                      <td className="w-[32px]">
                        <PreviewLink itemId={item.id} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>
                        There are no stock adjustment available, create a new
                        stock adjustment now.
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {adjustList.stockAdjustments.length > 0 ? (
              <Pagination
                totalPageNum={adjustList.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
