import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createCustomerOptionsRequest, newCustomerRequest } from "../../actions/customerActions";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ScreenTitle } from "../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import CustomerDetailForm from "./CustomerDetailForm";

export default function NewCustomerScreen() {
  useDocumentTitle("New Customer")
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { loading, options, error, success } = useSelector(
    (state) => state.createCustomerOptions
  );

  useEffect(() => {
    dispatch(createCustomerOptionsRequest());
  }, [dispatch]);

  const {
    loading: newLoading,
    success: newSuccess,
    error: newError,
  } = useSelector((state) => state.newCustomer);

  const [dialog, setDialog] = useState(false);

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/customers", { replace: true, from: location });
  };

  const addNewCustomerRequest = (values, differentBillingName) => {
    let customerValues = {
      name: values.name,
      billingName: differentBillingName ? values.billingName : values.name,
      country: typeof values.country === 'object' ? values.country.value : "",
      currency: typeof values.currency === 'object' ? values.currency.value : "",
      taxRateId: values.taxRateId,
      locationId: values.locationId,
      priceTierId: values.priceTierId,
      paymentTerm: values.paymentTerm,
      postalAddress: values.postalAddress,
      billingAddress: values.billingAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      notes: values.notes,
    };
    setDialog(true);
    dispatch(newCustomerRequest(customerValues));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Create New Customer"
        successMessage="New customer created successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={newLoading}
        loadingMessage="Processing request..."
        success={newSuccess}
        error={newError}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="New Customer" />
      <ScreenTitle title="Create New Customer" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting data, please wait a moment..." />
        ) : success ? (
          <div className="w-[80%]">
          <CustomerDetailForm
            type="new"
            customerId={""}
            onSubmit={addNewCustomerRequest}
            options={options}
            btnName="Create New Customer"
          />
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
