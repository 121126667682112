import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  productVariantDetailsRequest,
  updateProductCustomPriceRequest,
} from "../../actions/customPriceActions";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  ScreenTitle,
  SimpleBlueButtonLink,
} from "../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  ProductNameLink,
  SimpleDialog,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import CustomPriceForm from "./CustomPriceForm";

export default function CustomPriceScreen() {
  const dispatch = useDispatch();

  const { id: productId } = useParams();

  const { loading, productVariantPrice, error, success } = useSelector(
    (state) => state.productVariant
  );

  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateCustomPrice);

  const [dialog, setDialog] = useState(false);
  const [updateCnt, setUpdateCnt] = useState(0);

  useEffect(() => {
    dispatch(productVariantDetailsRequest(productId));
  }, [dispatch, productId, updateCnt]);

  const saveCustomPriceRequest = (prices) => {
    setDialog(true);
    let structured_prices = [];
    let structured_types = [];

    let product_tier_price = {
      variantCode: productVariantPrice.productBasic.variantCode,
      salePrice: productVariantPrice.productBasic.salePrice,
    };

    prices.forEach((ele) => {
      product_tier_price[ele.name] = {
        priceTierId: ele.id,
        customPrice: ele.customPrice,
        discount: ele.discount,
      };

      structured_types.push({
        priceTierId: ele.id,
        priceTierName: ele.name,
        isStaticPrice: ele.isStaticPrice,
      });
    });
    structured_prices.push(product_tier_price);
    let requestValues = {
      prices: structured_prices,
      priceTierType: structured_types,
    };

    dispatch(updateProductCustomPriceRequest(requestValues, productId));
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    setUpdateCnt(updateCnt + 1);
    //navigate("/inventory/product-list", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update Custom Price"
        successMessage="Custom price updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={updateLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="Confirm"
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="Custom Price" />
      <ScreenTitle title="Custom Price" />

      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting product price details, please wait a moment..." />
        ) : success ? (
          <div>
            <ProductNameLink productId={productId} name={productVariantPrice.productBasic.productName} code={productVariantPrice.productBasic.variantSku} largeText={true}/>


            {productVariantPrice.prices.length > 0 ? (
              <CustomPriceForm
                productBasic={productVariantPrice.productBasic}
                initialPrices={productVariantPrice.prices}
                saveCustomPrice={saveCustomPriceRequest}
              />
            ) : (
              <div>
                <p>
                  There are no price tier available, add a new price tier now.
                </p>
                <div className="w-full mt-6">
                  <SimpleBlueButtonLink
                    name="Add New Price Tier"
                    path="/settings/price-tier"
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
