import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { ADD_CUSTOMER_FAIL, ADD_CUSTOMER_REQUEST, ADD_CUSTOMER_SUCCESS, CREATE_CUSTOMER_OPTIONS_FAIL, CREATE_CUSTOMER_OPTIONS_REQUEST, CREATE_CUSTOMER_OPTIONS_SUCCESS, CUSTOMER_DETAILS_FAIL, CUSTOMER_DETAILS_REQUEST, CUSTOMER_DETAILS_SUCCESS, CUSTOMER_LIST_FAIL, CUSTOMER_LIST_REQUEST, CUSTOMER_LIST_SUCCESS, DELETE_CUSTOMER_FAIL, DELETE_CUSTOMER_REQUEST, DELETE_CUSTOMER_SUCCESS, EDIT_CUSTOMER_FAIL, EDIT_CUSTOMER_REQUEST, EDIT_CUSTOMER_SUCCESS } from "../constants/customerConstants";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const createCustomerOptionsRequest =
  () => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {

      dispatch({ type: CREATE_CUSTOMER_OPTIONS_REQUEST });
      axios
        .get(SERVER_URL + `/customers/options`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: CREATE_CUSTOMER_OPTIONS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Request customer options failed, please try again later."),
              { code: 501 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: CREATE_CUSTOMER_OPTIONS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOMER_OPTIONS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const newCustomerRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {

    dispatch({ type: ADD_CUSTOMER_REQUEST });
    axios
      .post(SERVER_URL + `/customers/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: ADD_CUSTOMER_SUCCESS, payload: response.data.data });
        } else {
          if (response.data.error === 100) {
            throw Object.assign(
              new Error("Customer name exists, please use a new name."),
              { code: 503 }
           );
          } else {
            throw Object.assign(
              new Error("Create new customer failed, please try again."),
              { code: 504 }
           );
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: ADD_CUSTOMER_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ADD_CUSTOMER_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const editCustomerRequest = (values, id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: EDIT_CUSTOMER_REQUEST });
    axios
      .put(SERVER_URL + `/customers/${id}`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: EDIT_CUSTOMER_SUCCESS, payload: response.data.data });
          // dispatch(usersListRequest());
        } else {
          if (response.data.error === 100) {
            throw Object.assign(
              new Error("Customer name exists, please use a new name."),
              { code: 503 }
           );
          } else {
            throw Object.assign(
              new Error("Update new customer failed, please try again."),
              { code: 505 }
           );
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: EDIT_CUSTOMER_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: EDIT_CUSTOMER_FAIL,
      payload: "Connection error, please try again later.",
    });
  }
};

export const customerListRequest = (pageNum, pageSize, keywords, sortOrder) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
  
    try {

      dispatch({ type: CUSTOMER_LIST_REQUEST });
      axios
        .get(SERVER_URL + `/customers/list?page_num=${pageNum}&page_size=${pageSize}&keywords=${keywords}&sortOrder=${sortOrder}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
  
            dispatch({
              type: CUSTOMER_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Request customer list failed, please try again later."),
              { code: 506 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: CUSTOMER_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: CUSTOMER_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const customerDetailsRequest =
  (id, type) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
  
      dispatch({ type: CUSTOMER_DETAILS_REQUEST });

      axios
        .get(SERVER_URL + `/customers/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: CUSTOMER_DETAILS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Request customer details failed, please try again later."),
              { code: 507 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: CUSTOMER_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: CUSTOMER_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const deleteCustomerRequest = (id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {

      dispatch({ type: DELETE_CUSTOMER_REQUEST });
      axios
        .delete(SERVER_URL + `/customers/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: DELETE_CUSTOMER_SUCCESS });
          } else {
            throw Object.assign(
              new Error("Customer deleted failed."),
              { code: 408 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: DELETE_CUSTOMER_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: DELETE_CUSTOMER_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };