import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  EditButton,
  RefreshButton,
  ScreenTitle,
  SimpleBlueButton,
} from "../../components/editComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import XeroIntegrationTab from "./XeroIntegrationTab";
import ImportXeroCustomers from "./importXeroData/ImportXeroCustomers";
import ImportXeroSuppliers from "./importXeroData/ImportXeroSuppliers";
import ImportXeroProducts from "./importXeroData/ImportXeroProducts";
import { importXeroDataTaskListRequest } from "../../actions/xeroActions";
import {
  ErrorMessage,
  ImportXeroDataErrors,
  Loader,
  SimpleDialog,
  SubTitle,
  Tag,
  ViewDateWithTime,
} from "../../components/viewComponents";
import Pagination from "../../components/Pagination";
import {
  systemSettingsDetailsRequest,
  updateSystemSettingsRequest,
} from "../../actions/systemSettingsActions";
import { ReadOnlyField, StyledSelect } from "../../components/inputFields";
import { locationListRequest } from "../../actions/orgSettingsActions";

export default function XeroSettingsScreen() {
  const dispatch = useDispatch();
  useDocumentTitle("Xero Settings");

  const INVOICE_OPTIONS = [
    { id: 1, label: "Yes", value: "yes" },
    { id: 2, label: "No", value: "no" },
  ];

  const { loading, settingDetails, error, success } = useSelector(
    (state) => state.systemSettingDetails
  );
  const {
    loading: locationLoading,
    locations,
    error: locationError,
    success: locationSuccess,
  } = useSelector((state) => state.locationList);

  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateSystemSettings);

  const [formState, setFormState] = useState({
    hookInvoiceRule: {},
    hookDefaultLocation: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);

  useEffect(() => {
    dispatch(systemSettingsDetailsRequest("xero"));
    dispatch(locationListRequest("selectOptions"));
  }, [dispatch, updateSuccess]);

  useEffect(() => {
    if (success && locationSuccess) {
      let findHookInvoiceRule = settingDetails.find(
        (x) => x.settingName === "xero_webhook_invoice"
      );

      let hook_rule_value = "";
      if (findHookInvoiceRule) {
        hook_rule_value = INVOICE_OPTIONS.find(
          (x) => x.value === findHookInvoiceRule.settingValue
        );
      }

      let findDefaultLocation = settingDetails.find(
        (x) => x.settingName === "xero_webhook_default_location_id"
      );
      let location_value = "";
      if (findDefaultLocation) {
        location_value = locations.find(
          (x) => Number(x.id) === Number(findDefaultLocation.settingValue)
        );
      }

      setFormState({
        hookDefaultLocation: location_value ? location_value : "",
        hookInvoiceRule: hook_rule_value ? hook_rule_value : {},
      });
    }
  }, [success, locationSuccess]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const checkValidation = () => {
    const hasErrors = validate(formState);

    if (Object.values(hasErrors).length === 0) {
      // setEdit(false);
      let submitValues = {
        hookInvoiceRule: formState.hookInvoiceRule
          ? formState.hookInvoiceRule.value
          : "no",
        hookDefaultLocationId: formState.hookDefaultLocation.id,
      };
      saveChanges(submitValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.hookDefaultLocation) {
      errors.hookDefaultLocation = "Please select a default location.";
    }
    if (!values.hookInvoiceRule) {
      errors.hookInvoiceRule =
        "Please select whether sync invoice from Xero to StockUnify.";
    }
    return errors;
  };

  const saveChanges = (values) => {
    dispatch(updateSystemSettingsRequest("xero", values));
    setUpdateDialog(true);
    setIsEdit(false);
  };


  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update Xero Settings"
        successMessage="Updated successfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={updateLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="OK"
        confirmAction={() => setUpdateDialog(false)}
      />

      <Breadcrumbs screenName="Xero Settings" />
      <ScreenTitle title="Xero Settings" />

      <div className={commonStyle.tabsContainer}>
        <XeroIntegrationTab tabName="settings" />
        <div className={commonStyle.settingsContent}>
          <div className="w-full min-h-[980px]">
            {loading ? (
              <Loader mess="Requesting details, please wait a moment..." />
            ) : success ? (
              <div className="w-1/2 h-full">
                <div className="bg-green-50 p-4 rounded mt-4">
                  <p>
                    * Enable Xero Webhook to automatically sync invoices from Xero to StockUnify. When an invoice is created and saved as approved in Xero, a corresponding sales order will be generated in StockUnify with a 'Draft' status, ready for review and processing. You can enable or disable this feature anytime based on your workflow needs.
                  </p>
                </div>

                <div className="w-full flex flex-row justify-between mt-8">
                  <p className="text-brandColor">Xero Webhook Settings</p>
                  {!isEdit && <EditButton action={() => setIsEdit(true)} />}
                </div>

                <div className="mt-4">
                  {isEdit ? (
                    <StyledSelect
                      label={"Automatically Sync Invoices from Xero to StockUnify?"}
                      clearable={false}
                      placeHolder="Select tax rule"
                      selectOptions={INVOICE_OPTIONS}
                      value={formState.hookInvoiceRule}
                      onChange={(hookInvoiceRule) =>
                        handleChange({
                          target: {
                            value: hookInvoiceRule,
                            name: "hookInvoiceRule",
                          },
                        })
                      }
                      error={formErrors.hookInvoiceRule}
                    />
                  ) : (
                    <ReadOnlyField
                      label={"Automatically Sync Invoices from Xero to StockUnify?"}
                      value={formState.hookInvoiceRule.label}
                    />
                  )}
                </div>

                <div className="mt-8">
                  {isEdit ? (
                    <StyledSelect
                      label={"Please select a default location for the auto-created sales order"}
                      clearable={false}
                      placeHolder="Select location"
                      selectOptions={locationSuccess ? locations : []}
                      value={formState.hookDefaultLocation}
                      onChange={(hookDefaultLocation) =>
                        handleChange({
                          target: {
                            value: hookDefaultLocation,
                            name: "hookDefaultLocation",
                          },
                        })
                      }
                      error={formErrors.hookDefaultLocation}
                    />
                  ) : (
                    <ReadOnlyField
                      label={"Please select a default location for the auto-created sales order"}
                      value={formState.hookDefaultLocation.label}
                    />
                  )}
                </div>

                {isEdit && (
                  <div className="w-full mt-6">
                    <SimpleBlueButton
                      name="Save Changes"
                      action={checkValidation}
                    />
                  </div>
                )}
              </div>
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
