import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  AiFillEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import { MdFileCopy, MdLocalShipping } from "react-icons/md";
import { IoIosMore } from "react-icons/io";
import {
  CloneButtonLink,
  EditButtonLink,
  ViewButtonLink,
  DeleteButton,
  ShippingButton,
} from "../../../components/editComponents";
import {
  DeleteDialog,
  Modal,
  SimpleDialog,
} from "../../../components/viewComponents";
import { shipSaleOrderRequest } from "../../../actions/saleOrderActions";
import SaleOrderShippingForm from "./SaleOrderShippingForm";
import { deleteSaleOrderRequest } from "../../../api/saleOrderServices";

function EditLink({ orderId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("new_sale_order") ? (
    <>
      {buttonView ? (
        <NavLink
          to={`/sale/order/edit/${orderId}`}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandGreen rounded border border-solid border-brandGreen hover:bg-brandGreen hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <AiFillEdit className="w-4 h-4 mr-2" /> Edit
        </NavLink>
      ) : (
        <EditButtonLink path={`/sale/order/edit/${orderId}`} />
      )}
    </>
  ) : (
    <></>
  );
}

function CloneLink({ orderId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("new_sale_order") ? (
    <>
      {buttonView ? (
        <NavLink
          to={`/sale/order/new`}
          state={orderId}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandYellow rounded border border-solid border-brandYellow hover:bg-brandYellow hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <MdFileCopy className="w-4 h-4 mr-2" /> Clone
        </NavLink>
      ) : (
        <CloneButtonLink path={`/sale/order/new`} data={orderId} />
      )}
    </>
  ) : (
    <></>
  );
}

function PreviewLink({ orderId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("new_sale_order") ? (
    <ViewButtonLink path={`/sale/order/preview/${orderId}`} />
  ) : (
    <></>
  );
}


function ShippingSaleOrderButton ({ orderId, shipSuccess, buttonView = false, marginRight = false }){
  const dispatch = useDispatch();
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const {
    loading: shippingLoading,
    error: shippingError,
    success: shippingSuccess,
  } = useSelector((state) => state.shipSaleOrder);

  const initialShippingValues = {
    shippingCompany: "",
    trackingNo: "",
  };

  const [shippingModal, setShippingModal] = useState(false);
  const [shippingDialog, setShippingDialog] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const openShippingModal = () => {
    setShippingModal(true);
  };

  const addShippingInformation = (shippingValues) => {
    setShippingModal(false);
    setShippingDialog(true);
    dispatch(shipSaleOrderRequest(shippingValues, orderId));
  };

  const closeDialogWithSuccess = () => {
    setShippingDialog(false);
    shipSuccess();
  };

  return (
    <div>
      <Modal
        title="Shipping"
        isModalOpen={shippingModal}
        closeModal={() => setShippingModal(false)}
        content={
          <SaleOrderShippingForm
            values={initialShippingValues}
            onSubmit={addShippingInformation}
            btnName="Ship Order"
          />
        }
      />
      <SimpleDialog
        title="Shipping"
        successMessage="Shipping information updated successfully."
        isDialogOpen={shippingDialog}
        closeDialog={() => setShippingDialog(false)}
        loading={shippingLoading}
        loadingMessage="Processing request..."
        success={shippingSuccess}
        error={shippingError}
        confirmAction={closeDialogWithSuccess}
      />
      {userDetailsSuccess ? (
        userDetails.data.permissions.includes("new_sale_order") ? (
          <>
          {buttonView ? (
            <button
              onClick={openShippingModal}
              className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandGreen rounded border border-solid border-brandGreen hover:bg-brandGreen hover:text-white ${
                marginRight ? "mr-2" : ""
              }`}
            >
              <MdLocalShipping className="w-4 h-4 mr-2" /> Ship Order
            </button>
          ) : (
            <ShippingButton action={openShippingModal} />
          )}
        </>

         
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

function DeleteOrderButton({
  orderId,
  deleteSuccess,
  buttonView = false,
  marginRight = false,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const openDeleteDialog = () => {
    setDeleteDialog(true);
  };
  const sendDeleteRequest = async () => {
    try {
      setDeleteStatus({
        ...deleteStatus,
        loading: true,
        success: false,
        error: "",
      });
      const responseData = await deleteSaleOrderRequest(orderId, config);

      if (responseData.success) {
        setDeleteStatus({
          ...deleteStatus,
          loading: false,
          success: true,
        });
      } else {
        throw "Delete sale order failed.";
      }
    } catch (error) {
      setDeleteStatus({
        ...deleteStatus,
        loading: false,
        error: error,
      });
    }
  };

  const closeDeleteDialogWithSuccess = () => {
    deleteSuccess();
    setDeleteDialog(false);
    setDeleteStatus({
      loading: false,
      success: false,
      error: "",
    });
  };

  return (
    <div>
      <DeleteDialog
        title="Delete Sale Order"
        preDeleteMess={`Please note that the delete action can not be undone. Are you sure you want to proceed?`}
        successMessage="Sale order deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={closeDeleteDialogWithSuccess}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      {userDetailsSuccess &&
      userDetails.data.permissions.includes("new_sale_order") ? (
        <>
          {buttonView ? (
            <button
              onClick={openDeleteDialog}
              className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-red-500 rounded border border-solid border-red-500 hover:bg-red-500 hover:text-white ${
                marginRight ? "mr-2" : ""
              }`}
            >
              <AiOutlineDelete className="w-4 h-4 mr-2" /> Delete
            </button>
          ) : (
            <DeleteButton action={openDeleteDialog} />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

function MoreActionLink({orderId}) {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <div className="relative">
      <div
        className="w-[44px] h-[44px] rounded flex items-center justify-center rounded border border-solid border-darkGray hover:bg-brandColor hover:text-white hover:cursor-pointer hover:border-brandColor"
        onClick={() => setFilterOpen(!filterOpen)}
      >
        <IoIosMore className="w-4 h-4" />
      </div>

      <div
        className={`${
          filterOpen ? "absolute block top-14 right-0 z-20 " : "hidden"
        }  min-w-[140px] bg-white shadow-lg p-4 rounded`}
      >
        <div className="w-full">
        <NavLink
          to={`/sale/order/after-approve-edit/${orderId}`}
          className={`w-full h-8 flex flex-row items-center justify-start hover:underline hover:text-brandColor`}
        >
          <AiFillEdit className="w-4 h-4 mr-2" />Edit
        </NavLink>
        </div>
      </div>
    </div>
  );
}

export {
  EditLink,
  CloneLink,
  PreviewLink,
  DeleteOrderButton,
  ShippingSaleOrderButton,
  MoreActionLink
};
