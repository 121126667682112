import React, { useEffect, useState } from "react";
import {
  SimpleBlueButton
} from "../../components/editComponents";
import {
  NoBorderInput,
  NoBorderPercentInput,
} from "../../components/inputFields";
import { validatePrice } from "../../functions/validate";
import commonStyle from "../../style/commonStyle.module.css";
import priceStyle from "./customPriceStyle.module.css";
import { SubTitle } from "../../components/viewComponents";

export default function CustomPriceForm({
  productBasic,
  initialPrices,
  saveCustomPrice,
}) {

  const [prices, setPrices] = useState([]);

  useEffect(() => {
    let copy = [...initialPrices];
    if (copy.length) {
      setPrices(copy);
    }
  }, [initialPrices]);

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  const handleTypeChange = (e) => {
    const { value, index } = e.target;

    let copy = [...prices];
    copy[index]["isStaticPrice"] = value;
    setPrices(copy);
  };

  const handlePriceChange = (e) => {
    const { key, value, index, priceTierId } = e.target;

    const validated = validatePrice(value);
    if (!validated) return;

    let pricesCopy = [...prices];

    let select_price_tier = pricesCopy[index];
    select_price_tier[key] = value;

    if (key === "discount") {
      let calculatedPrice = Number(
        Number(productBasic.salePrice) -
          (Number(value) / 100) * Number(productBasic.salePrice)
      );
      calculatedPrice = calculatedPrice.toFixed(2);
      calculatedPrice = Number(calculatedPrice);
      select_price_tier["discountedPrice"] = calculatedPrice;
    }

    setPrices(pricesCopy);
  };

  ////////////////////////////////  Submit//////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const checkValidation = () => {
    saveCustomPrice(prices);
  };

  return (
    <div className={commonStyle.longDataTable}>
      <div className="mt-4">
      <SubTitle name='Configure Product Custom Price' />
      <div className="p-4 rounded bg-green-50 mt-4 mb-2">
      <p>
      Use the table below to set and manage custom pricing for your products. You can choose to set up either a static custom price or a sale discount based on the basic sale price.
      </p>
      <p className="mt-2"><span className="font-bold text-brandColor">Static Price:</span>A fixed custom price that remains unchanged regardless of the basic sale price.</p>
      <p><span className="font-bold text-brandColor">Sale Discount:</span> A custom price calculated by applying a percentage discount to the basic price. For example, if the basic price is 100 and you apply a 10% discount, the final custom price will be 90.</p>

      <p className="mt-4 text-brandColor font-bold">When is the tier price used?</p>
      <p>The tier price is applied when creating a sales order. If a tier price is selected for the order, the default sale price for the product will automatically use the tier price instead of the basic sale price.</p>
      </div>
  
      </div>
      <table className={commonStyle.pageTable}>
        <thead>
          <tr>
            <th>Basic Sale Price</th>
            <th>Price Tier Name</th>
            <th>Custom Price Type</th>
            <th>Adjust Custom Price</th>
            <th>Final Custom Price</th>
          </tr>
        </thead>

        <tbody>
          {prices.length ? (
            prices.map((item, index) => (
              <tr>
                <td>
                  <div className="px-4">{productBasic.salePrice}</div>
                </td>
                <td>
                  <div>{item.name}</div>
                </td>

                <td className={priceStyle.priceTierContent}>
                  <div className={priceStyle.priceTypeCon}>
                    <span
                      className={
                        item.isStaticPrice
                          ? priceStyle.priceTypeCBtnActive
                          : priceStyle.priceTypeCBtn
                      }
                      onClick={() =>
                        handleTypeChange({
                          target: {
                            value: true,
                            index: index,
                          },
                        })
                      }
                    >
                      Static Price
                    </span>

                    <span
                      style={{ marginLeft: "8px" }}
                      className={
                        !item.isStaticPrice
                          ? priceStyle.priceTypeCBtnActive
                          : priceStyle.priceTypeCBtn
                      }
                      onClick={() =>
                        handleTypeChange({
                          target: {
                            value: false,
                            index: index,
                          },
                        })
                      }
                    >
                      Sale Discount
                    </span>
                  </div>
                </td>
                <td className={priceStyle.priceTierPriceCol}>
                  {item.isStaticPrice ? (
                    <div>
                      <NoBorderInput
                        type="text"
                        name="Attribute"
                        value={item.customPrice}
                        onChange={(price) =>
                          handlePriceChange({
                            target: {
                              key: "customPrice",
                              value: price,
                              priceTierId: item.id,
                              index: index,
                            },
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <NoBorderPercentInput
                        type="text"
                        name="discount"
                        value={item.discount}
                        onChange={(discount) =>
                          handlePriceChange({
                            target: {
                              key: "discount",
                              value: discount,
                              priceTierId: item.id,
                              index: index,
                            },
                          })
                        }
                      />
                    </div>
                  )}
                </td>
                <td className="w-[240px]">
                  <div className="px-8">
                    {" "}
                    {item.isStaticPrice
                      ? item.customPrice
                      : item.discountedPrice}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>

      <div className="w-full mt-6">
        <SimpleBlueButton action={checkValidation} name="Save Custom Price" />
      </div>
    </div>
  );
}
