import axios from "axios";
import {serviceMiddleware} from "./serviceMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;



 export const createUnitRequest = async (values, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/product/unit/create`, values, config);
    serviceMiddleware(response);
    return response;
  } catch (error) {
    throw error;
  }

};

export const updateUnitRequest = async (unitId, values, config) => {

  try {
    const response = await axios.put(SERVER_URL + `/product/unit/${unitId}`, values, config);
    return response;
  } catch (error) {
    throw error;
  }
};






