import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonStyle from "../../../style/commonStyle.module.css";
import Breadcrumbs from "../../../components/Breadcrumbs";
import settingsStyle from "../settingsStyle.module.css";
import CompanySettingsTab from "./CompanySettingsTab";
import {
  EditButton,
  AddButton,
  DefaultLabel,
  DeleteButton,
} from "../../../components/editComponents";
import {
  Modal,
  ErrorMessage,
  Loader,
  SimpleDialog,
  DeleteDialog,
} from "../../../components/viewComponents";
import LocationForm from "./LocationForm";
import {
  newLocationRequest,
  updateLocationRequest,
  locationListRequest,
  deleteLocationRequest,
} from "../../../actions/orgSettingsActions";
import useDocumentTitle from "../../../useDocumentTitle";

export default function CompanySettingsLocations() {
  useDocumentTitle("Location");
  const dispatch = useDispatch();

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  useEffect(() => {
    dispatch(locationListRequest());
  }, [dispatch]);

  const initialValues = {
    name: "",
    description: "",
    address: "",
    isDefault: false,
  };

  // const [newLocationValues, setNewLocationtValues] = useState();
  const [newModal, setNewModal] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const [editedLocation, setEditLocation] = useState("");

  const { loading, locations, error, success } = useSelector(
    (state) => state.locationList
  );
  // update location
  const {
    loading: upadteLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateLocation);

  // update location
  const {
    loading: newLoading,
    error: newError,
    success: newSuccess,
  } = useSelector((state) => state.newLocation);

  const openEditModal = (item) => {
    setEditLocation(item);
    setEditModal(true);
  };

  // mange dialog content, new and edit
  const [newDialog, setNewDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);

  const addNewLocation = (values) => {
    let locationValues = {
      locationName: values.name,
      locationAddress: values.address,
      locationDesc: values.description,
      isDefault: values.isDefault,
    };

    dispatch(newLocationRequest(locationValues));
    setNewModal(false);
    setNewDialog(true);
  };
  const editLocation = (values) => {
    let locationNewValues = {
      locationName: values.name,
      locationAddress: values.address,
      locationDesc: values.description,
      isDefault: values.isDefault,
    };
    dispatch(updateLocationRequest(locationNewValues, values.id));
    setEditModal(false);
    setUpdateDialog(true);
  };

  // delete
  const [deleteId, setDeleteId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = useSelector((state) => state.deleteLocation);

  const deleteAction = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = () => {
    dispatch(deleteLocationRequest(deleteId));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Location"
        isModalOpen={newModal}
        closeModal={() => setNewModal(false)}
        content={
          <LocationForm
            values={initialValues}
            onSubmit={addNewLocation}
            btnName="Save"
          />
        }
      />
      <Modal
        title="Update Location"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <LocationForm
            values={editedLocation}
            onSubmit={editLocation}
            btnName="Update"
          />
        }
      />

      <SimpleDialog
        title="Add New Location"
        successMessage="New location added sucessfully."
        isDialogOpen={newDialog}
        closeDialog={() => setNewDialog(false)}
        loading={newLoading}
        loadingMessage="Processing request..."
        success={newSuccess}
        error={newError}
        btnValue="Confirm"
        confirmAction={() => setNewDialog(false)}
      />

      <SimpleDialog
        title="Update Location"
        successMessage="Location updated sucessfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={upadteLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <DeleteDialog
        title="Delete Location"
        preDeleteMess="Please note that if you delete this location, the action can not be undone. Any products assigned to this location will have their location field cleared. If the location's address has changed, you can edit the location instead of deleting it. Are you sure you want to proceed with the deletion?"
        successMessage="Location deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={() => setDeleteDialog(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />
      <Breadcrumbs screenName="Location" />
      <div className={commonStyle.tabsContainer}>
        <CompanySettingsTab tabName="location" />

        <div className={commonStyle.settingsContent}>
          <div className={settingsStyle.subTitleContainer}>
            <h2>Location</h2>
          </div>

          {loading ? (
            <Loader mess="Requesting location list, please wait a moment..." />
          ) : success ? (
            <div className={settingsStyle.contentCon}>
              <div className={commonStyle.shortDataTable}>
              {userDetailsSuccess ? (
                  userDetails.data.permissions.includes(
                    "add_delete_edit_company_info"
                  ) ? (
                    userDetails.data.orgInfo.plan_name !== "start_up" ? (
                      <AddButton
                      name="New Location"
                      action={() => setNewModal(true)}
                    />
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

          

                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Location Name</th>
                      <th>Address</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locations.length > 0 ? (
                      locations.map((item) => (
                        <tr key={item.id}>
                          <td>
                            {item.name}{" "}
                            {item.isDefault ? (
                              <DefaultLabel name="Default" />
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>{item.address}</td>
                          <td>{item.description}</td>

                          <td>
                            <div className={commonStyle.actionsGrop}>
                              {userDetailsSuccess ? (
                                userDetails.data.permissions.includes(
                                  "add_delete_edit_company_info"
                                ) ? (
                                  <>
                                    <EditButton
                                      action={() => openEditModal(item)}
                                    />
                                    <DeleteButton
                                      action={() => deleteAction(item.id)}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          {" "}
                          <p>
                            There are no existing locations. Please add a new
                            location now.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <ErrorMessage mess={error} />
          )}
        </div>
      </div>
    </div>
  );
}
