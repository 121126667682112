import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  saleOrderListRequest
} from "../../../actions/saleOrderActions";
import { OrderAttachmentsPreview } from "../../../components/attachmentComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ScreenTitle
} from "../../../components/editComponents";
import { Search } from "../../../components/inputFields";
import Pagination from "../../../components/Pagination";
import {
  DollarTag,
  ErrorMessage,
  FailedSyncToXero,
  Loader,
  PriceText,
  SuccessSyncToXero,
  Tag,
  ViewDateText
} from "../../../components/viewComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import { CloneLink, DeleteOrderButton, EditLink, PreviewLink, ShippingSaleOrderButton } from "./soActionButtons";

export default function SaleOrderListScreen() {
  useDocumentTitle("Manage Sale Orders");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.userLogin);

  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, saleOrders, error, success } = useSelector(
    (state) => state.saleOrderList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  // search
  const [keyWords, setKeywords] = useState("");
  const [hasXeroInvoice, setHasXeroInvoice] = useState(false);

  const [refreshCnt, setRefreshCnt] = useState(0);

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userDetailsSuccess) {
      if (
        userDetails.data.orgInfo.xeroConfigured &&
        userDetails.data.orgInfo.xeroConnected
      ) {
        setHasXeroInvoice(true);
      }
    }
  }, [userDetails]);
  

  useEffect(() => {
    if (
      userDetailsSuccess &&
      userDetails.data.permissions.includes("sale_order_list")
    ) {
      dispatch(saleOrderListRequest(keyWords, pageNum, pageSize, "all"));
    } else {
      dispatch(saleOrderListRequest(keyWords, pageNum, pageSize, "creator"));
    }
  }, [
    dispatch,
    pageNum,
    keyWords,
    userDetails,
    refreshCnt
  ]);


  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };

  ////////////////////////////////////////////////////////////////////////////////////
  const deleteSuccessAction = () => {
    setRefreshCnt(refreshCnt + 1);
  };
  ////////////////////////////////////////////////////////////////////////////////////
  const addShippingInfoSuccessAction = () => {
    setRefreshCnt(refreshCnt + 1);
  };

  const naviogateToOrderDetails = (orderId) => {
    navigate(`/sale/order/preview/${orderId}`, { replace: false });
  }

  return (
    <div className={commonStyle.pageContainer}>
     
      <Breadcrumbs screenName={"All Sale Orders"} />
      <ScreenTitle title={"All Sale Orders"} />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "60%" }}>
          <Search
            placeholdertext="Type order number / invoice number/ customer name to search..."
            initialQuery={keyWords}
            onChange={handleSearchChange}
          />
        </div>
        {loading ? (
          <Loader mess="Requesting sale order list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  {/* <th>Order Type</th> */}
                  <th>Order Number</th>
                  <th>Create Date</th>
                  <th>Customer</th>
                  <th>Location</th>
                  <th>Total Price</th>
                  <th>Sales Person</th>
                  <th>Order Status</th>
                  <th>Shipping Status</th>
                  <th>Attachments</th>
                  {saleOrders.hasSuInvoice && <th>SU Invoice</th>}
                  {hasXeroInvoice && <th>Sent to Xero</th>}

       
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {saleOrders.orders.length > 0 ? (
                  saleOrders.orders.map((item, index) => (
                    <tr key={index}>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                      {item.orderNumber}
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        <ViewDateText value={item.createDate} />
                      </td>
                      <td style={{ width: "10%" }} onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        {item.noCustomer ? (
                          <Tag name="No Customer" color="gray" />
                        ) : (
                          item.customerName
                        )}
                      </td>
                      <td style={{ width: "10%" }} onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        <Tag name={item.locationName} color="gray" />
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        <DollarTag />
                        <PriceText num={item.totalPriceInclTax} />
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        {item.creatorFirstName}{" "}
                        {item.creatorLastName ? item.creatorLastName : ""}
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                      <div className="flex flex-row items-center justify-start">
                      <div>
                        {item.status === "Approved" ? (
                          <Tag name={item.status} color="green" />
                        ) : item.status === "Draft" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Back Order" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Completed" ? (
                          <Tag name={item.status} color="blue" />
                        ) : item.status === "Deleted" ? (
                          <Tag name={item.status} color="gray" />
                        ) : (
                          <></>
                        )}
                          </div>
                        {
                          item.sent && item.sent === 1 ?<div> <Tag name={"Sent"} color="blue" /> </div>: <></>
                        }
                        </div>
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                   
                        {item.status !== "Draft" ? (
                          item.shippingStatus === "Waiting" ? (
                            <Tag name="Waiting for shipping" color="yellow" />
                          ) : item.shippingStatus === "Not required" ? (
                            <Tag name="Not Required" color="gray" />
                          ) : item.shippingStatus === "Shipped" ? (
                            <Tag name="Shipped" color="blue" />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">{item.files && item.files.length > 0 ? <OrderAttachmentsPreview files={item.files} style="list"/> : <></>}</td>
                      {saleOrders.hasSuInvoice && (
                        <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">{item.suInvoiceNumber}</td>
                      )}
                      {hasXeroInvoice && (
                           <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                           {item.invoiceStatus === "ISSUED" ? (
                             <SuccessSyncToXero />
                           ) : item.xeroTaskError ? (
                             <FailedSyncToXero mess={item.xeroTaskError} />
                           ) : <></>}
                         </td>
                      )}
                      {/* according to different status show different actions */}
                      <td>
                        <div className={commonStyle.actionsGrop}>
                          {(item.status === "Draft" || item.status === "Back Order") &&  <EditLink orderId={item.id} buttonView={false}/>}
                          <PreviewLink orderId={item.id} buttonView={false}/>
                          {item.shippingStatus === "Waiting" && item.status === "Approved" && <ShippingSaleOrderButton orderId={item.id} buttonView={false} shipSuccess={addShippingInfoSuccessAction}/>}
                          <CloneLink orderId={item.id} buttonView={false}/>
                          {(item.status === "Draft" || item.status === "Back Order") && <DeleteOrderButton orderId={item.id} deleteSuccess={deleteSuccessAction} buttonView={false} />}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no order available, create a new order now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {saleOrders.orders.length > 0 ? (
              <Pagination
                totalPageNum={saleOrders.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
