import React, { useEffect, useState } from "react";
import {
  AiOutlineQuestionCircle,
  AiOutlineUser,
  AiOutlineMail,
} from "react-icons/ai";
import { BsFileImage, BsX } from "react-icons/bs";
import {
  MdArrowForward,
  MdCheckCircle,
  MdError,
  MdOutlineLocationOn,
  MdOutlineEmail,
  MdOutlineLocalPhone,
  MdOutlineErrorOutline,
} from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import { TailSpin } from "react-loader-spinner";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { formatDate, highlightText } from "../functions/functions";
import styledComponentsStyle from "../style/styledEditComponentsStyle.module.css";
import viewStyle from "../style/styledViewComponentsStyle.module.css";
import { SingleRatioButton } from "./editComponents";
import { TbArrowsSort } from "react-icons/tb";
import { SmallDialogButton } from "./buttons";
import { FaCheck } from "react-icons/fa6";

function Modal({ title, isModalOpen, closeModal, content }) {
  return (
    <div>
      <ReactModal
        isOpen={isModalOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.modalContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeModal}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>

          <div className={viewStyle.modalContent}>{content}</div>
        </div>
      </ReactModal>
    </div>
  );
}

function FullHeightModal({ title, isModalOpen, closeModal, content }) {
  return (
    <div>
      <ReactModal
        isOpen={isModalOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.fullHeightModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.modalContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeModal}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>

          <div className={viewStyle.modalContent_full}>{content}</div>
        </div>
      </ReactModal>
    </div>
  );
}

function FullHeightContentModal({ title, isModalOpen, closeModal, content }) {
  return (
    <div>
      <ReactModal
        isOpen={isModalOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.fullHeightConetntModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.modalContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeModal}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>

          <div className={viewStyle.modalContent_full}>{content}</div>
        </div>
      </ReactModal>
    </div>
  );
}

function ContentModal({
  title,
  isModalOpen,
  closeModal,
  content,
  closeOnOverlayClick,
}) {
  return (
    <div>
      <ReactModal
        isOpen={isModalOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.contentModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={closeOnOverlayClick}
      >
        <div className={viewStyle.modalContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeModal}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>

          <div className={viewStyle.modalContent_full}>{content}</div>
        </div>
      </ReactModal>
    </div>
  );
}

function SimpleDialog({
  title,
  successMessage,
  isDialogOpen,
  closeDialog,
  loading,
  success,
  error,
  loadingMessage,
  btnValue,
  confirmAction,
}) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={false}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>
          {loading ? (
            <Loader mess={loadingMessage} />
          ) : success ? (
            <>
              <p className={viewStyle.dialogSuccessMessage}>{successMessage}</p>
              <div className={viewStyle.confirmBtnCon} onClick={confirmAction}>
                <div className={viewStyle.confirmBtn}>OK</div>
              </div>
            </>
          ) : (
            <>
              <ErrorMessage mess={error} />
              <div className={viewStyle.confirmBtnCon} onClick={closeDialog}>
                <div className={viewStyle.confirmBtn}>OK</div>
              </div>
            </>
          )}
        </div>
      </ReactModal>
    </div>
  );
}

function SuccessDialog({
  title,
  successMessage,
  isDialogOpen,
  closeDialog,
  btnValue,
  confirmAction,
}) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={false}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>

          <p className={viewStyle.dialogSuccessMessage}>{successMessage}</p>
          <div className={viewStyle.confirmBtnCon} onClick={confirmAction}>
            <div className={viewStyle.confirmBtn}>OK</div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function RecievStockDialog({
  title,
  successMessage,
  isDialogOpen,
  closeDialog,
  closeDialogWithSuccess,
  loading,
  success,
  error,
  loadingMessage,
  partiallyReceived,
  confirmAction,
}) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const [showKeepReceiveOption, setKeepReceiveOption] = useState(false);

  const [beforeProceed, setBeforeProceed] = useState(true);
  const [isSplitOrder, setSplitOrder] = useState(true);

  const proceedAction = () => {
    setBeforeProceed(false);
    if (partiallyReceived) {
      confirmAction(isSplitOrder);
    } else {
      confirmAction();
    }
  };

  const nextAction = () => {
    setKeepReceiveOption(true);
  };

  const closeDialogAction = () => {
    setKeepReceiveOption(false);
    setBeforeProceed(true);
    closeDialog();
  };

  const ProceedText = () => {
    return beforeProceed ? (
      <div>
        <p className={viewStyle.textLabel}>
          After click the confirm button, the following actions will be proceed:
        </p>

        {userDetailsSuccess &&
        userDetails.data.orgInfo.xeroConnected &&
        userDetails.data.orgInfo.xeroConfigured ? (
          <div className={viewStyle.proceedActionsCon}>
            <MdCheckCircle />
            <p>
              The stock level will be updated.(For inventory tracked items in
              your Xero system, the stock on hand quantity will be also
              updated).
            </p>
          </div>
        ) : (
          <div className={viewStyle.proceedActionsCon}>
            <MdCheckCircle />
            <p>The stock on hand quantity will be updated.</p>
          </div>
        )}
        <div className={viewStyle.proceedActionsCon}>
          <MdCheckCircle />{" "}
          <p>The latest cost of the product will be updated.</p>
        </div>

        {userDetailsSuccess &&
        userDetails.data.orgInfo.xeroConnected &&
        !userDetails.data.orgInfo.xeroConfigured ? (
          <div className={viewStyle.proceedActionsCon}>
            <p style={{ color: "var(--delete-btn)" }}>
              Your account is connected with Xero, but it appears that account
              mappings with Xero have not been set up. As a result, any stock
              quantities changed will not be reflected in your Xero system. Do
              you still wish to proceed?
            </p>
          </div>
        ) : (
          <></>
        )}

        {/* <div className={viewStyle.proceedActionsCon}>
          <MdCheckCircle /><p>The amount with received quantity will send to Xero, and a payable bill will be automatically created in your xero account.</p>
          </div> */}

        <div className={viewStyle.confirmBtnCon}>
          <div className={viewStyle.confirmBtn} onClick={proceedAction}>
            Confirm
          </div>
          <div className={viewStyle.cancelBtn} onClick={closeDialogAction}>
            Cancel
          </div>
          {userDetailsSuccess &&
          userDetails.data.orgInfo.xeroConnected &&
          !userDetails.data.orgInfo.xeroConfigured ? (
            <XeroAccountMappingLink />
          ) : (
            <></>
          )}
        </div>
      </div>
    ) : loading ? (
      <Loader mess="Proceeding stock reveive...." />
    ) : success ? (
      <div>
        <p className={viewStyle.dialogSuccessMessage}>{successMessage}</p>
        <div className={viewStyle.confirmBtnCon}>
          <div
            className={viewStyle.confirmBtn}
            onClick={closeDialogWithSuccess}
          >
            Ok
          </div>
        </div>
      </div>
    ) : (
      <>
        <ErrorMessage mess={error} />
        <div className={viewStyle.confirmBtnCon} onClick={closeDialogAction}>
          <div className={viewStyle.confirmBtn}>OK</div>
        </div>
      </>
    );
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialogAction}
        shouldCloseOnOverlayClick={false}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>

          {partiallyReceived ? (
            !showKeepReceiveOption ? (
              <div>
                <p className={viewStyle.partialtext}>
                  The received stock quantity is less than ordered.
                </p>
                <p className={viewStyle.textLabel}>Split purchase order ?</p>
                <SingleRatioButton
                  label="Keep receiving the remainning products"
                  name="isSplitOrder"
                  isOn={isSplitOrder}
                  onChange={(isSplitOrder) => setSplitOrder(isSplitOrder)}
                />
                <p className={viewStyle.splitHinText}>
                  A new purchase order will be created with the unreceived
                  quantity
                </p>
                <SingleRatioButton
                  label="Complete the order with partially received status"
                  name="notSplitOrder"
                  isOn={!isSplitOrder}
                  onChange={(isSplitOrder) => setSplitOrder(!isSplitOrder)}
                />

                <div className={viewStyle.confirmBtnCon}>
                  <div className={viewStyle.confirmBtn} onClick={nextAction}>
                    Next
                  </div>
                </div>
              </div>
            ) : (
              <ProceedText />
            )
          ) : (
            <ProceedText />
          )}
        </div>
      </ReactModal>
    </div>
  );
}

function ProceedStockAdjustDialog({
  title,
  successMessage,
  isDialogOpen,
  closeDialog,
  closeDialogWithSuccess,
  loading,
  success,
  error,
  loadingMessage,
  confirmAction,
}) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const [beforeProceed, setBeforeProceed] = useState(true);

  const proceedAction = () => {
    setBeforeProceed(false);
    confirmAction();
  };

  const closeDialogAction = () => {
    setBeforeProceed(true);
    closeDialog();
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialogAction}
        shouldCloseOnOverlayClick={false}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>
          <div>
            {beforeProceed ? (
              <div>
                <p className={viewStyle.textLabel}>
                  After click the confirm button, the following actions will be
                  proceed:
                </p>
                <div className={viewStyle.proceedActionsCon}>
                  <MdCheckCircle />
                  <p>The stock level will be adjusted.</p>
                </div>
                {userDetailsSuccess &&
                userDetails.data.orgInfo.xeroConnected &&
                userDetails.data.orgInfo.xeroConfigured ? (
                  <div className={viewStyle.proceedActionsCon}>
                    <MdCheckCircle />
                    <p>
                      The adjusted quantity will be updated within your Xero
                      system(Only for inventory tracked items).
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                {userDetailsSuccess &&
                userDetails.data.orgInfo.xeroConnected &&
                !userDetails.data.orgInfo.xeroConfigured ? (
                  <div className={viewStyle.proceedActionsCon}>
                    <p style={{ color: "var(--delete-btn)" }}>
                      Your account is connected with Xero, but it appears that
                      account mappings with Xero have not been set up. As a
                      result, any stock adjustment quantities will not be
                      reflected in your Xero system. Do you still wish to
                      proceed?
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                <div className={viewStyle.confirmBtnCon}>
                  <div className={viewStyle.confirmBtn} onClick={proceedAction}>
                    Confirm
                  </div>
                  <div
                    className={viewStyle.cancelBtn}
                    onClick={closeDialogAction}
                  >
                    Cancel
                  </div>
                  {userDetailsSuccess &&
                  userDetails.data.orgInfo.xeroConnected &&
                  !userDetails.data.orgInfo.xeroConfigured ? (
                    <XeroAccountMappingLink />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : loading ? (
              <Loader mess={loadingMessage} />
            ) : success ? (
              <div>
                <p className={viewStyle.dialogSuccessMessage}>
                  {successMessage}
                </p>
                <div className={viewStyle.confirmBtnCon}>
                  <div
                    className={viewStyle.confirmBtn}
                    onClick={closeDialogWithSuccess}
                  >
                    Ok
                  </div>
                </div>
              </div>
            ) : error ? (
              <>
                <ErrorMessage mess={error} />
                <div
                  className={viewStyle.confirmBtnCon}
                  onClick={closeDialogAction}
                >
                  <div className={viewStyle.confirmBtn}>OK</div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function InsufficientStockSaleOrderDialog({
  title,
  successMessage,
  isDialogOpen,
  closeDialog,
  loading,
  success,
  error,
  loadingMessage,
  confirmAction,
  closeDialogWithSuccess,
}) {
  const [beforeProceed, setBeforeProceed] = useState(true);
  const proceedAction = (savetype) => {
    setBeforeProceed(false);
    confirmAction(savetype);
  };
  const closeDialogAction = () => {
    setBeforeProceed(true);
    closeDialog();
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialogAction}
        shouldCloseOnOverlayClick={false}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>

          <div>
            {beforeProceed ? (
              <div>
                <p className="text-red-500 text-base">
                  You do not have sufficient stock to fullfill this sale order,
                  you can save this order to "Draft" or "Back Order"
                </p>

                <div className="flex flex-row items-center mt-4">
                  <SmallDialogButton
                    name={"Save As Draft"}
                    action={() => proceedAction("Draft")}
                    marginRight={true}
                  />
                  <SmallDialogButton
                    name={"Save As Back Order"}
                    action={() => proceedAction("Back Order")}
                    marginRight={true}
                  />

                  <SmallDialogButton
                    name={"Cancel"}
                    action={closeDialogAction}
                    marginRight={false}
                  />
                </div>
              </div>
            ) : loading ? (
              <Loader mess={loadingMessage} />
            ) : success ? (
              <div>
                <p className={viewStyle.dialogSuccessMessage}>
                  {successMessage}
                </p>
                <div className={viewStyle.confirmBtnCon}>
                  <div
                    className={viewStyle.confirmBtn}
                    onClick={closeDialogWithSuccess}
                  >
                    Ok
                  </div>
                </div>
              </div>
            ) : error ? (
              <>
                <ErrorMessage mess={error} />
                <div
                  className={viewStyle.confirmBtnCon}
                  onClick={closeDialogAction}
                >
                  <div className={viewStyle.confirmBtn}>OK</div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function ProceedSaleOrderDialog({
  title,
  successMessage,
  isDialogOpen,
  closeDialog,
  closeDialogWithSuccess,
  loading,
  success,
  error,
  loadingMessage,
  type,
  confirmAction,
  text1,
  text2,
}) {
  //  insufficientStock will always false, but when add back order feature in the future, this parameter will be used.
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const [beforeProceed, setBeforeProceed] = useState(true);

  const proceedAction = () => {
    setBeforeProceed(false);
    confirmAction();
  };

  const closeDialogAction = () => {
    setBeforeProceed(true);
    closeDialog();
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialogAction}
        shouldCloseOnOverlayClick={false}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>

          <div>
            {beforeProceed ? (
              <div>
                <p className={viewStyle.textLabel}>
                  After click the confirm button, the following actions will be
                  proceed:
                </p>
                <div className={viewStyle.proceedActionsCon}>
                  <div className="w-4 h-4 mr-1">
                    <MdCheckCircle className="w-4 h-4" />
                  </div>
                  <p>{text1}</p>
                </div>
                {userDetailsSuccess &&
                userDetails.data.orgInfo.xeroConnected &&
                userDetails.data.orgInfo.xeroConfigured ? (
                  <div className={viewStyle.proceedActionsCon}>
                    <div className="w-4 h-4 mr-1">
                      <MdCheckCircle className="w-4 h-4" />
                    </div>
                    <p style={{ flexGrow: 1 }}>{text2}</p>
                  </div>
                ) : (
                  <></>
                )}
                {userDetailsSuccess &&
                userDetails.data.orgInfo.xeroConnected &&
                !userDetails.data.orgInfo.xeroConfigured ? (
                  <div className={viewStyle.proceedActionsCon}>
                    <p style={{ color: "var(--delete-btn)" }}>
                      Your account is connected with Xero, but it appears that
                      account mappings with Xero have not been set up. As a
                      result, there is no invoice will be created in your Xero
                      system, and the stock change quantities will not be
                      reflected in your Xero system. Do you still wish to
                      proceed?
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                <div className={viewStyle.confirmBtnCon}>
                  <div className={viewStyle.confirmBtn} onClick={proceedAction}>
                    Confirm
                  </div>
                  <div
                    className={viewStyle.cancelBtn}
                    onClick={closeDialogAction}
                  >
                    Cancel
                  </div>
                  {userDetailsSuccess &&
                  userDetails.data.orgInfo.xeroConnected &&
                  !userDetails.data.orgInfo.xeroConfigured ? (
                    <XeroAccountMappingLink />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : loading ? (
              <Loader mess={loadingMessage} />
            ) : success ? (
              <div>
                <p className={viewStyle.dialogSuccessMessage}>
                  {successMessage}
                </p>
                <div className={viewStyle.confirmBtnCon}>
                  <div
                    className={viewStyle.confirmBtn}
                    onClick={closeDialogWithSuccess}
                  >
                    Ok
                  </div>
                </div>
              </div>
            ) : error ? (
              <>
                <ErrorMessage mess={error} />
                <div
                  className={viewStyle.confirmBtnCon}
                  onClick={closeDialogAction}
                >
                  <div className={viewStyle.confirmBtn}>OK</div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function DeleteDialog({
  title,
  preDeleteMess,
  successMessage,
  isDialogOpen,
  closeDialog,
  loading,
  success,
  error,
  loadingMessage,
  deleteAction,
  closeDialogWithSuccess,
}) {
  const [beforeDelete, setBeforeDel] = useState(true);

  const deleteItem = () => {
    setBeforeDel(false);
    deleteAction();
  };

  const closeDialogAction = () => {
    setBeforeDel(true);
    closeDialog();
  };

  const closeDialogSuccess = () => {
    setBeforeDel(true);
    closeDialogWithSuccess();
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={false}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>

          {beforeDelete ? (
            <>
              <p className={viewStyle.preDelMess}>{preDeleteMess}</p>
              <div className={viewStyle.delBtnCon}>
                <div className={viewStyle.delBtn} onClick={deleteItem}>
                  Delete
                </div>
                <div
                  className={viewStyle.cancelBtn}
                  onClick={closeDialogAction}
                >
                  Cancel
                </div>
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <Loader mess={loadingMessage} />
              ) : success ? (
                <>
                  <p className={viewStyle.dialogSuccessMessage}>
                    {successMessage}
                  </p>
                  <div
                    className={viewStyle.confirmBtnCon}
                    onClick={closeDialogSuccess}
                  >
                    <div className={viewStyle.confirmBtn}>OK</div>
                  </div>
                </>
              ) : (
                <>
                  <ErrorMessage mess={error} />
                  <div
                    className={viewStyle.confirmBtnCon}
                    onClick={closeDialogAction}
                  >
                    <div className={viewStyle.confirmBtn}>OK</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </ReactModal>
    </div>
  );
}

function DeleteTrialAccountDataDialog({
  title,
  successMessage,
  isDialogOpen,
  closeDialog,
  loading,
  success,
  error,
  deleteAction,
}) {
  //  insufficientStock will always false, but when add back order feature in the future, this parameter will be used.

  const [productsData, setProductsData] = useState(true);
  const [customersData, setCustomersData] = useState(true);
  const [suppliersData, setSuppliersData] = useState(true);
  const [categoriesData, setCategoriesData] = useState(true);

  const [typeError, setTypeError] = useState("");

  const [beforeProceed, setBeforeProceed] = useState(true);

  const proceedAction = () => {
    let dataTypes = {
      productsData: productsData,
      customersData: customersData,
      suppliersData: suppliersData,
      categoriesData: categoriesData,
    };
    // Check if at least one property is true
    const atLeastOneTrue = Object.values(dataTypes).some(
      (value) => value === true
    );
    if (!atLeastOneTrue) {
      setTypeError("Please select at least one type of data to proceed");
      return;
    }
    deleteAction(dataTypes);
    setBeforeProceed(false);
  };

  const closeDialogAction = () => {
    closeDialog();
    setBeforeProceed(true);
  };

  const ProceedText = () => {
    return beforeProceed ? (
      <div>
        <p className={viewStyle.textLabel}>
          After click the confirm button, the following data will be deleted:
        </p>
        {typeError ? (
          <div>
            <TextErrorMessage mess={typeError} />
          </div>
        ) : (
          <></>
        )}
        <div>
          <SingleRatioButton
            label="All products data(includes all related orders and custom prices)"
            name="productsData"
            isOn={productsData}
            onChange={(checked) => setProductsData(checked)}
          />
          <SingleRatioButton
            label="Product categories"
            name="categoriesData"
            isOn={categoriesData}
            onChange={(checked) => setCategoriesData(checked)}
          />
          <SingleRatioButton
            label="All customers"
            name="customersData"
            isOn={customersData}
            onChange={(checked) => setCustomersData(checked)}
          />
          <SingleRatioButton
            label="All suppliers"
            name="suppleirsData"
            isOn={suppliersData}
            onChange={(checked) => setSuppliersData(checked)}
          />
        </div>

        <div className={viewStyle.confirmBtnCon}>
          <div className={viewStyle.confirmBtn} onClick={proceedAction}>
            Confirm
          </div>
          <div className={viewStyle.cancelBtn} onClick={closeDialogAction}>
            Cancel
          </div>
        </div>
      </div>
    ) : loading ? (
      <Loader mess="Processing request...." />
    ) : success ? (
      <div>
        <p className={viewStyle.dialogSuccessMessage}>{successMessage}</p>
        <div className={viewStyle.confirmBtnCon}>
          <div className={viewStyle.confirmBtn} onClick={closeDialogAction}>
            Ok
          </div>
        </div>
      </div>
    ) : (
      <>
        <ErrorMessage mess={error} />
        <div className={viewStyle.confirmBtnCon} onClick={closeDialogAction}>
          <div className={viewStyle.confirmBtn}>OK</div>
        </div>
      </>
    );
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialogAction}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>

          <ProceedText />
        </div>
      </ReactModal>
    </div>
  );
}

function BatchSerialNumberViewDialog({
  title,
  numbers,
  isDialogOpen,
  closeDialog,
}) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>

          <div className="mb-4">
            {numbers.map((item, index) => (
              <span className="text-base w-fit px-3 py-1 bg-lightbg rounded-full my-4 mr-4">
                {item}
              </span>
            ))}
          </div>

          <div className={viewStyle.confirmBtnCon} onClick={closeDialog}>
            <div className={viewStyle.confirmBtn}>OK</div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function Loader({ mess }) {
  return (
    <div className={viewStyle.loader}>
      <TailSpin color="#4683FF" height={20} width={20} />
      <p className={viewStyle.loaderMessage}>{mess}</p>
    </div>
  );
}

function ErrorMessage({ mess }) {
  return (
    <div className={viewStyle.errorMesContainer}>
      <MdError />
      <p className={viewStyle.errorMessage}>
        {mess && typeof mess === "object"
          ? mess.message
          : typeof mess === "string"
          ? mess
          : ""}
      </p>
    </div>
  );
}

function TextErrorMessage({ mess }) {
  return (
    <div className={viewStyle.errorMesContainer}>
      <MdError />
      <p className={viewStyle.errorMessage}>{mess}</p>
    </div>
  );
}

function FadeErrorMessage({ mess }) {
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [mess]);

  return (
    <div
      className={
        visible
          ? viewStyle.fadeErrorContainer
          : `${viewStyle.fadeErrorContainer} ${viewStyle.hide}`
      }
    >
      <MdError />
      <p className={viewStyle.errorMessage}>{mess}</p>
    </div>
  );
}

function ErrorText({ mess }) {
  return <p className={viewStyle.errorMessageText}>{mess}</p>;
}

function SuccessText({ mess }) {
  return <p className={viewStyle.successMessageText}>{mess}</p>;
}

function PasswordInput({ label, type, name, onChange, autoFocus }) {
  const [password, setPassword] = useState("");

  // handle password field changes
  const [hint1, setHint1] = useState(viewStyle.hintError);
  const [hint2, setHint2] = useState(viewStyle.hintError);
  const [hint3, setHint3] = useState(viewStyle.hintError);
  const [hint4, setHint4] = useState(viewStyle.hintError);
  const [hint5, setHint5] = useState(viewStyle.hintError);

  const handlePasswordChange = (e) => {
    const { value } = e.target.value;
    setPassword(value);
    onChange(value);
    const lowerCaseCount = (value.match(/[a-z]/g) || []).length;
    const upperCaseCount = (value.match(/[A-Z]/g) || []).length;
    const numberCount = (value.match(/\d/g) || []).length;
    const specialCount = (value.match(/[^\d\w]/g) || []).length;
    setHint1(value.length > 7 ? viewStyle.hintPassed : viewStyle.hintError);
    setHint4(lowerCaseCount > 0 ? viewStyle.hintPassed : viewStyle.hintError);
    setHint3(upperCaseCount > 0 ? viewStyle.hintPassed : viewStyle.hintError);
    setHint2(numberCount > 0 ? viewStyle.hintPassed : viewStyle.hintError);
    setHint5(specialCount > 0 ? viewStyle.hintPassed : viewStyle.hintError);
  };

  let className = styledComponentsStyle.inputFieldError;
  // if (error) {
  //   className = styledComponentsStyle.inputFieldError;
  // } else {
  //   className = styledComponentsStyle.inputField;
  // }

  return (
    <div>
      <div className={styledComponentsStyle.styledInput}>
        <label className={styledComponentsStyle.inputLabel}>{label}</label>
        <input
          type={type}
          name={name}
          value={password}
          className={className}
          autoFocus={autoFocus}
          onChange={(e) => handlePasswordChange(e)}
        ></input>
        <p className={styledComponentsStyle.errorMessage}>"error"</p>
      </div>

      <div className={viewStyle.passwordHints}>
        <div className={hint1}>
          <MdCheckCircle />
          <p style={{ marginLeft: "2px" }}>At least 8 characters</p>
        </div>
        <div className={hint2}>
          <MdCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 number</p>
        </div>
        <div className={hint3}>
          <MdCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 uppercase letter</p>
        </div>
        <div className={hint4}>
          <MdCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 lowercase letter</p>
        </div>
        <div className={hint5}>
          <MdCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 special character</p>
        </div>
      </div>
    </div>
  );
}

function RoleTag({ userRoles }) {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {userRoles.map((x, index) => (
        <span key={index} className={viewStyle.roleTag}>
          {x}
        </span>
      ))}
    </div>
  );
}

function RoleListTag({ userRoles }) {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {userRoles.map((x, index) => (
        <span key={index} className={viewStyle.roleTag}>
          {x.label}
        </span>
      ))}
    </div>
  );
}

function ViewPreText({ text }) {
  return <pre className={viewStyle.viewPreText}>{text}</pre>;
}

function ViewText({ title, value }) {
  let valueClassName;
  if (value === "null") {
    valueClassName = viewStyle.nullValue;
  } else {
    valueClassName = viewStyle.textValue;
  }
  return (
    <div className={viewStyle.viewTextCon}>
      <p className={viewStyle.viewTextTitle}>{title}</p>
      <p className={valueClassName}> {value}</p>
    </div>
  );
}

function ViewLabel({ title }) {
  return <p className={viewStyle.viewTextTitle}>{title}</p>;
}

function TipsText({ text }) {
  return <p className={viewStyle.tipsText}>{text}</p>;
}

function ViewDateWithTitle({ title, value }) {
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  let format = detailsSuccess
    ? userDetails.data.orgInfo.date_format
    : "DD/MM/YYYY";
  let timeZone = detailsSuccess
    ? userDetails.data.orgInfo.time_zone
    : "Pacific/Auckland";

  let valueClassName;
  if (value === "null") {
    valueClassName = viewStyle.nullValue;
  } else {
    valueClassName = viewStyle.textValue;
  }
  return (
    <div className={viewStyle.viewTextCon}>
      <p className={viewStyle.viewTextTitle}>{title}</p>
      <p className={valueClassName}>
        {" "}
        {value ? formatDate(new Date(value * 1000), format, timeZone) : ""}
      </p>
    </div>
  );
}

function ViewDateWithTime({ value }) {
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  let format = detailsSuccess
    ? userDetails.data.orgInfo.date_format
    : "DD/MM/YYYY";
  let timeZone = detailsSuccess
    ? userDetails.data.orgInfo.time_zone
    : "Pacific/Auckland";

  const withTime = true;

  let valueClassName;
  if (value === "null") {
    valueClassName = viewStyle.nullValue;
  } else {
    valueClassName = viewStyle.textValue;
  }
  return (
    <div className={viewStyle.viewTextCon}>
      <p className={valueClassName}>
        {" "}
        {formatDate(new Date(value * 1000), format, timeZone, withTime)}
      </p>
    </div>
  );
}

// only text no tyle
function ViewDateText({ value }) {
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  let format = detailsSuccess
    ? userDetails.data.orgInfo.date_format
    : "DD/MM/YYYY";
  let timeZone = detailsSuccess
    ? userDetails.data.orgInfo.time_zone
    : "Pacific/Auckland";

  return <div>{formatDate(new Date(value * 1000), format, timeZone)}</div>;
}

function FromTag({ name }) {
  return <span className={viewStyle.fromTag}>{name}</span>;
}

function DollarTag() {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  return (
    <span>
      {userDetailsSuccess && userDetails
        ? userDetails.data.orgInfo.base_currency === "GBP"
          ? "£"
          : userDetails.data.orgInfo.base_currency === "EUR"
          ? "€"
          : "$"
        : "$"}
    </span>
  );
}

function SubTitle({ name }) {
  return <p className=" text-brandColor font-medium">{name}</p>;
}

function Tag({ name, color }) {
  let className;
  if (color === "green") {
    className = viewStyle.greenTag;
  }
  if (color === "gray") {
    className = viewStyle.grayTag;
  }
  if (color === "yellow") {
    className = viewStyle.yellowTag;
  }
  if (color === "blue") {
    className = viewStyle.blueTag;
  }
  if (color === "brandBlue") {
    className = viewStyle.brandBlueTag;
  }
  if (color === "red") {
    className = viewStyle.redTag;
  }
  if (color === "lightGreen") {
    className = viewStyle.lightGreenTag;
  }
  return <span className={`${viewStyle.tagCon} ${className}`}>{name}</span>;
}

function AdjustTypeTag({ name }) {
  let className = "";
  let displayName = "";

  if (name === "RECEIVESTOCK") {
    className = "text-sm px-2 py-1 bg-green-50 text-green-500 rounded-full";
    displayName = "Receive Stock";
  } else if (name === "STOCKDEDUCTION") {
    className = "text-sm px-2 py-1 bg-blue-50 text-brandColor rounded-full";
    displayName = "Deduct Stock";
  } else {
    className = "text-sm px-2 py-1 bg-blue-50 text-brandColor rounded-full";
    displayName = "Unknown";
  }

  return (
    <span className={`${viewStyle.tagCon} ${className}`}>{displayName}</span>
  );
}

function VariantTag({ name }) {
  return <span className={viewStyle.variantTag}>{name}</span>;
}
function VariantSkuTag({ name }) {
  return <span className={viewStyle.variantSkuTag}>{name}</span>;
}

function HightlightKeywords({ keywords, text }) {
  // Split text on highlight term, include term itself into parts, ignore case

  return <p>{highlightText(text, keywords)}</p>;
}

function PriceText({ num }) {
  let value = (Math.round(num * 100) / 100).toFixed(2);
  return <span>{value}</span>;
}

// edit button component
function QuestionMark({ title, text }) {
  return (
    <div className={viewStyle.questionMarkCon}>
      {title ? <p>{title}</p> : <></>}
      <AiOutlineQuestionCircle className="w-4 h-4 ml-1" />
      <div className={viewStyle.questionMarkText}>{text}</div>
    </div>
  );
}

function ProductImage({ alt, src }) {
  return (
    <div className={viewStyle.imageContainer}>
      <div className={viewStyle.productImage}>
        {src ? (
          <img alt={alt} src={src} className={viewStyle.productImagePreview} />
        ) : (
          <BsFileImage className={viewStyle.defaultImage} />
        )}
      </div>
    </div>
  );
}

function SmallProductImage({ alt, src }) {
  return (
    <div className="w-full h-full ">
      <div className="w-full h-full flex flex-row items-center justify-center">
        {src ? (
          <img
            alt={alt}
            src={src}
            className="w-full h-full"
            style={{ objectFit: "contain" }}
          />
        ) : (
          <div className="w-full h-full bg-lightGray flex flex-row items-center justify-center">
            <BsFileImage className="w-1/2 h-1/2 text-borderGray" />
          </div>
        )}
      </div>
    </div>
  );
}

function GuideLink({ name, src }) {
  return (
    <a
      href={`https://stockunify.com/legal/privacy-policy`}
      className={viewStyle.termLink}
      style={{ marginTop: "6px" }}
      target="_blank"
      rel="noreferrer"
    >
      Mapping Xero account guide
    </a>
  );
}

function BlurColorBackground() {
  return (
    <>
      <div className={viewStyle.modalColorBgCircle1}></div>
      <div className={viewStyle.modalColorBgCircle2}></div>
      <div className={viewStyle.modalColorBgCircle3}></div>
      <div className={viewStyle.modalColorBgCircle4}></div>
    </>
  );
}

function MappingXeroAccountDialog({
  title,
  isDialogOpen,
  closeDialog,
  proceedAction,
}) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const closeDialogAction = () => {
    closeDialog();
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialogAction}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>

          <div>
            {userDetailsSuccess &&
            userDetails.data.orgInfo.xeroConnected &&
            !userDetails.data.orgInfo.xeroConfigured ? (
              <div className={viewStyle.proceedActionsCon}>
                <p style={{ color: "var(--delete-btn)" }}>
                  Your account is connected with Xero, but it appears that
                  account mappings with Xero have not been set up. As a result,
                  any updated of the product details will not synchronize with
                  your Xero system. Do you still wish to proceed?
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className={viewStyle.confirmBtnCon}>
              <div className={viewStyle.confirmBtn} onClick={proceedAction}>
                Confirm
              </div>
              <div className={viewStyle.cancelBtn} onClick={closeDialogAction}>
                Cancel
              </div>
              {userDetailsSuccess &&
              userDetails.data.orgInfo.xeroConnected &&
              !userDetails.data.orgInfo.xeroConfigured ? (
                <XeroAccountMappingLink />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function XeroAccountMappingLink() {
  return (
    <NavLink
      to="/integration/xero"
      className={viewStyle.xeroLinkBtn}
      style={{ textDecoration: "none", marginLeft: "12px" }}
    >
      <div className={styledComponentsStyle.xeroLogo}></div>
      Mapping Xero Accounts
    </NavLink>
  );
}

function XeroAccountMappingTip({ pageName }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  return (
    <>
      {userDetailsSuccess &&
      userDetails.data.orgInfo.xeroConnected &&
      !userDetails.data.orgInfo.xeroConfigured ? (
        <div
          style={{
            padding: "16px 10px 10px 10px",
            borderRadius: "4px",
            border: "1px solid var(--border-color)",
            backgroundColor: "var(--white-color)",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={styledComponentsStyle.xeroLogo}></div>{" "}
            <span>Xero Connected</span>
          </div>

          <p style={{ color: "var(--delete-btn)", marginTop: "12px" }}>
            {`Your account is connected with Xero, but it appears that account
            mappings with Xero have not been set up. As a result, any updated of
            the ${
              pageName === "saleOrder"
                ? "sale order"
                : pageName === "purchaseOrder"
                ? "purchase order"
                : pageName === "productDetails"
                ? "product details"
                : pageName === "stockAdjust"
                ? " stock change"
                : "data change"
            } will not synchronize with your Xero system.`}
          </p>
          <div style={{ width: "fit-content" }}>
            <NavLink
              to="/integration/xero"
              className={viewStyle.xeroLinkBtn}
              style={{ textDecoration: "none" }}
            >
              Mapping Xero Accounts
            </NavLink>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

function XeroSmallLogo() {
  return <div className={styledComponentsStyle.xeroLogo}></div>;
}

function MoreLink({ href }) {
  return (
    <div>
      <NavLink to={href} className="flex items-center justify-center">
        <span>More</span>
        <MdArrowForward />
      </NavLink>
    </div>
  );
}

function SortedTableTitle({ name, options, onSelect }) {
  const [sortMenuOpen, setSortMenuOpen] = useState(false);

  const handleSelect = (item) => {
    setSortMenuOpen(false);
    onSelect(item);
  };

  const SortItem = ({ name, onClick }) => {
    return (
      <p
        className="px-2 py-2 mt-2 hover:bg-lightbg hover:cursor-pointer rounded"
        onClick={onClick}
      >
        {name}
      </p>
    );
  };
  return (
    <div className="relative">
      <button
        onClick={() => setSortMenuOpen(!sortMenuOpen)}
        className="rounded p-1 hover:bg-tableHeaderHover hover:cursor-pointer flex items-center justify-center"
      >
        <span>{name}</span> <TbArrowsSort className="w-4 h-4 ml-1" />
      </button>
      {sortMenuOpen && (
        <div className="absolute bg-white left-0 z-20 min-w-[160px] shadow-cardShadow p-4 rounded mt-1">
          {options.map((item, index) => (
            <SortItem
              key={index}
              name={item.name}
              onClick={() => handleSelect(item.value)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function OrderNumberPatternDescriptionDialog({
  title,
  isDialogOpen,
  closeDialog,
}) {
  const ExampleItem = ({ pattern, value }) => {
    return (
      <div className="mt-4">
        <p>Pattern: {pattern}</p>
        <p>Value: {value}</p>
      </div>
    );
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeDialog}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>
          <div className={viewStyle.modalContent}>
            <div className="text-base">
              <p>
                Define how your order numbers should be generated using the
                placeholders below:
              </p>
              <ul className="mt-4 pl-4">
                <ol>%d - Random digit (0-9)</ol>
                <ol>%W - Random uppercase letter (A-Z)</ol>
                <ol>%w - Random lowercase letter (a-z)</ol>
              </ul>
              <p className="text-brandColor font-medium mt-6">Examples:</p>
              <ExampleItem pattern="PO%d%d%d%d" value="PO1234" />
              <ExampleItem pattern="INV-%W%d%d" value="INV-A12" />
              <ExampleItem pattern="SO%d%w%W%d" value="SO1aA9" />
              <ExampleItem pattern="SI-0000%d%d%d%d" value="SI-00001234" />

              <p className="mt-4">
                Simply combine these placeholders with any fixed text to create
                your desired format.
              </p>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function ImportErrors({ errors }) {
  // implement function when errors exceed three lines show ImportErrorsDialog
  // only show five lines
  const hasMoreThanFiveErrors = errors.length > 5;
  const visibleErrors = errors.slice(0, 5);

  const [dialog, setDialog] = useState(false);

  const clickAction = (e) => {
    e.preventDefault();
    setDialog(true);
  };
  return (
    <div>
      <ImportErrorsDialog
        title={"Import Errors"}
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        errors={errors}
      />

      {/* Display the first 5 errors */}
      {visibleErrors.map((item, index) => (
        <p key={index}>{item}</p>
      ))}

      {/* Show "View More" button if there are more than 5 errors */}
      {hasMoreThanFiveErrors && (
        <div>
          <div>...</div>
          <button
            onClick={clickAction}
            className="mt-1 text-brandColor hover:underline hover:cursor-pointer"
          >
            View More
          </button>
        </div>
      )}
    </div>
  );
}

function ImportErrorsDialog({ title, isDialogOpen, closeDialog, errors }) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeDialog}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>
          <div className={viewStyle.modalContent}>
            <div className="text-base">
              {errors.map((item, index) => (
                <p key={index} className="mt-1">
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function ImportXeroDataErrors({ errors }) {
  // implement function when errors exceed three lines show ImportErrorsDialog
  // only show five lines
  const hasMoreThanFiveErrors = errors.length > 5;
  const visibleErrors = errors.slice(0, 5);

  const [dialog, setDialog] = useState(false);

  const clickAction = (e) => {
    e.preventDefault();
    setDialog(true);
  };
  return (
    <div>
      <ImportXeroDataErrorsDialog
        title={"Import Errors"}
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        errors={errors}
      />

      {/* Display the first 5 errors */}
      {visibleErrors.map((item, index) => (
        <p key={index}>
          {item.name} / {item.reason}
        </p>
      ))}

      {/* Show "View More" button if there are more than 5 errors */}
      {hasMoreThanFiveErrors && (
        <div>
          <div>...</div>
          <button
            onClick={clickAction}
            className="mt-1 text-brandColor hover:underline hover:cursor-pointer"
          >
            View More
          </button>
        </div>
      )}
    </div>
  );
}

function ImportXeroDataErrorsDialog({
  title,
  isDialogOpen,
  closeDialog,
  errors,
}) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeDialog}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>
          <div className={viewStyle.modalContent}>
            <div className="text-base">
              {errors.map((item, index) => (
                <p key={index}>
                  {item.name} / {item.reason}
                </p>
              ))}
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function LongTextError({ error }) {
  const charLimit = 200; // Limit to 200 characters

  const hasMoreThanCharLimit = error.length > charLimit;
  const visibleError = hasMoreThanCharLimit
    ? error.slice(0, charLimit) + "..."
    : error; // Show first 200 characters

  const [dialog, setDialog] = useState(false);

  const clickAction = (e) => {
    e.preventDefault();
    setDialog(true);
  };
  return (
    <div>
      <LongTextErrorDialog
        title={"Error"}
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        error={error}
      />
      {/* Display the first 200 characters */}
      <p>{visibleError}</p>
      {/* Show "View More" button if there are more than 200 characters */}
      {hasMoreThanCharLimit && (
        <div>
          <button
            onClick={clickAction}
            className="mt-1 text-brandColor hover:underline hover:cursor-pointer"
          >
            View Full Error
          </button>
        </div>
      )}
    </div>
  );
}

function LongTextErrorDialog({ title, isDialogOpen, closeDialog, error }) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={viewStyle.inputModal}
        overlayClassName={viewStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={viewStyle.dialogContentContainer}>
          <div className={viewStyle.modalTitle}>
            <h3>{title}</h3>
            <div className={viewStyle.closeIconContainer} onClick={closeDialog}>
              <BsX className={viewStyle.closeIcon} />
            </div>
          </div>
          <div className={viewStyle.modalContent}>
            <div className="text-base">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function SuccessSyncToXero() {
  return (
    <div>
      <FaCheck className="w-5 h-5 text-green-500" />
    </div>
  );
}

function FailedSyncToXero({ mess }) {
  const [showMessage, setShowMessage] = useState(false);
  return (
    <div className="relative inline-block">
      <MdOutlineErrorOutline
        className="w-5 h-5 text-red-500 cursor-pointer"
        onMouseEnter={() => setShowMessage(true)}
        onMouseLeave={() => setShowMessage(false)}
      />
      {showMessage && (
        <div className="absolute z-50 min-w-[320px] max-w-[280px] left-0 top-6 bg-darkGray opacity-90 text-white p-3 rounded shadow-lg">
          {mess}
        </div>
      )}
    </div>
  );
}

function ContactCard({
  contactName,
  billingName,
  firstName,
  lastName,
  contactLink,
  phone,
  email,
  postalAddress,
  billingAddress,
}) {
  // Split text on highlight term, include term itself into parts, ignore case

    let postal_address = [];
    let postal_address_line = "";
    let billing_address = [];
    let billing_address_line = "";
    let contact_name = [];
    let contact_name_line = "";

    if (firstName) {
      contact_name.push(firstName);
    }
    if (lastName) {
      contact_name.push(lastName);
    }

    if (postalAddress) {
      if (postalAddress.addressLine) {
        postal_address.push(postalAddress.addressLine);
      }
      if (postalAddress.city) {
        postal_address.push(postalAddress.city);
      }
      if (postalAddress.region) {
        postal_address.push(postalAddress.region);
      }
      if (postalAddress.country && typeof postalAddress.country === "object") {
        let c_name = postalAddress.country.value;
        postal_address.push(c_name);
      }
      if (postalAddress.postalCode) {
        postal_address.push(postalAddress.postalCode);
      }
    }

    if (billingAddress) {
      if (billingAddress.addressLine) {
        billing_address.push(billingAddress.addressLine);
      }
      if (billingAddress.city) {
        billing_address.push(billingAddress.city);
      }
      if (billingAddress.region) {
        billing_address.push(billingAddress.region);
      }
      if (billingAddress.country && typeof billingAddress.country === "object") {
        let c_name = billingAddress.country.value;
        billing_address.push(c_name);
      }
      if (billingAddress.postalCode) {
        billing_address.push(billingAddress.postalCode);
      }
    }

    if (postal_address.length) {
      postal_address_line = postal_address.join(", ");
    }
    if (billing_address.length) {
      billing_address_line = billing_address.join(", ");
    }
    if (contact_name.length) {
      contact_name_line = contact_name.join(" ");
    }

  return (
    <div className="text text-textColor">
      <NavLink to={contactLink} className={"hover:text-brandColor"}>
        <div className="flex flex-row items-center">
          <p className="text-[16px] font-medium underline">{contactName}</p>
          <FiExternalLink className="w-4 h-4 ml-2 " />
        </div>
      </NavLink>
      <div className="w-full grid grid-cols-2 gap-4">
        <div>
          {contact_name_line && (
            <div className="w-full flex flex-row items-center mt-2">
              <AiOutlineUser className="w-4 h-4 mr-2" />
              <p className="w-[calc(100%-16px)] text-sm">{contact_name_line}</p>
            </div>
          )}

          {email && (
            <div className="w-full flex flex-row items-center mt-2">
              <AiOutlineMail className="w-4 h-4 mr-2" />
              <p className="w-[calc(100%-16px)] text-sm">{email}</p>
            </div>
          )}

          {phone && (
            <div className="w-full flex items-center mt-2">
              <MdOutlineLocalPhone className="w-4 h-4 mr-2" />
              <p className="w-[calc(100%-16px)] text-sm">{phone}</p>
            </div>
          )}
        </div>
        <div>
          {billing_address_line && (
            <div className="mt-2">
              <div className="w-full flex items-start flex-row">
                <MdOutlineLocationOn className="w-4 h-4 mr-2" />
                <p className="text-sm">Billing Address</p>
              </div>
              <div className="pl-6 mt-1">
                <p className="text-sm">{billingName}</p>
                <p className="text-sm">
                  {billing_address_line}
                </p>
              </div>
            </div>
          )}
          {postal_address_line && (
            <div className="mt-2">
              <div className="w-full flex items-start flex-row">
                <MdOutlineLocationOn className="w-4 h-4 mr-2" />
                <p className="text-sm">Postal Address</p>
              </div>
              <div className="pl-6 mt-1">
                <p className="text-sm">{contactName}</p>
                <p className="text-sm">
                  {postal_address_line}
                </p>
              </div>
            </div>
          )}
       
        </div>
      </div>
    </div>
  );
}

function ProductNameLink({productId, code, name, largeText=false}) {
  return (
    <NavLink to={`/inventory/preview/${productId}`} className="flex flex-row items-center justify-start hover:text-brandColor">
      <VariantSkuTag name={code} /> <p className={`ml-2 hover:underline ${largeText ? "text-xl font-medium" : "text-base"}`}>{name}</p>
  </NavLink>


  );
}

export {
  AdjustTypeTag,
  BatchSerialNumberViewDialog,
  BlurColorBackground,
  ContentModal,
  DeleteDialog,
  DeleteTrialAccountDataDialog,
  DollarTag,
  ErrorMessage,
  ErrorText,
  FadeErrorMessage,
  FromTag,
  FullHeightContentModal,
  FullHeightModal,
  GuideLink,
  HightlightKeywords,
  ImportErrors,
  ImportXeroDataErrors,
  InsufficientStockSaleOrderDialog,
  Loader,
  LongTextError,
  MappingXeroAccountDialog,
  Modal,
  MoreLink,
  OrderNumberPatternDescriptionDialog,
  PasswordInput,
  PriceText,
  ProceedSaleOrderDialog,
  ProceedStockAdjustDialog,
  ProductImage,
  QuestionMark,
  RecievStockDialog,
  RoleListTag,
  RoleTag,
  SimpleDialog,
  SmallProductImage,
  SortedTableTitle,
  SubTitle,
  SuccessDialog,
  SuccessText,
  Tag,
  TextErrorMessage,
  TipsText,
  VariantSkuTag,
  VariantTag,
  ViewDateText,
  ViewDateWithTime,
  ViewDateWithTitle,
  ViewLabel,
  ViewPreText,
  ViewText,
  XeroAccountMappingLink,
  XeroAccountMappingTip,
  XeroSmallLogo,
  SuccessSyncToXero,
  FailedSyncToXero,
  ContactCard,
  ProductNameLink
};
