import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import {
  EditButton,
  EditButtonLink,
  ScreenTitle,
} from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ErrorMessage,
  Loader,
  ViewText,
  ViewDateWithTitle,
  SubTitle,
  PriceText,
  VariantTag,
  VariantSkuTag,
  ViewLabel,
} from "../../../components/viewComponents";
import { stockAdjustDetailsRequest } from "../../../actions/productActions";
import useDocumentTitle from "../../../useDocumentTitle";
import { convtPrice, convtQuantity } from "../../../functions/functions";

export default function PreviewStockAdjustmentScreen() {
  useDocumentTitle("Preview Stock Adjustment");
  const dispatch = useDispatch();
  const { id: adjustid } = useParams();

  const { loading, adjustDetails, error, success } = useSelector(
    (state) => state.stockAdjustDetails
  );

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(stockAdjustDetailsRequest(adjustid));
  }, [dispatch, adjustid]);

  /////////////////////////////////////////////////////////////////////////////

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview Stock Adjustment" />
      <ScreenTitle title="Preview Stock Adjustment" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting details, please wait a moment..." />
        ) : success ? (
          <div>
            {/* purchase order infomation */}
            <SubTitle name="Order Basic Information" />

            <div className={commonStyle.previewContentContainer}>
              {adjustDetails.basicInfo.status === "Approved" && (
                <div className="flex justify-end">
                  <EditButtonLink
                    path={`/inventory/bulk-stock-adjustment/edit/${adjustDetails.basicInfo.id}`}
                  />
                </div>
              )}

              <div className={commonStyle.previewWrapper}>
                <div className={commonStyle.col1}>
                  <ViewText
                    title="Location"
                    value={adjustDetails.basicInfo.location}
                  />

                  <ViewText
                    title="Adjust Type"
                    value={
                      adjustDetails.basicInfo.type === "RECEIVESTOCK"
                        ? "Receive Stock"
                        : adjustDetails.basicInfo.type === "STOCKDEDUCTION"
                        ? "Deduct Stock"
                        : "Unknown"
                    }
                  />
                  <ViewText
                    title="Adjust Reason"
                    value={adjustDetails.basicInfo.reasonName}
                  />
                  {adjustDetails.basicInfo.projectName && (
                    <ViewText
                      title="Project"
                      value={
                        adjustDetails.basicInfo.projectCode +
                        "-" +
                        adjustDetails.basicInfo.projectName
                      }
                    />
                  )}
                  <ViewText
                    title="Notes"
                    value={adjustDetails.basicInfo.notes}
                  />
                </div>
                <div className={commonStyle.col3}>
                  <ViewDateWithTitle
                    title="Create Date"
                    value={adjustDetails.basicInfo.atime}
                  />
                  <ViewText
                    title="Creator"
                    value={
                      adjustDetails.basicInfo.creatorFirstName +
                      " " +
                      adjustDetails.basicInfo.creatorLastName
                    }
                  />
                </div>
              </div>
            </div>

            {/* invoice section */}
            <div className="mt-8">
              <SubTitle name="Adjust Products" />
            </div>
            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Adjust Cost</th>
                    <th>Quantity</th>
                    <th>Adjust Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {adjustDetails.orderItems.map((item, index1) => {
                    return (
                      <tr key={index1}>
                        <td>
                          {item.variantSku && (
                            <VariantSkuTag name={item.variantSku} />
                          )}
                        </td>
                        <td>{item.productName}</td>
                        <td>{item.unitName}</td>
                        <td>
                          <PriceText num={convtPrice(item.unitCost)} />
                        </td>
                        <td>{convtQuantity(item.quantity)}</td>

                        <td>
                          <PriceText num={convtPrice(item.totalCost)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
