import { ADD_CUSTOMER_FAIL, ADD_CUSTOMER_REQUEST, ADD_CUSTOMER_SUCCESS, CREATE_CUSTOMER_OPTIONS_FAIL, CREATE_CUSTOMER_OPTIONS_REQUEST, CREATE_CUSTOMER_OPTIONS_SUCCESS, CUSTOMER_DETAILS_FAIL, CUSTOMER_DETAILS_REQUEST, CUSTOMER_DETAILS_SUCCESS, CUSTOMER_LIST_FAIL, CUSTOMER_LIST_REQUEST, CUSTOMER_LIST_SUCCESS, DELETE_CUSTOMER_FAIL, DELETE_CUSTOMER_REQUEST, DELETE_CUSTOMER_SUCCESS, EDIT_CUSTOMER_FAIL, EDIT_CUSTOMER_REQUEST, EDIT_CUSTOMER_SUCCESS } from "../constants/customerConstants";

export const createCustomerOptionsReducer = (
    state = { options: [] },
    action
  ) => {
    switch (action.type) {
      case CREATE_CUSTOMER_OPTIONS_REQUEST:
        return { loading: true };
      case CREATE_CUSTOMER_OPTIONS_SUCCESS:
        return { loading: false, options: action.payload, success: true };
      case CREATE_CUSTOMER_OPTIONS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const createNewCustomerReducer = (state = {customerId: ""}, action) => {
    switch (action.type) {
      case ADD_CUSTOMER_REQUEST:
        return { loading: true };
      case ADD_CUSTOMER_SUCCESS:
        return { loading: false, customerId: action.payload, success: true };
      case ADD_CUSTOMER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const editCustomerReducer = (state = {customerId: ""}, action) => {
    switch (action.type) {
      case EDIT_CUSTOMER_REQUEST:
        return { loading: true };
      case EDIT_CUSTOMER_SUCCESS:
        return { loading: false, customerId: action.payload, success: true };
      case EDIT_CUSTOMER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const customerDetailsReducer = (
    state = { customerInfo: [] },
    action
  ) => {
    switch (action.type) {
      case CUSTOMER_DETAILS_REQUEST:
        return { loading: true, success: false };
      case CUSTOMER_DETAILS_SUCCESS:
        return { loading: false, customerInfo: action.payload, success: true };
      case CUSTOMER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const customerListReducer = (state = { customers: [] }, action) => {
    switch (action.type) {
      case CUSTOMER_LIST_REQUEST:
        return { loading: true, success: false };
      case CUSTOMER_LIST_SUCCESS:
        return { loading: false, customers: action.payload, success: true };
      case CUSTOMER_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const deleteCustomerReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_CUSTOMER_REQUEST:
        return { loading: true, success: false };
      case DELETE_CUSTOMER_SUCCESS:
        return { loading: false, success: true };
      case DELETE_CUSTOMER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };