import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  SingleRatioButton,
  SimpleBlueButton,
} from "../../components/editComponents";
import {
  StyledSelect,
  StyledInput,
  StyledEmailInput,
  StyledTextarea,
} from "../../components/inputFields";
import {
  ErrorMessage,
  Loader,
  SubTitle,
} from "../../components/viewComponents";
import salesStyle from "../sale/salesStyle.module.css";
import { K_COUNTRIES } from "../../constantsData/countries";
import { validateEmail } from "../../functions/validate";
import { getCustomerDetailsApiRequest } from "../../api/customerServices";

export default function CustomerDetailForm({
  type,
  customerId,
  options,
  btnName,
  onSubmit,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [sameBilling, setSameBilling] = useState(false);
  const [differentBillingName, setDifferentBillingName] = useState(false);
  const [customerValues, setCustomerValues] = useState();

  const [formErrors, setFormErrors] = useState({});

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    const customerDetails = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getCustomerDetailsApiRequest(
          customerId,
          config
        );
        if (responseData.data.success) {
          let details = responseData.data.data;
          if(details.name !== details.billingName){
            setDifferentBillingName(true);
          }
          let country = "";
          let has_country_value = K_COUNTRIES.find(
            (x) => x.value === details.country
          );
          if (has_country_value) {
            country = has_country_value;
          }

          let currency = "";
          let has_currency_value = options.currencies.find(
            (x) => x.value === details.currency
          );
          if (has_currency_value) {
            currency = has_currency_value;
          }

          let initialValues = {
            name: details.name,
            billingName: details.billingName,
            country: country,
            currency: currency,
            taxRateId: details.taxRateId,
            locationId: details.locationId,
            priceTierId: details.priceTierId > 0 ? details.priceTierId : "",
            paymentTerm: details.paymentTerm,
            postalAddress: details.postalAddress,
            billingAddress: details.billingAddress,
            firstName: details.firstName,
            lastName: details.lastName,
            email: details.email,
            phone: details.phone,
            notes: details.notes,
          };

          setCustomerValues(initialValues);

          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    if (type === "new") {
      let country_default_value = "";
      if (userDetailsSuccess) {
        let find_country = K_COUNTRIES.find(
          (x) => x.value === userDetails.data.orgInfo.country
        );
        if (find_country) {
          country_default_value = find_country;
        }
      }

      let currency_default_value = "";
      let find_currency = options.currencies
        ? options.currencies.find((x) => x.isBaseCurrency === 1)
        : "";
      if (find_currency) {
        currency_default_value = find_currency;
      }

      let taxrate_default_id = "";
      let find_taxrate = options.salesTaxRates
        ? options.salesTaxRates.find((x) => x.isDefault === 1)
        : "";
      if (find_taxrate) {
        taxrate_default_id = find_taxrate.id;
      }

      let location_default_id = "";
      let find_location = options.locations
        ? options.locations.find((x) => x.isDefault === 1)
        : "";
      if (find_taxrate) {
        location_default_id = find_location.id;
      }

      let initialValues = {
        name: "",
        billingName: "",
        country: country_default_value,
        currency: currency_default_value,
        taxRateId: taxrate_default_id,
        locationId: location_default_id,
        priceTierId: "",
        paymentTerm: 7,
        postalAddress: {
          country: "",
          city: "",
          region: "",
          addressLine: "",
          postalCode: "",
        },
        billingAddress: {
          country: "",
          city: "",
          region: "",
          addressLine: "",
          postalCode: "",
        },

        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: "",
      };

      setCustomerValues(initialValues);

      setReqStatus({
        loading: false,
        success: true,
        error: "",
      });
    } else {
      customerDetails();
    }
  }, [customerId, type, userDetailsSuccess]);
  // handle change when input
  const handleBillingAddress = (value) => {
    setSameBilling(value);
    if (value) {
      setCustomerValues({
        ...customerValues,
        billingAddress: customerValues.postalAddress,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerValues({ ...customerValues, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { type, name, value } = e.target;
    if (type === "postal") {
      let postalAddressCopy = { ...customerValues.postalAddress };
      postalAddressCopy[name] = value;
      setCustomerValues({
        ...customerValues,
        postalAddress: postalAddressCopy,
      });
    }

    if (type === "billing") {
      let billingAddressCopy = { ...customerValues.billingAddress };
      billingAddressCopy[name] = value;
      setCustomerValues({
        ...customerValues,
        billingAddress: billingAddressCopy,
      });
    }
  };

  // handle email input field change
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const errors = {};
    if (validateEmail(value)) {
      // this is a valid email address
      setFormErrors({});
      setCustomerValues({ ...customerValues, [name]: value });
    } else {
      // invalid email, maybe show an error to the user.
      setCustomerValues({ ...customerValues, [name]: value });
      errors.email = "Please enter a valid email address.";
      setFormErrors(errors);
    }
  };

  const checkValidation = () => {
    const hasErrors = validate(customerValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(customerValues, differentBillingName);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Company name is required!";
    }
    if (differentBillingName && !values.billingName) {
      errors.billingName = "Billing name is required!";
    }

    if (values.email && !validateEmail(values.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!values.taxRateId) {
      errors.taxRate = "Customer tax rate is required!";
    }

    if (!values.country) {
      errors.country = "Customer country is required!";
    }
    if (!values.currency) {
      errors.currency = "Customer currency is required!";
    }

    return errors;
  };

  return (
    <div>
      {reqStatus.loading ? (
        <Loader mess={"Requesting.."} />
      ) : reqStatus.success ? (
        <div>
          <SubTitle name="Customer Information" />
          <div className="grid grid-cols-2 gap-8">
            <div>
              <StyledInput
                label="Company Name*"
                type="text"
                name="name"
                autofocus="autofocus"
                value={customerValues.name}
                onChange={(name) =>
                  handleChange({
                    target: { value: name, name: "name" },
                  })
                }
                error={formErrors.name}
              />
              <div style={{ marginTop: "10px" }}>
                <SingleRatioButton
                  label="Use a different name for billing"
                  name="differentBillingName"
                  isOn={differentBillingName}
                  onChange={(differentBillingName) =>
                    setDifferentBillingName(differentBillingName)
                  }
                />
                {differentBillingName && (
                  <div className="bg-lightbg px-4 pb-4 pt-1 mt-2 rounded">
                    <StyledInput
                      label="Billing  Name*"
                      type="text"
                      name="billingName"
                      value={customerValues.billingName}
                      onChange={(billingName) =>
                        handleChange({
                          target: { value: billingName, name: "billingName" },
                        })
                      }
                      error={formErrors.billingName}
                    />
                  </div>
                )}
              </div>

              <div style={{ display: "flex" }}>
                <StyledInput
                  label="Contact First Name"
                  type="text"
                  name="firstName"
                  value={customerValues.firstName}
                  onChange={(firstName) =>
                    handleChange({
                      target: { value: firstName, name: "firstName" },
                    })
                  }
                  error={formErrors.firstName}
                />

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <StyledInput
                    label="Contact Last Name"
                    type="text"
                    name="lastName"
                    value={customerValues.lastName}
                    onChange={(lastName) =>
                      handleChange({
                        target: { value: lastName, name: "lastName" },
                      })
                    }
                    error={formErrors.lastName}
                  />
                </div>
              </div>

              <StyledEmailInput
                label="Contact Email"
                type="email"
                name="email"
                value={customerValues.email}
                onChange={(email) =>
                  handleEmailChange({
                    target: { value: email, name: "email" },
                  })
                }
                error={formErrors.email}
              />
              <StyledInput
                label="Contact Phone"
                type="text"
                name="phone"
                value={customerValues.phone}
                onChange={(phone) =>
                  handleChange({
                    target: { value: phone, name: "phone" },
                  })
                }
                error={formErrors.userPhone}
              />

              <StyledSelect
                label="Default Location"
                placeHolder="Select location"
                selectOptions={options.locations}
                value={customerValues.locationId}
                extractKey="id"
                onChange={(location) =>
                  handleChange({
                    target: { value: location, name: "locationId" },
                  })
                }
                error={formErrors.location}
              />
            </div>
            <div>
              <StyledSelect
                label="Country*"
                placeHolder="Select country"
                selectOptions={K_COUNTRIES}
                value={customerValues.country}
                onChange={(country) =>
                  handleChange({
                    target: { value: country, name: "country" },
                  })
                }
                error={formErrors.country}
              />
              <StyledSelect
                label="Currency*"
                placeHolder="Select customer currency"
                selectOptions={options.currencies}
                value={customerValues.currency}
                onChange={(currency) =>
                  handleChange({
                    target: { value: currency, name: "currency" },
                  })
                }
                error={formErrors.currency}
              />
              <StyledSelect
                label="Tax Rate*"
                placeHolder="Select tax rate"
                selectOptions={options.salesTaxRates}
                value={customerValues.taxRateId}
                extractKey="id"
                onChange={(taxRate) =>
                  handleChange({
                    target: { value: taxRate, name: "taxRateId" },
                  })
                }
                error={formErrors.taxRate}
              />

              <StyledSelect
                label="Price Tier"
                placeHolder="Select price tier"
                selectOptions={options.priceTiers}
                value={customerValues.priceTierId}
                extractKey="id"
                onChange={(priceTierId) =>
                  handleChange({
                    target: { value: priceTierId, name: "priceTierId" },
                  })
                }
                error={formErrors.priceTier}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "6px" }}>
                  <StyledInput
                    label="Payment Term"
                    type="text"
                    name="paymentTerm"
                    value={customerValues.paymentTerm}
                    onChange={(paymentTerm) =>
                      handleChange({
                        target: { value: paymentTerm, name: "paymentTerm" },
                      })
                    }
                  />
                </div>
                <span style={{ marginTop: "26px" }}>
                  {" "}
                  Days after invoice date
                </span>
              </div>
            </div>
          </div>

          <div className="mt-8 grid grid-cols-2 gap-8">
            <div>
              <SubTitle name="Postal Address" />
              <StyledSelect
                label="Country"
                placeHolder="Select country"
                selectOptions={K_COUNTRIES}
                value={customerValues.postalAddress.country}
                onChange={(country) =>
                  handleAddressChange({
                    target: { type: "postal", value: country, name: "country" },
                  })
                }
              />
              <StyledInput
                label="Region"
                type="text"
                name="region"
                value={customerValues.postalAddress.region}
                onChange={(region) =>
                  handleAddressChange({
                    target: { type: "postal", value: region, name: "region" },
                  })
                }
              />
              <StyledInput
                label="City"
                type="text"
                name="city"
                value={customerValues.postalAddress.city}
                onChange={(city) =>
                  handleAddressChange({
                    target: { type: "postal", value: city, name: "city" },
                  })
                }
              />

              <StyledInput
                label="Street Address"
                type="text"
                name="addressLine"
                value={customerValues.postalAddress.addressLine}
                onChange={(addressLine) =>
                  handleAddressChange({
                    target: {
                      type: "postal",
                      value: addressLine,
                      name: "addressLine",
                    },
                  })
                }
              />
              <StyledInput
                label="Postal Code"
                type="text"
                name="postalCode"
                value={customerValues.postalAddress.postalCode}
                onChange={(postalCode) =>
                  handleAddressChange({
                    target: {
                      type: "postal",
                      value: postalCode,
                      name: "postalCode",
                    },
                  })
                }
              />
            </div>
            <div>
              <SubTitle name="Billing Address" />
              <StyledSelect
                label="Country"
                placeHolder="Select country"
                selectOptions={K_COUNTRIES}
                value={customerValues.billingAddress.country}
                onChange={(country) =>
                  handleAddressChange({
                    target: {
                      type: "billing",
                      value: country,
                      name: "country",
                    },
                  })
                }
              />
              <StyledInput
                label="Region"
                type="text"
                name="region"
                value={customerValues.billingAddress.region}
                onChange={(region) =>
                  handleAddressChange({
                    target: { type: "billing", value: region, name: "region" },
                  })
                }
              />
              <StyledInput
                label="City"
                type="text"
                name="city"
                value={customerValues.billingAddress.city}
                onChange={(city) =>
                  handleAddressChange({
                    target: { type: "billing", value: city, name: "city" },
                  })
                }
              />

              <StyledInput
                label="Street Address"
                type="text"
                name="addressLine"
                value={customerValues.billingAddress.addressLine}
                onChange={(addressLine) =>
                  handleAddressChange({
                    target: {
                      type: "billing",
                      value: addressLine,
                      name: "addressLine",
                    },
                  })
                }
              />
              <StyledInput
                label="Postal Code"
                type="text"
                name="postalCode"
                value={customerValues.billingAddress.postalCode}
                onChange={(postalCode) =>
                  handleAddressChange({
                    target: {
                      type: "billing",
                      value: postalCode,
                      name: "postalCode",
                    },
                  })
                }
              />
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <SingleRatioButton
              label="Same billing address "
              name="sameBilling"
              isOn={sameBilling}
              onChange={(sameBilling) => handleBillingAddress(sameBilling)}
            />
          </div>

          <StyledTextarea
            label="Notes"
            type="text"
            name="notes"
            value={customerValues.notes}
            onChange={(notes) =>
              handleChange({
                target: { value: notes, name: "notes" },
              })
            }
            error={formErrors.notes}
          />
          <div className="w-full mt-6">
            <SimpleBlueButton name={btnName} action={checkValidation} />
          </div>
        </div>
      ) : reqStatus.error ? (
        <ErrorMessage mess={reqStatus.error} />
      ) : (
        <></>
      )}
    </div>
  );
}
